/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

export const PortalIn = ({ id, children }: any) => {
  const el = useRef(document.createElement('div'));

  useEffect(() => {
    const portal: any = document.getElementById(id);
    portal.appendChild(el.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      portal.removeChild(el.current);
    };
  }, [children, id]);

  return ReactDOM.createPortal(children, el.current);
};

export const PortalOut = ({ id, ...rest }: any) => (
  <div id={id} {...rest}></div>
);
