import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Col, Layout, Row } from 'antd';
import { useHistory } from 'react-router';

import { useErrorBoundary } from './ErrorBoundaryProvider';
import { PortalOut } from '@components/Portal';
import './styles.scss';

export interface HeaderProps {
  breadcrumbs: any[];
  title: string;
  isCollapse: boolean;
  className: string;
}

const Header: FunctionComponent<HeaderProps> = ({
  breadcrumbs,
  title,
  isCollapse,
  className,
}) => {
  const history = useHistory();
  const widthMenu = isCollapse ? 'calc(100% - 80px)' : 'calc(100% - 210px)';

  const [error] = useErrorBoundary();

  return (
    <div
      style={{
        transition: 'all .3s ease-in-out',
      }}
      className={className}
    >
      {error.hasError ? (
        <div
          style={{
            background: '#f54f64',
            height: 48,
            width: `${widthMenu}`,
            position: 'fixed',
            top: 0,
            right: 0,
            paddingLeft: 15,
          }}
        >
          <Row gutter={[7, 0]} align="middle" style={{ height: '100%' }}>
            <Col className="text-white">{error.message}</Col>
          </Row>
        </div>
      ) : null}

      {error.hasError}

      <Layout.Header
        className="header-wrap"
        style={{
          background: '#fff',
          padding: 0,
          width: `${widthMenu}`,
          height: 60,
          position: 'fixed',
          top: error.hasError ? 48 : 0,
          right: 0,
          paddingRight: 20,
          paddingLeft: 15,
          zIndex: 888,
          transition: 'width 0.2s',
          WebkitTransition: 'width 0.2s',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Row gutter={[7, 0]} align="middle">
          <Col>
            <Breadcrumb>
              {breadcrumbs &&
                breadcrumbs?.map((breadcrumb: any, index: number) => {
                  const { title, middleCrumbs, path } = breadcrumb;
                  return (
                    <React.Fragment key={`${title}-${index}`}>
                      {middleCrumbs && (
                        <Breadcrumb.Item>
                          {<a onClick={history.goBack}>{middleCrumbs.title}</a>}
                        </Breadcrumb.Item>
                      )}
                      {title && (
                        <Breadcrumb.Item>
                          {path && title !== 'Home' ? (
                            path === 'goback' ? (
                              <a onClick={() => history.goBack()}>{title}</a>
                            ) : path === '*' ? (
                              <Link to="/">{title}</Link>
                            ) : (
                              <Link to={path}>{title}</Link>
                            )
                          ) : null}
                        </Breadcrumb.Item>
                      )}
                    </React.Fragment>
                  );
                })}
            </Breadcrumb>
          </Col>
        </Row>
        <PortalOut id="indigo-breadcrumb" />
      </Layout.Header>
      <Row className="header-title ">
        <div style={{ marginRight: 16 }}>{title}</div>
        <div id="company-selection" />
      </Row>
    </div>
  );
};
export default Header;
