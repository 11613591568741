import '../../../ModalHistoryLogsTable/style.scss';

import { Divider, Form, Spin } from 'antd';
import {
  Payment_Advice_Logs,
  useGetPaymentAdviceLogsDetailLazyQuery,
} from '@services/data-access';
import Pill, { PillType } from '@components/Pill/Pill';
import React, { Fragment, useEffect } from 'react';

import ClassInfo from '../class-info/ClassInfo';
import CreditNote from '../credit-note/CreditNote';
import Deduction from '../deduction/Deduction';
import Deposit from '../deposit/Deposit';
import { HistoryLogType } from '@models/historyLog';
import OtherPayments from '../other-payments/OtherPayments';
import { PALogMetadata } from '../../../ModalHistoryLogsTable/PALogMetadata';
import { PATransferInformation } from '../transfer-information/PATransferInformation';
import { PaymentAdviceStatus } from '@models/payment-advice';
import PaymentInfo from '../payment-info/PaymentInfo';
import RegistrationFee from '../registration-fee/RegistrationFee';
import StudentInfo from '../student-info/StudentInfo';
import Summary from '../summary/Summary';
import { useParams } from 'react-router-dom';

const PALogsDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [getPaymentAdviceDetail, { data, loading }] =
    useGetPaymentAdviceLogsDetailLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        where: {
          id: {
            _eq: id,
          },
        },
      },
      onCompleted: result => {},
      onError: () => {},
    });

  useEffect(() => {
    getPaymentAdviceDetail();
    // eslint-disable-next-line
  }, [id]);

  const renderPill = (value: any) => {
    let color: PillType = 'primary';
    switch (value) {
      case PaymentAdviceStatus.Draft:
        color = 'warning';
        break;
      case PaymentAdviceStatus.OverDue:
        color = 'brown';
        break;
      case PaymentAdviceStatus.Paid:
        color = 'success';
        break;
      case PaymentAdviceStatus.UnPaid:
        color = 'danger';
        break;

      default:
        break;
    }
    return (
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <Pill text={value} type={color}></Pill>
      </div>
    );
  };

  const renderAction = (record: Payment_Advice_Logs) => {
    let Content: any = '';
    switch (record?.type) {
      case HistoryLogType.Update:
        Content = 'Update Content';
        break;
      case HistoryLogType.Create:
        Content = `Create New PA {${record?.pa?.name}}`;
        break;
      case HistoryLogType.ChangeStatus:
        Content = () => (
          <div style={{ display: 'flex' }}>
            Change Status from{' '}
            {renderPill(
              record?.valueBefore?.paymentAdvice?.paymentStatus ??
                record?.valueBefore?.paymentStatus,
            )}{' '}
            to{' '}
            {renderPill(
              record?.valueAfter?.paymentAdvice?.paymentStatus ??
                record?.valueAfter?.paymentStatus,
            )}
          </div>
        );
        break;
      case HistoryLogType.Duplicate:
        Content = ` Duplicate from PA {${record?.pa?.name}}`;
        break;
    }
    return (
      <Fragment>
        <div>{typeof Content === 'string' ? Content : <Content />}</div>
      </Fragment>
    );
  };

  return (
    <div
      style={{
        maxWidth: '100%',
        margin: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: '100vh',
        display: 'flex',
        position: 'fixed',
        background: '#fff',
        zIndex: 9999,
        overflow: 'auto',
        paddingTop: 30,
        paddingBottom: 30,
      }}
    >
      {data?.payment_advice_logs[0]?.valueAfter && (
        <Form
          style={{ marginLeft: 'auto', marginRight: 'auto', width: 1110 }}
          form={form}
          layout="vertical"
        >
          <Spin spinning={loading}>
            <div
              className="create-student-form"
              style={{
                width: '100%',
                margin: 'auto',
              }}
            >
              <PALogMetadata
                detail={data?.payment_advice_logs[0] as Payment_Advice_Logs}
                renderAction={renderAction(data?.payment_advice_logs[0] as any)}
              />
              <Divider />
              <PaymentInfo
                onChange={() => {}}
                disabled={true}
                paymentAdvice={
                  data?.payment_advice_logs[0]?.valueAfter?.paymentAdvice
                }
                isLogDetail={true}
                // onUpdateData={() => {}}
              />
              <Divider />

              <StudentInfo
                disabled={true}
                onChange={() => {}}
                student={
                  data?.payment_advice_logs[0]?.valueAfter?.paymentAdvice
                    ?.student
                }
                // onUpdateData={() => {}}
              />
              <Divider />
              {/* <div style={{ marginTop: -20 }}> */}
              <RegistrationFee
                onChange={() => {}}
                disabled={true}
                paymentAdvice={
                  data?.payment_advice_logs[0]?.valueAfter?.paymentAdvice
                }
                // onUpdateData={() => {}}
              />
              {/* </div> */}
              <Divider />
              {/* <div style={{ marginTop: -20 }}> */}
              <ClassInfo
                disabled={true}
                onChange={() => {}}
                paymentAdvice={
                  data?.payment_advice_logs[0]?.valueAfter?.paymentAdvice
                }
                onUpdateData={() => {}}
              />
              {/* </div> */}
              <Divider />

              {/* <div style={{ marginTop: -20 }}> */}
              {/* <OtherPayments
                disabled={true}
                onChange={() => {}}
                otherPayments={data?.payment_advice_logs[0]?.valueAfter}
                // onUpdateData={() => {}}
              /> */}
              {/* </div> */}
              {/* <Divider /> */}

              {/* <div style={{ marginTop: -20 }}> */}
              {/* <Deduction
                disabled={true}
                onChange={() => {}}
                paymentAdvice={data?.payment_advice_logs[0]?.valueAfter}
                // onUpdateData={() => {}}
              /> */}
              {/* </div> */}
              {/* <Divider /> */}
              {/* <div style={{ marginTop: -20 }}> */}
              {/* <Deposit
                disabled={true}
                onChange={() => {}}
                paymentAdvice={data?.payment_advice_logs[0]?.valueAfter}
                // onUpdateData={() => {}}
              /> */}
              {/* </div> */}
              {/* <Divider /> */}
              {/* <div style={{ marginTop: -20 }}> */}
              <CreditNote
                disabled={true}
                onChange={() => {}}
                paymentAdvice={
                  data?.payment_advice_logs[0]?.valueAfter?.paymentAdvice
                }
              />
              {/* </div> */}
              <Divider />
              <PATransferInformation
                transferBill={
                  data.payment_advice_logs[0].valueAfter?.paymentAdvice
                    ?.transferBill
                }
                transferData={
                  data.payment_advice_logs[0].valueAfter?.transferLog
                }
              />
              <Divider />
              {/* <div style={{ marginTop: -20 }}> */}
              <Summary
                disabled={true}
                onChange={() => {}}
                paymentAdvice={
                  data?.payment_advice_logs[0]?.valueAfter?.paymentAdvice
                }
                // onUpdateData={() => {}}
              />
              {/* </div> */}
            </div>
          </Spin>
        </Form>
      )}
    </div>
  );
};

export default PALogsDetail;
