import { Col, Divider, Form, InputNumber, Row, Select } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { formatCurrencyFullText, parseCurrencyToValue } from '@helpers';
import { CreditNoteFormItemProps } from './CreditNoteFormItemProps';
import { CreditNoteType } from '@models/credit-note';
import CurrencyInput from '@components/CurrencyInput/component';
import { StatusCode } from '@models';
import { useFilterReceiptListLazyQuery } from '@services/data-access';

const CreditNoteProRatedFeeFormItem: FunctionComponent<
  CreditNoteFormItemProps & {
    pricing: number;
    studentId: string;
    defaultNumberLesson?: number;
  }
> = ({
  creditNoteData,
  formName,
  disabled,
  pricing,
  formInstance,
  studentId,
  defaultNumberLesson,
}) => {
  const [
    getListData,
    { loading, data: listData, called, fetchMore: fetchMoreListData },
  ] = useFilterReceiptListLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        invoice: {
          pa: {
            studentId: {
              _eq: studentId,
            },
          },
        },
        status: {
          _neq: StatusCode.Deleted,
        },
      },
    },
    onCompleted: result => {
      const selectedReceipt = formInstance?.getFieldValue(formName || '');
      if (selectedReceipt && selectedReceipt.receipt) {
        const existed = result.receipts.find(
          item => item.id === selectedReceipt.receipt,
        );
        if (!existed) {
          // eslint-disable-next-line no-unused-expressions
          formInstance?.setFieldsValue({
            [formName || '']: {
              receipt: undefined,
            },
          });
        }
      }
    },
  });

  useEffect(() => {
    if (studentId) {
      if (called) {
        fetchMoreListData({
          variables: {
            filter: {
              invoice: {
                pa: {
                  studentId: {
                    _eq: studentId,
                  },
                },
              },
              status: {
                _neq: StatusCode.Deleted,
              },
            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          },
        });
      } else {
        getListData({
          variables: {
            filter: {
              invoice: {
                pa: {
                  studentId: {
                    _eq: studentId,
                  },
                },
              },
              status: {
                _neq: StatusCode.Deleted,
              },
            },
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [studentId, called]);

  const [numberLesson, setNumberLesson] = useState(defaultNumberLesson || 0);
  const [overpaidAmount, setOverpaidAmount] = useState(0);
  const [gst, setGst] = useState('');
  const totalPricing = () => {
    return overpaidAmount || pricing * (numberLesson || 1);
  };
  const changeNumberLesson = (value: string | number | undefined) => {
    setNumberLesson(Number(value) || 0);
  };
  const govTaxResult = () => {
    return ((Number(gstConfig()) * totalPricing()) / 100).toFixed(2);
  };
  const totalAmount = () => {
    return totalPricing() + Number(govTaxResult());
  };
  const isShowOverpaid = () => {
    return creditNoteData?.type !== CreditNoteType.Suspension;
  };
  const isShowNoLesson = () => {
    return creditNoteData?.type !== CreditNoteType.Overpaid;
  };
  const gstConfig = () => {
    const paymentSummaryStatic =
      creditNoteData?.creditNoteFee?.receipt?.invoice?.pa?.paymentSummaryStatic;
    if (paymentSummaryStatic) {
      return paymentSummaryStatic.gstConfig || 0;
    }
    return gst;
  };
  const handleChangeReceipt = (value: any) => {
    const receipt = listData?.receipts.find((e: any) => e.id === value);

    setGst(receipt?.invoice?.pa.paymentSummaryStatic.gstConfig || 0);
  };
  return (
    <div>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <p className="text--large" style={{ marginBottom: 0 }}>
            Credit Note / Pro-Rated Fees (to be completed by finance)
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="(*) Receipt No."
            name={[formName || '', 'receipt']}
            rules={[
              {
                required: true,
                message: 'Please select receipt no.!',
              },
            ]}
          >
            <Select
              loading={loading}
              disabled={disabled}
              onChange={handleChangeReceipt}
              placeholder="Select receipt"
            >
              {listData?.receipts.map((item: any) => (
                <Select.Option value={item.id} key={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {isShowOverpaid() && (
        <Row>
          <Col span={24}>
            <Form.Item
              label="(*) Overpaid Amount"
              name={[formName || '', 'overpaidAmount']}
              rules={[
                {
                  required: true,
                  message: 'Please fill in overpaid amount!',
                },
              ]}
            >
              <CurrencyInput
                style={{ width: '100%', textAlign: 'right' }}
                disabled={disabled}
                onChange={val =>
                  setOverpaidAmount(
                    Number(parseCurrencyToValue(val.target.value)),
                  )
                }
                placeholder="S$00.00"
              />
              {/* <InputNumber style={{ width: '100%', textAlign: 'right' }} disabled={disabled} onChange={val => setOverpaidAmount(val || 0)} placeholder="S$00.00" /> */}
            </Form.Item>
          </Col>
        </Row>
      )}
      {isShowNoLesson() && (
        <Row gutter={[10, 0]} align="middle">
          <Col span={12}>
            <Form.Item
              label="(*) No. of Lesson"
              name={[formName || '', 'noOfLesson']}
              rules={[
                {
                  required: true,
                  message: 'Please fill in no. of lesson!',
                },
              ]}
            >
              <InputNumber
                disabled={disabled}
                style={{ width: '100%' }}
                min={1}
                onChange={changeNumberLesson}
              />
            </Form.Item>
          </Col>
          <Col span={1}>x</Col>
          <Col span={6}>
            {formatCurrencyFullText(pricing ? pricing.toString() : '0')}
          </Col>
          <Col span={1}>=</Col>
          <Col span={4} style={{ textAlign: 'right' }}>
            {formatCurrencyFullText(totalPricing().toString())}
          </Col>
        </Row>
      )}
      <Row justify="space-between">
        <Col> GST ({gstConfig()}%)</Col>
        <Col> {formatCurrencyFullText(govTaxResult())}</Col>
      </Row>
      <Row>
        <Col span={24}>
          <Divider style={{ margin: 10 }}></Divider>
        </Col>
      </Row>
      <Row justify="space-between" gutter={[20, 20]}>
        <Col className="text-bold text-ink">Total Amount</Col>
        <Col className="text-bold text-ink">
          {formatCurrencyFullText(totalAmount().toString())}
        </Col>
      </Row>
    </div>
  );
};

export default CreditNoteProRatedFeeFormItem;
