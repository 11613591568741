import { Col, Form, Input, Row } from 'antd';
import React, { FunctionComponent } from 'react';

import { CreditNoteFormItemProps } from './CreditNoteFormItemProps';

const CreditNoteRemarksFormItem: FunctionComponent<CreditNoteFormItemProps> = ({
  formName,
  disabled,
}) => {
  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <p className="text--large">Remarks</p>
      </Col>
      <Col span={24}>
        <Form.Item name={formName}>
          <Input.TextArea disabled={disabled} placeholder="Fill in remarks" />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CreditNoteRemarksFormItem;
