import React from 'react';

const Login = React.lazy(
  () => import(/* webpackChunkName: "login" */ './Login'),
);
const ForgotPassword = React.lazy(
  () => import(/* webpackChunkName: "forgot-password" */ './ForgotPassword'),
);
const ChangePassword = React.lazy(
  () => import(/* webpackChunkName: "change-password" */ './ChangePassword'),
);

export default [
  {
    path: '/login',
    component: Login,
    title: 'Login Page',
    isPublic: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    title: 'Forgot password page',
    isPublic: true,
  },
];

export const ChangePassRoute = [
  {
    path: '/change-password/:email',
    component: ChangePassword,
    title: 'Forgot password page',
    isPublic: true,
  },
];
