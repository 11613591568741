import { Form, Input } from 'antd';
import React, { FunctionComponent } from 'react';

import { CreditNoteFormItemProps } from './CreditNoteFormItemProps';

const CreditNoteReasonFormItem: FunctionComponent<CreditNoteFormItemProps> = ({
  creditNoteData,
  creditNoteStatus,
  creditNoteType,
  disabled,
  formName,
}) => {
  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: 'Please fill in reason!',
        },
      ]}
      label="(*) Reason"
      name={formName}
    >
      <Input
        placeholder={`Fill in reason for this ${creditNoteType}`}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default CreditNoteReasonFormItem;
