import React from 'react';

import { Roles } from '@models';

const BroadcastList = React.lazy(
  () =>
    import(
      /* webpackChunkName: "broadcast-list" */ '@pages/communication/broadcast/BroadcastList'
    ),
);

const BroadcastDetail = React.lazy(
  () =>
    import(
      /* webpackChunkName: "broadcast-detail" */ '@pages/communication/broadcast/BroadcastDetail'
    ),
);

const CreateBroadcast = React.lazy(
  () =>
    import(
      /* webpackChunkName: "broadcast-create" */ '@pages/communication/broadcast/CreateBroadcast'
    ),
);

const NewsList = React.lazy(
  () =>
    import(
      /* webpackChunkName: "news-list" */ '@pages/communication/news/NewsList'
    ),
);

const NewsDetail = React.lazy(
  () =>
    import(
      /* webpackChunkName: "news-detail" */ '@pages/communication/news/NewsDetail'
    ),
);

const CreateNews = React.lazy(
  () =>
    import(
      /* webpackChunkName: "news-create" */ '@pages/communication/news/CreateNews'
    ),
);

const ThreeTimesAbsent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "three-times-absent" */ '@pages/communication/three-times-absent'
    ),
);

const Unsubscribers = React.lazy(
  () =>
    import(
      /* webpackChunkName: "unsubscribers" */ '@pages/communication/unsubscribers'
    ),
);

export default [
  {
    path: '/communication/broadcast',
    component: BroadcastList,
    exact: true,
    title: 'Broadcast',
    isPublic: true,
    roles: [Roles.FrontDesk, Roles.Intern, Roles.OpsManager, Roles.Sales],
  },
  {
    path: '/communication/broadcast/broadcast-detail/:id',
    component: BroadcastDetail,
    exact: true,
    title: 'Broadcast Detail',
    isPublic: true,
    roles: [Roles.FrontDesk, Roles.Intern, Roles.OpsManager, Roles.Sales],
  },
  {
    path: '/communication/broadcast/create',
    component: CreateBroadcast,
    exact: true,
    title: 'Compose Broadcast',
    isPublic: true,
    roles: [Roles.FrontDesk, Roles.Intern, Roles.OpsManager, Roles.Sales],
  },
  {
    path: '/communication/broadcast/update/:id',
    component: CreateBroadcast,
    exact: true,
    title: 'Compose Broadcast',
    isPublic: true,
    roles: [Roles.FrontDesk, Roles.Intern, Roles.OpsManager, Roles.Sales],
  },
  {
    path: '/communication/news',
    component: NewsList,
    exact: true,
    title: 'Newsfeed',
    isPublic: true,
    roles: [Roles.FrontDesk, Roles.Intern, Roles.OpsManager, Roles.Sales],
  },
  {
    path: '/communication/news/news-detail/:id',
    component: NewsDetail,
    exact: true,
    title: 'Newsfeed Detail',
    isPublic: true,
    roles: [Roles.FrontDesk, Roles.Intern, Roles.OpsManager, Roles.Sales],
  },
  {
    path: '/communication/news/create',
    component: CreateNews,
    exact: true,
    title: 'Compose News',
    isPublic: true,
    roles: [Roles.FrontDesk, Roles.Intern, Roles.OpsManager, Roles.Sales],
  },
  {
    path: '/communication/news/update/:id',
    component: CreateNews,
    exact: true,
    title: 'Compose News',
    isPublic: true,
    roles: [Roles.FrontDesk, Roles.Intern, Roles.OpsManager, Roles.Sales],
  },
  {
    path: '/communication/three-times-absent',
    component: ThreeTimesAbsent,
    exact: true,
    title: '3x Absent',
    isPublic: true,
    roles: [Roles.FrontDesk, Roles.Intern, Roles.OpsManager, Roles.Sales],
  },
  {
    path: '/communication/unsubscribers',
    component: Unsubscribers,
    exact: true,
    title: 'Unsubscribers',
    isPublic: true,
    roles: [Roles.FrontDesk, Roles.Intern, Roles.OpsManager, Roles.Sales],
  },
];
