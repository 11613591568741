import './pill.scss';

import React, { FunctionComponent, useEffect, useState } from 'react';

export type PillType =
  | 'success'
  | 'warning'
  | 'info'
  | 'danger'
  | 'primary'
  | 'brown'
  | 'purple'
  | 'withdrawn'
  | 'graduated'
  | 'draff';

export interface PillProps {
  text: string;
  type: PillType;
  color?: string;
}

const Pill: FunctionComponent<PillProps> = ({ text, type, color: bgColor }) => {
  const [color, setColor] = useState('');
  useEffect(() => {
    switch (type) {
      case 'brown':
        setColor('brown-pill');
        break;
      case 'danger':
        setColor('danger-pill');
        break;
      case 'info':
        setColor('info-pill');
        break;
      case 'primary':
        setColor('primary-pill');
        break;
      case 'success':
        setColor('success-pill');
        break;
      case 'warning':
        setColor('warning-pill');
        break;
      case 'purple':
        setColor('purple-pill');
        break;
      case 'withdrawn':
        setColor('withdrawn-pill');
        break;
      case 'graduated':
        setColor('graduated-pill');
        break;
      case 'draff':
        setColor('draff-pill');
        break;
      default:
        break;
    }
  }, [type]);

  if (!!bgColor) {
    return (
      <div className="indigo-pill" style={{ backgroundColor: bgColor }}>
        {text}
      </div>
    );
  }

  return <div className={`indigo-pill ${color}`}>{text}</div>;
};

export default Pill;
