import './styles.scss';

/**
 * Static Component
 * @use when children component without loading attribute
 */
import React, { FunctionComponent, PropsWithChildren } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StaticContainer: FunctionComponent<Props> = ({ loading, children }) => {
  return (
    <div className="static">
      {children}
      {loading && (
        <div className="static__loading">
          <FontAwesomeIcon
            spin={true}
            icon={['far', 'spinner-third']}
            style={{ width: 48, height: 48, fontSize: 48 }}
          />
        </div>
      )}
    </div>
  );
};

type Props = PropsWithChildren<{ loading: boolean; children: any }>;
export default StaticContainer;
