import React from 'react';

import { Roles } from '../../models';
import PaymentAdviceLogsDetail from './payment-advice/create/payment-advice-log/PaymentAdviceLogDetail';
import CreditNoteLogsDetail from './credit-note/credit-note-logs/CreditNoteLogsDetail';

const FinancePaymentList = React.lazy(
  () =>
    import(
      /* webpackChunkName: "financial-payment-list" */ './FinancePaymentList'
    ),
);
const CreatePaymentAdvice = React.lazy(
  () =>
    import(
      /* webpackChunkName: "payment-advice-create" */ './payment-advice/create/CreatePaymentAdvice'
    ),
);
const InvoiceList = React.lazy(
  () =>
    import(
      /* webpackChunkName: "invoice-list" */ './payment-advice/invoice-list/InvoiceList'
    ),
);
const ReceiptList = React.lazy(
  () =>
    import(
      /* webpackChunkName: "receipt-list" */ './payment-advice/receipt-list/ReceiptList'
    ),
);
const CreditNoteList = React.lazy(
  () =>
    import(
      /* webpackChunkName: "credit-note-list" */ './payment-advice/creditNote-list/CreditNoteList'
    ),
);
const CreditNoteForm = React.lazy(
  () =>
    import(
      /* webpackChunkName: "credit-note-form" */ './credit-note/forms/credit-note-form'
    ),
);
const CreditNoteDetail = React.lazy(
  () =>
    import(
      /* webpackChunkName: "credit-note-detail" */ './paymentPaper/PaymentCreditNoteWrapper'
    ),
);
const SuspensionForm = React.lazy(
  () =>
    import(
      /* webpackChunkName: "suspension-form" */ '../student/StudentSuspension/CreateSuspension'
    ),
);
const RefundList = React.lazy(
  () =>
    import(/* webpackChunkName: "refund-list" */ './refund/list/RefundList'),
);
const RefundForm = React.lazy(
  () =>
    import(/* webpackChunkName: "refund-form" */ './refund/forms/refund-form'),
);
const Refunded = React.lazy(
  () => import(/* webpackChunkName: "refunded" */ './refund/RefundedWrapper'),
);
const RefundLogsDetail = React.lazy(
  () =>
    import(
      /* webpackChunkName: "refund-logs-detail" */ './refund/logs/logs-detail/RefundLogsDetail'
    ),
);
const Paper = React.lazy(
  () =>
    import(
      /* webpackChunkName: "payment-paper" */ './paymentPaper/PaymentPaperWrapper'
    ),
);
const Invoice = React.lazy(
  () =>
    import(
      /* webpackChunkName: "invoice" */ './paymentPaper/PaymentInvoiceWrapper'
    ),
);
const Receipt = React.lazy(
  () =>
    import(
      /* webpackChunkName: "receipt" */ './paymentPaper/PaymentReceiptWrapper'
    ),
);
const PaymentSetting = React.lazy(
  () =>
    import(
      /* webpackChunkName: "payment-setting" */ './Settings/PaymentSetting'
    ),
);
const PaymentDiscount = React.lazy(
  () =>
    import(
      /* webpackChunkName: "payment-discount" */ './Settings/PaymentDiscount'
    ),
);
const CreatePaymentDiscount = React.lazy(
  () =>
    import(
      /* webpackChunkName: "payment-discount-create" */ './Settings/PaymentDiscount/CreatePaymentDiscount'
    ),
);
const Pricing = React.lazy(
  () => import(/* webpackChunkName: "pricing" */ './Settings/Pricing'),
);

export default [
  {
    path: '/finance',
    component: FinancePaymentList,
    title: 'Finance',
    isPublic: true,
    breadcrumbs: [],
    exact: true,
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.FinanceManager,
      Roles.FinanceStaff,
    ],
  },
  {
    path: '/finance/payment-advice',
    component: FinancePaymentList,
    title: 'Payment Advice',
    isPublic: true,
    breadcrumbs: [],
    exact: true,
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.FinanceManager,
      Roles.FinanceStaff,
    ],
  },
  {
    path: '/finance/payment-advice/create',
    component: CreatePaymentAdvice,
    exact: true,
    title: 'Create Payment Advice',
    isPublic: true,
    breadcrumbs: [],
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.FinanceManager,
      Roles.FinanceStaff,
    ],
  },
  {
    path: '/finance/payment-advice/edit/:id',
    component: CreatePaymentAdvice,
    exact: true,
    title: 'Create Payment Advice',
    isPublic: true,
    breadcrumbs: [],
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.FinanceManager,
      Roles.FinanceStaff,
    ],
  },
  {
    path: '/finance/payment-advice/paper/:id',
    component: Paper,
    exact: true,
    title: 'Generate PA',
    isPublic: true,
    breadcrumbs: [],
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.FinanceManager,
      Roles.FinanceStaff,
    ],
  },
  {
    path: '/finance/invoice/:id/:invoiceId',
    component: Invoice,
    exact: true,
    title: 'Generate Invoice',
    isPublic: true,
    breadcrumbs: [],
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.FinanceManager,
      Roles.FinanceStaff,
    ],
  },
  {
    path: '/finance/receipt/:id/:receiptId',
    component: Receipt,
    exact: true,
    title: 'Generate Receipt',
    isPublic: true,
    breadcrumbs: [],
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.FinanceManager,
      Roles.FinanceStaff,
    ],
  },
  {
    path: '/finance/invoice',
    component: InvoiceList,
    exact: true,
    title: 'Invoice',
    isPublic: true,
    breadcrumbs: [],
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.FinanceManager,
      Roles.FinanceStaff,
    ],
  },
  {
    path: '/finance/receipt',
    component: ReceiptList,
    exact: true,
    title: 'Receipt',
    isPublic: true,
    breadcrumbs: [],
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.FinanceManager,
      Roles.FinanceStaff,
    ],
  },
  {
    path: '/finance/payment-advice/logs/:id',
    component: PaymentAdviceLogsDetail,
    exact: true,
    title: 'Payment Advice Logs Detail',
    isPublic: true,
    breadcrumbs: [],
    roles: [Roles.FinanceManager, Roles.FinanceStaff],
  },
  {
    path: '/finance/credit-note',
    component: CreditNoteList,
    exact: true,
    title: 'Credit Note',
    isPublic: true,
    breadcrumbs: [],
    roles: [Roles.FinanceManager, Roles.FinanceStaff],
  },
  {
    path: '/finance/credit-note/update/:creditNoteId',
    component: CreditNoteForm,
    exact: true,
    title: 'Update Credit Note',
    isPublic: true,
    breadcrumbs: [],
    roles: [Roles.FinanceManager, Roles.FinanceStaff],
  },
  {
    path: '/finance/credit-note/update/sus/:id',
    component: SuspensionForm,
    exact: true,
    title: 'Update Suspension Credit Note',
    isPublic: true,
    breadcrumbs: [],
    roles: [Roles.FinanceManager, Roles.FinanceStaff],
  },
  {
    path: '/finance/credit-note/detail/:id',
    component: CreditNoteDetail,
    exact: true,
    title: 'Credit Note Detail',
    isPublic: true,
    breadcrumbs: [],
    roles: [Roles.FinanceManager, Roles.FinanceStaff],
  },
  {
    path: '/finance/refund',
    component: RefundList,
    exact: true,
    title: 'Refund',
    isPublic: true,
    breadcrumbs: [],
    roles: [Roles.FinanceManager, Roles.FinanceStaff],
  },
  {
    path: '/finance/refund/:id',
    component: RefundForm,
    exact: true,
    title: 'Create Refund',
    isPublic: true,
    breadcrumbs: [],
    roles: [Roles.FinanceManager, Roles.FinanceStaff],
  },
  {
    path: '/finance/refund/refunded/:id',
    component: Refunded,
    exact: true,
    title: 'Refunded',
    isPublic: true,
    breadcrumbs: [],
    roles: [Roles.FinanceManager, Roles.FinanceStaff],
  },
  {
    path: '/finance/refund/logs/:id',
    component: RefundLogsDetail,
    exact: true,
    title: 'Refund Logs Detail',
    isPublic: true,
    breadcrumbs: [],
    roles: [Roles.FinanceManager, Roles.FinanceStaff],
  },
  {
    path: '/finance/credit-note/logs/:id',
    component: CreditNoteLogsDetail,
    exact: true,
    title: 'Credit Note Logs Detail',
    isPublic: true,
    breadcrumbs: [],
    roles: [Roles.FinanceManager, Roles.FinanceStaff],
  },
  {
    path: '/finance/payment-setting',
    component: PaymentSetting,
    exact: true,
    title: 'Payment Setting',
    isPublic: true,
    breadcrumbs: [],
    roles: [Roles.FinanceManager, Roles.FinanceStaff],
  },
  {
    path: '/finance/payment-discount',
    component: PaymentDiscount,
    exact: true,
    title: 'Payment Discount',
    isPublic: true,
    breadcrumbs: [],
    roles: [Roles.FinanceManager, Roles.FinanceStaff],
  },

  {
    path: '/finance/payment-discount/create/:order',
    component: CreatePaymentDiscount,
    exact: true,
    title: 'Create Payment Discount',
    isPublic: true,
    breadcrumbs: [],
    roles: [Roles.FinanceManager, Roles.FinanceStaff],
  },
  {
    path: '/finance/pricing',
    component: Pricing,
    exact: true,
    title: 'Pricing',
    isPublic: true,
    breadcrumbs: [],
    roles: [Roles.OpsManager, Roles.FinanceManager, Roles.FinanceStaff],
  },
];
