export enum PaymentAdviceStatus {
  Draft = 'draft',
  OverDue = 'overdue',
  Paid = 'paid',
  UnPaid = 'unpaid',
  Voided = 'voided',
  Overpaid = 'overpaid',
  Withdrawn = 'withdrawn',
}

export enum InvoiceStatus {
  Active = 'active',
  Voided = 'voided',
  Paid = 'paid',
  PartialPayment = 'partial_payment',
  Overpaid = 'overpaid',
  PaymentOnline = 'payment_online',
}

export enum PaymentEmailStatus {
  Fail = 'fail',
  Success = 'success',
  InProgress = 'in_progress',
}

export enum BatchPaymentAdviceStatus {
  New = 'new',
  InProgress = 'in_progress',
  Completed = 'completed',
  Error = 'error',
}

export enum PlaceOfCreations {
  Finance = 'finance',
  Student = 'student',
  Batch = 'batch',
  Transfer = 'transfer',
}
