import { Classes, Lessons, Sessions } from '@services/data-access';
import { Col, Row, Select, Table, message } from 'antd';
import moment from 'moment';
import {
  Key,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import Pill, { PillType } from '@components/Pill/Pill';
import React, {
  Fragment,
  FunctionComponent,
  memo,
  useEffect,
  useState,
} from 'react';
import { ClassStatus } from '@models/class';
import { ColumnsType } from 'antd/lib/table';
import IndigoPagination from '@components/Pagination/IndigoPagination';
import SelectClassSessionPopup from '../session-popup/SelectClassSessionPopup';
import TableSummary from '@components/TableSummary/TableSummary';
import { cloneDeep } from 'lodash';
import { getWaitingType } from '@pages/class/helper/helper';
import { parseUTCToTime } from '@helpers';
import { ProgramCategory } from '@models';

const { Option } = Select;

export interface SelectClassPopUpTableProps {
  data: Classes[];
  isLoading: boolean;
  currentPage: number;
  total: number;
  pageSize: number;
  disable: boolean;
  previousSelectedClasses: Classes[];
  onPageChange: (role: number) => void;
  onSortChange: (role: { order: string; field: string }) => void;
  onItemsPageChange: (role: number) => void;
  onSelectedChange: (classIds: Classes[]) => void;
}

const SelectClassPopUpTable: FunctionComponent<SelectClassPopUpTableProps> = ({
  data,
  isLoading,
  currentPage,
  total,
  pageSize,
  disable,
  onPageChange,
  onItemsPageChange,
  onSortChange,
  previousSelectedClasses,
  onSelectedChange,
}) => {
  const [showSessionPopup, setShowSessionPopup] = useState(false);

  const [viewLesson, setViewLesson] = useState<Lessons>({} as Lessons);
  const [viewSession, setViewSession] = useState<Sessions[]>([]);
  const [selectedClasses, setSelectedClasses] = useState<Classes[]>([]);

  useEffect(() => {
    setSelectedClasses(previousSelectedClasses.slice());
  }, [previousSelectedClasses]);

  const columns: ColumnsType<Classes> = [
    {
      title: 'Index',
      width: 80,
      render: (text: string, record: any, index: number) => (
        <span>{index + 1 + pageSize * (currentPage - 1)}</span>
      ),
    },
    {
      title: 'Class Name',
      dataIndex: 'name',
    },
    {
      title: 'Upcoming Lesson',
      render: (text: string, record: Classes, index: number) => (
        <span>
          <div>
            {parseUTCToTime(record?.lessons?.[0]?.date, 'ddd, DD/MM/YYYY')}
          </div>
          <div
            onClick={() => {
              setShowSessionPopup(true);
              setViewSession(record.lessons[0]?.sessions);
              setViewLesson(record.lessons[0]);
            }}
            className="clickable text-primary"
          >
            View Session
          </div>
        </span>
      ),
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      width: 150,
    },
    {
      title: 'Class Availability',
      width: 150,
      render: (text: string, record: Classes, index: number) => {
        const numberOfStudent =
          record.lessons?.[0]?.lessonsStudents_aggregate?.aggregate?.count ?? 0;

        const status = getWaitingType(
          moment(record?.lessons?.[0]?.date),
          record.classStatus as ClassStatus,
          numberOfStudent,
          record.lessons[0]?.classroom.capacity,
        );
        return (
          <span>
            {status} ({numberOfStudent}/{record.lessons[0]?.classroom.capacity})
          </span>
        );
      },
    },
    {
      title: 'Class Status',
      render: (text: string, record: Classes, index: number) => {
        let type: PillType = 'primary';
        let prettyText = '';

        switch (record.classStatus as ClassStatus) {
          case 'closed':
            type = 'brown';
            prettyText = 'Closed Class';
            break;

          case 'new':
            type = 'info';
            prettyText = 'New Class';

            break;
          case 'open':
            type = 'success';
            prettyText = 'Open Class';
        }

        return (
          <span>
            <Pill type={type} text={prettyText}></Pill>
          </span>
        );
      },
    },
    {
      title: 'Action',
      render: (text: string, record: Classes, index: number) => {
        const exist = selectedClasses.find(
          item => item.id === record.id || item.classId === record.id,
        );
        const existProgramme = selectedClasses.find(
          item => item.programId === record.programId,
        );

        return (
          <span>
            {exist ? (
              <div
                onClick={() => cancelClass(record.id)}
                className="clickable text-danger"
              >
                Cancel
              </div>
            ) : (
              <Fragment>
                {!existProgramme &&
                  record?.program?.cate?.code !== ProgramCategory.Special && (
                    <div
                      onClick={() => selectClass(record)}
                      className="clickable text-info"
                    >
                      Select
                    </div>
                  )}
              </Fragment>
            )}
            {!exist &&
              record?.program?.cate?.code === ProgramCategory.Special && (
                <div
                  onClick={() => selectClass(record)}
                  className="clickable text-info"
                >
                  Select
                </div>
              )}
          </span>
        );
      },
    },
  ];

  const selectClass = (selectedClass: Classes) => {
    const existProgram = selectedClasses.find(
      item => item.programId === selectedClass.programId,
    );
    if (
      !existProgram ||
      selectedClass.program?.cate.code === ProgramCategory.Special
    ) {
      const nexSelectedClasses = cloneDeep(selectedClasses);
      nexSelectedClasses.push(selectedClass);
      // selectedClasses.push(selectedClass);
      setSelectedClasses(nexSelectedClasses);
      onSelectedChange(nexSelectedClasses);
    } else {
      message.warn("Class's programme is already existed!");
    }
  };

  const cancelClass = (id: string) => {
    const index = selectedClasses.findIndex(
      item => item.id === id || item.classId === id,
    );
    if (index >= 0) {
      selectedClasses.splice(index, 1);
      setSelectedClasses(selectedClasses.slice());
      onSelectedChange(selectedClasses);
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, Key[] | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<any>,
  ) => {
    const sort = sorter as any;
    onSortChange({
      order: sort.order,
      field: sort.field,
    });
  };

  return (
    <Fragment>
      <Row style={{ marginBottom: 20 }} justify="space-between" align="middle">
        <Col>
          <TableSummary
            currentNumberOfRecords={data.length}
            currentPage={currentPage}
            pageSize={pageSize}
            total={total}
          />
        </Col>
        <Col>
          <Select
            value={pageSize}
            style={{ width: 170 }}
            onChange={onItemsPageChange}
          >
            <Option value={5}>5 items</Option>
            <Option value={10}>10 items</Option>
            <Option value={20}>20 items</Option>
            <Option value={30}>30 items</Option>
          </Select>
        </Col>
      </Row>
      <div className={`table-container ${data.length > 5 ? 'has-scroll' : ''}`}>
        <Table
          rowClassName="table-row-light"
          size="middle"
          columns={columns.map(item => ({ ...item }))}
          rowKey={record => record.id}
          dataSource={data}
          pagination={false}
          loading={isLoading}
          onChange={handleTableChange}
          scroll={{ x: true, y: 500 }}
        />
      </div>

      <Row justify="space-between" align="bottom">
        <Col>
          <IndigoPagination
            currentPage={currentPage}
            total={total}
            pageSize={pageSize}
            onPageChange={onPageChange}
            disabled={disable}
          />
        </Col>
        <Col className="text-ink text-bold">{`Total Selected Class (${selectedClasses.length})`}</Col>
      </Row>
      <SelectClassSessionPopup
        sessions={viewSession}
        lessonStartDate={parseUTCToTime(viewLesson.date, 'ddd, DD/MM/YYYY')}
        visible={showSessionPopup}
        onClose={() => setShowSessionPopup(false)}
      />
    </Fragment>
  );
};

export default memo(SelectClassPopUpTable);
