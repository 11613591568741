import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Col, Form, Input, message, Row, Select } from 'antd';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

import { getClassInformationForCreditNote } from '@services/api/creditNoteApi';
import {
  useGetClassCreditNoteLazyQuery,
  useGetTermsLazyQuery,
} from '@services/data-access';
import { ClassStatus, StatusCode, CreditNoteStatus } from '@models';
import { ICreditNoteQueryConstants } from '@interfaces/constants';
import { IError } from '@interfaces/network/res';
import { CreditNoteFormItemProps } from './CreditNoteFormItemProps';

export interface CreditNoteClassFormItemProps extends CreditNoteFormItemProps {
  studentId?: string;
  classId?: string;
  termId?: string;
  onTermChanged?: (price: number) => void;
}

const CreditNoteClassFormItem: FunctionComponent<
  CreditNoteClassFormItemProps
> = ({
  creditNoteData,
  creditNoteStatus,
  creditNoteType,
  disabled,
  formName,
  studentId,
  classId = '',
  termId = '',
  formInstance,
  onTermChanged,
}) => {
  const [selectDateRange, setSelectDateRange] = useState<string[]>([]);
  const [classIdQuery, setClassIdQuery] = useState(classId);
  const currentTerm: any = useRef();

  const { data: classInformationData, isFetched: isFetchedClassInformation } =
    useQuery(
      [
        ICreditNoteQueryConstants.GET_CLASS_INFORMATION_FOR_CREDIT_NOTE,
        {
          studentId,
          classId: classIdQuery,
        },
      ],
      () =>
        getClassInformationForCreditNote({
          studentId,
          classId: classIdQuery,
        }),
      {
        enabled: [!!studentId, !!classIdQuery].every(Boolean),
        cacheTime: 0,
        refetchOnWindowFocus: false,
        suspense: false,
        useErrorBoundary: false,
        onSuccess: ({ data }: any) => {
          if (data?.data?.terms?.length === 0) {
            formInstance?.setFieldsValue({
              [formName || '']: {
                termId: undefined,
                endDate: undefined,
                startDate: undefined,
              },
            });
          }
        },
        onError: (error: IError) => {
          message.error(error.message);
        },
      },
    );

  const [getClass, { data: listClass, loading: getClassLoading }] =
    useGetClassCreditNoteLazyQuery({
      onCompleted: () => {
        if (creditNoteData && creditNoteData.creditNoteClassId) {
          _handleChangeClass(creditNoteData.creditNoteClass?.classId);
        }
      },
    });

  const [getTerms, { data: termsData, loading: termsLoading }] =
    useGetTermsLazyQuery();

  function _handleChangeClass(classId: string) {
    if (!classId) {
      return;
    }

    setClassIdQuery(classId);

    if (isFetchedClassInformation) {
      formInstance?.setFieldsValue({
        [formName || '']: {
          termId: undefined,
          endDate: undefined,
          startDate: undefined,
        },
      });
    }

    if (formInstance) {
      if (!creditNoteData?.creditNoteClass) {
        formInstance.setFieldsValue({
          [formName || '']: {
            endDate: undefined,
            startDate: undefined,
            noOfLesson: undefined,
            termId: undefined,
            termName: undefined,
            lessonIds: undefined,
          },
        });
      }
    }
  }

  function _handleChangeTerm(termId: string) {
    const term = (classInformationData?.data?.data?.terms ?? [])?.find(
      (item: any) => item?.id === termId,
    );

    if (term) {
      if (onTermChanged) {
        onTermChanged(term.pricing || 0);
      }
      currentTerm.current = term;
      if (formInstance) {
        formInstance.setFieldsValue({
          [formName || '']: {
            termName: term.name || '',
          },
        });
      }
      setSelectDateRange(term.lessons as string[]);
    }
  }

  function _handleChangeDate() {
    if (formInstance) {
      const value = formInstance.getFieldValue(formName || '');
      if (value.startDate && value.endDate) {
        const starIndex = selectDateRange.findIndex(
          item => item === value.startDate,
        );
        const endIndex = selectDateRange.findIndex(
          item => item === value.endDate,
        );
        const numberOfLesson = endIndex - starIndex + 1;
        if (numberOfLesson) {
          formInstance.setFieldsValue({
            [formName || '']: {
              noOfLesson: numberOfLesson,
            },
          });
        }
        const listLessonIds: string[] = [];
        for (let index = starIndex; index <= endIndex; index++) {
          if (currentTerm.current) {
            listLessonIds.push(
              ((currentTerm.current.lessonIds || [])[index] as string) || '',
            );
          }
        }
        formInstance.setFieldsValue({
          [formName || '']: {
            lessonIds: listLessonIds,
          },
        });
      }
    }
  }

  useEffect(() => {
    if (disabled && !!classId && !!termId) {
      getTerms({
        variables: {
          where: {
            classId: { _eq: classId },
            termId: { _eq: termId },
          },
        },
      });
    }
  }, [disabled, classId, termId]);

  useEffect(() => {
    if (studentId && !classInformationData) {
      const filter: any = {
        userId: { _eq: studentId ?? '' },
        class: {
          classStatus: { _in: [ClassStatus.Open, ClassStatus.New] },
          lessons: {
            date: { _gt: moment(Date.now()).format('YYYY-MM-DD') },
            status: { _neq: StatusCode.Deleted },
          },
          status: { _neq: StatusCode.Deleted },
        },
      };

      getClass({
        variables: {
          where: filter,
        },
      });
    }

    if (formInstance) {
      if (!creditNoteData?.creditNoteClassId) {
        formInstance.setFieldsValue({
          [formName || '']: {
            endDate: undefined,
            startDate: undefined,
            noOfLesson: undefined,
            termId: undefined,
            termName: undefined,
            lessonIds: undefined,
          },
        });
      }

      _handleChangeTerm(creditNoteData?.creditNoteClass?.termId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getClass, studentId, classInformationData]);

  return (
    <div>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <p className="text--large">Class Information</p>
        </Col>
      </Row>
      <Row gutter={[0, 0]}>
        <Col span={24}>
          <Form.Item
            label="(*) Class Name"
            name={[formName || '', 'class']}
            rules={[
              {
                required: true,
                message: 'Please select class!',
              },
            ]}
          >
            <Select
              loading={getClassLoading}
              onChange={e => _handleChangeClass(e.toString())}
              disabled={disabled}
              placeholder="Select class"
            >
              {(listClass?.student_classes || []).map(item => (
                <Select.Option key={item?.class?.id} value={item?.class?.id}>
                  {item?.class?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={6}>
          <Form.Item
            label="(*) Suspension Period"
            name={[formName || '', 'termId']}
            rules={[
              {
                required: true,
                message: 'Please select suspension period!',
              },
            ]}
          >
            {disabled && classId && termId ? (
              <Select
                loading={termsLoading}
                onChange={_handleChangeTerm}
                disabled={disabled}
                placeholder="Select term"
              >
                {(termsData?.term_classes || []).map((item: any) => (
                  <Select.Option
                    key={item?.termId || ''}
                    value={item?.termId || ''}
                  >
                    T{item?.termName}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <Select
                onChange={_handleChangeTerm}
                disabled={disabled}
                placeholder="Select term"
              >
                {(classInformationData?.data?.data?.terms ?? []).map(
                  (item: any) => (
                    <Select.Option key={item?.id || ''} value={item?.id || ''}>
                      T{item?.name}
                    </Select.Option>
                  ),
                )}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            noStyle
            name={[formName || '', 'termName']}
            rules={[
              {
                required: true,
                message: 'Please select termName!',
              },
            ]}
          >
            <Input hidden disabled={disabled} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="(*) Start Date"
            name={[formName || '', 'startDate']}
            rules={[
              {
                required: true,
                message: 'Please select start date!',
              },
            ]}
          >
            <Select
              onChange={_handleChangeDate}
              disabled={disabled}
              placeholder="dd/mm/yyyy"
            >
              {selectDateRange.map((item, index) => (
                <Select.Option key={`start-date-${index}`} value={item}>
                  {moment(item).format('DD/MM/YYYY')}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="(*) End Date"
            name={[formName || '', 'endDate']}
            rules={[
              {
                required: true,
                message: 'Please select end date!',
              },
            ]}
          >
            <Select
              onChange={_handleChangeDate}
              disabled={disabled}
              placeholder="dd/mm/yyyy"
            >
              {selectDateRange.map((item, index) => (
                <Select.Option key={`end-date-${index}`} value={item}>
                  {moment(item).format('DD/MM/YYYY')}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {creditNoteData?.creditNoteStatus !== CreditNoteStatus.Issued ? (
            <Form.Item
              name={[formName || '', 'lessonIds']}
              rules={[
                {
                  required: true,
                  message: 'Please select start date!',
                },
              ]}
              noStyle
            >
              <Select
                mode="multiple"
                style={{ display: 'none' }}
                disabled={disabled}
              ></Select>
            </Form.Item>
          ) : null}
        </Col>
        <Col span={6}>
          <Form.Item
            label="(*) No. Of Lesson"
            name={[formName || '', 'noOfLesson']}
            rules={[
              {
                required: true,
                message: 'Please fill in no. of lesson!',
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default CreditNoteClassFormItem;
