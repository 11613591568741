import React from 'react';
import { Roles } from '@models';

const UserList = React.lazy(
  () => import(/* webpackChunkName: "user-list" */ './UserList'),
);
const UserDetail = React.lazy(
  () => import(/* webpackChunkName: "user-detail" */ './UserDetail'),
);
const UserForm = React.lazy(
  () => import(/* webpackChunkName: "user-form" */ './UserForm'),
);
const UserRoles = React.lazy(
  () => import(/* webpackChunkName: "user-roles" */ './UserRoles'),
);

export default [
  {
    path: '/user',
    component: UserList,
    exact: true,
    title: 'User List',
    isPublic: true,
    roles: [Roles.HR],
  },
  {
    path: '/user/:id/view',
    component: UserDetail,
    title: 'View User Account',
    roles: [
      Roles.AttendanceChecker,
      Roles.FinanceManager,
      Roles.FinanceStaff,
      Roles.FrontDesk,
      Roles.FulltimeTutor,
      Roles.HOD,
      Roles.HR,
      Roles.Intern,
      Roles.OpsManager,
      Roles.Sales,
    ],
  },
  {
    path: '/user/create',
    component: UserForm,
    exact: true,
    title: 'Create User Account',
    roles: [Roles.HR],
  },
  {
    path: '/user/:userId/update',
    component: UserForm,
    exact: true,
    title: 'Edit User Account',
    middleCrumbs: { title: 'Edit User Account' },
    roles: [Roles.HR, Roles.AttendanceChecker],
  },
  {
    path: '/roles',
    component: UserRoles,
    exact: true,
    title: 'User Roles',
    isPublic: true,
    roles: [Roles.HR],
  },
];
