import React from 'react';

import { Roles } from '@models';

const DailyReceipt = React.lazy(
  () => import(/* webpackChunkName: "daily-receipt" */ './DailyReceipt'),
);
const DailyClosing = React.lazy(
  () => import(/* webpackChunkName: "daily-closing" */ './DailyClosing'),
);
const Overpaid = React.lazy(
  () => import(/* webpackChunkName: "overpaid" */ './Overpaid'),
);
const PaListing = React.lazy(
  () => import(/* webpackChunkName: "pa-listing" */ './PaListing'),
);
const EnrollmentList = React.lazy(
  () => import(/* webpackChunkName: "enrollment-list" */ './EnrollmentList'),
);
const StudentAttendanceReport = React.lazy(
  () =>
    import(
      /* webpackChunkName: "student-attendance-report" */ './StudentAttendance'
    ),
);
const RevenueRecognition = React.lazy(
  () =>
    import(
      /* webpackChunkName: "revenue-recognition" */ './RevenueRecognition'
    ),
);

const DepositReport = React.lazy(
  () => import(/* webpackChunkName: "revenue-recognition" */ './DepositReport'),
);

export default [
  {
    path: '/report/daily-receipt-report',
    component: DailyReceipt,
    exact: true,
    title: 'Daily Receipt',
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
      Roles.Sales,
    ],
  },
  {
    path: '/report/daily-closing-report',
    component: DailyClosing,
    exact: true,
    title: 'Daily Closing',
    roles: [Roles.HR, Roles.FinanceStaff, Roles.FinanceManager],
  },
  {
    path: '/report/overpaid-report',
    component: Overpaid,
    exact: true,
    title: 'Overpaid',
    roles: [Roles.HR, Roles.FinanceStaff, Roles.FinanceManager],
  },
  {
    path: '/report/pa-listing',
    component: PaListing,
    exact: true,
    title: 'PA Listing',
    roles: [
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
    ],
  },
  {
    path: '/report/student-attendance-report',
    component: StudentAttendanceReport,
    exact: true,
    title: 'Student Attendance',
    roles: [
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
    ],
  },
  {
    path: '/report/enrollment-list',
    component: EnrollmentList,
    exact: true,
    title: 'Enrollment List',
    roles: [
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
    ],
  },
  {
    path: '/report/revenue-recognition',
    component: RevenueRecognition,
    exact: true,
    title: 'Revenue Recognition',
    roles: [
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
    ],
  },
  {
    path: '/report/deposit',
    component: DepositReport,
    exact: true,
    title: 'Deposit Report',
    roles: [
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
    ],
  },
];
