export function rulePhoneNumber(message?: string) {
  const _message = message || 'Invalid_phone_number';
  // const util = PhoneNumberUtil.getInstance();
  const pattern = /^[0-9]+\s*$/;

  return wrapRule((_rule: any, value: any, callback: any) => {
    return (value.length >= 8 && pattern.test(value)) || parseMessage(_message);
  });
}
function wrapRule(rule: any) {
  return { validator: rule, trigger: ['blur', 'change'] };
}

function parseMessage(message: string) {
  return message || Promise.reject(message);
}

export function ruleNumber(message?: string) {
  message = message || 'invalid_number';
  const pattern = /^[0-9]*$/;

  return wrapRule((_rule: any, value: any) => {
    if (!!pattern.test(value)) {
      return Promise.resolve();
    }

    if (!!value && !pattern.test(value)) {
      return Promise.reject(parseMessage(message || ''));
    }
    return Promise.reject();
  });
}

export function ruleMinLength(min: number, message?: string) {
  message = message || `At_least_${min}_characters`;

  return wrapRule((_rule: any, value: any) => {
    if (!value || value.length >= min) {
      return Promise.resolve(true);
    }

    return Promise.reject(parseMessage(message || ''));
  });
}
export function ruleUsername(message?: string) {
  const pattern = /[\n# $&:\n\t]/g;
  return wrapRule((_rule: any, username: string = '') => {
    const isValid = pattern.test(username);
    return isValid
      ? Promise.reject('Not allow space for username')
      : Promise.resolve(true);
  });
}

export const userNameValidator = (rule: any, value: string) => {
  const message = 'Not allow space for username';
  const pattern = /[\n# $&:\n\t]/g;
  const isValid = pattern.test(value);
  if (isValid) {
    return Promise.reject(message);
  }
  return Promise.resolve();
};

export const nameValidator = (_rule: any, value: string) => {
  const message = 'Space and Special characters not allowed';
  const pattern = /^([a-zA-Z0-9]+?)([-\s'][a-zA-Z0-9]+)*?$/;
  const isValid = pattern.test(value);
  if (!!isValid || value === '') {
    return Promise.resolve();
  }
  return Promise.reject(message);
};
