import { Scalars } from '@services/data-access';

export interface IUser {
  id?: string;
  username: string;
  email: string;
  phoneNumber?: string;
  phone: {
    code: string;
    number: string;
  };
  firstName: string;
  lastName: string;
  gender?: string;
  address?: string;
  status?: string;
  role: string;
  dob?: string;
  code?: string;
  isVerifiedEmail?: boolean;
  isVerifiedPhoneNumber?: boolean;
  avatar?: string;
  attachments?: string[];
  levelIds?: string[];
  roleIds?: string[];
  subjectIds?: string[];
  bankId?: string;
  bankNumber?: string;
  branchId?: string;
  roles?: {
    role?: string;
  };
}
export enum StatusCode {
  Deleted = 'deleted',
  Active = 'active',
  Locked = 'locked',
  Pending = 'pending',
  Disabled = 'disabled',
  Inactive = 'inactive',
  Draft = 'draft',
}
export interface IConfirmUserForm {
  id: string;
  code: string;
}
export enum UserRole {
  Admin = 'admin',
  Student = 'student',
  Tutor = 'tutor',
}
export type PhoneNumberType = {
  code: Scalars['String'];
  number: Scalars['String'];
};
export type ParentType = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  occupation: Scalars['String'];
  phone: PhoneNumberType;
  primaryContact: Scalars['Boolean'];
  isEmergencyContact: Scalars['Boolean'];
  relationship: Scalars['String'];
};
export function formParentDefault(): ParentType {
  return {
    relationship: '',
    email: '',
    firstName: '',
    lastName: '',
    occupation: '',
    phone: {
      code: '',
      number: '',
    },
    primaryContact: true,
    isEmergencyContact: true,
  };
}

export function formFamilyInformationDefault() {
  return {
    siblings: [],
    subscribe: true,
    payOnline: false,
    parents: [formParentDefault()],
    emergencyContact: {
      firstName: '',
      lastName: '',
      phone: {
        code: '',
        number: '',
      },
    },
  };
}

export enum DiscountCode {
  Referral = 'referral_discount',
  Sibling = 'sibling_discount',
  WeChat = 'we_chat',
  PowerPack = 'power_pack',
  ExclusivePrincipal = 'exclusive_principal',
  FullAnnualPayment = 'full_annual_payment',
  SemiAnnualPayment = 'semi_annual_payment',
  OffSubsequenceSubject = 'off_subsequence_subject',
  SuperEarlyBirdSignUp = 'super_early_bird_sign_up',
  EarlyBirdSignUp = 'early_bird_sign_up',
  MakeTheSwitch = 'make_the_switch',
}
