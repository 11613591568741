import { Auth } from '../auth';
import axios from 'axios';
import * as http from 'http';

export const apiService = axios.create({
  baseURL: process.env.REACT_APP_REST_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  httpAgent: new http.Agent({ keepAlive: true }),
});

export const axiosPublic = axios.create({
  baseURL: process.env.REACT_APP_REST_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiService.interceptors.request.use(
  async function (config) {
    const session = JSON.parse(localStorage.getItem('idg') as string);
    if (session?.accessToken) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${session.accessToken.jwtToken}`,
      };
    }

    return config;
  },
  function (error) {
    return error.response;
  },
);

apiService.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const config = error?.config;
    if (error?.response?.status === 401) {
      const session = JSON.parse(localStorage.getItem('idg') as any);
      const result = await axiosPublic.get(
        `/auth/access-token?username=${session.accessToken.payload.username}&refreshToken=${session.refreshToken.token}`,
      );
      localStorage.removeItem('idg');
      let { data: response } = result || {};
      const { data, success } = response || {};
      if (success) {
        if (data?.accessToken) {
          localStorage.setItem('idg', JSON.stringify(data));
          config.headers = {
            ...config.headers,
            authorization: `Bearer ${data?.accessToken?.jwtToken}`,
          };
        }
      }
      return axios(config);
    }
    return Promise.reject(error.response.data);
  },
);
