export enum RefundStatus {
  RejectedBySale = 'rejected_by_sale',
  RejectedByFinance = 'rejected_by_finance',
  Refunded = 'refunded',
  AwaitingFrontDeskAction = 'awaiting_front_desk_action',
  AwaitingSaleAction = 'awaiting_sale_action',
  AwaitingFinanceApproval = 'awaiting_finance_approval',
}

export const RefundStatusLabel: any = {
  rejected_by_sale: 'Rejected By Sale',
  rejected_by_finance: 'Rejected By Finance',
  refunded: 'Refunded',
  awaiting_front_desk_action: 'Awaiting Front Desk Action',
  awaiting_sale_action: 'Awaiting Sale Action',
  awaiting_finance_approval: 'Awaiting Finance Approval',
};

export enum DepositStatus {
  Issued = 'issued',
  Used = 'used',
  Paid = 'paid',
}
