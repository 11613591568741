import { Col, Row, Select, Table } from 'antd';
import {
  ColumnsType,
  Key,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@interfaces/constants';
import {
  Payment_Advice_Logs,
  useGetPaymentAdviceLogsLazyQuery,
} from '@services/data-access';
import Pill, { PillType } from '@components/Pill/Pill';
import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HistoryLogType } from '@models/historyLog';
import IndigoPagination from '@components/Pagination/IndigoPagination';
import { PaymentAdviceStatus } from '@models/payment-advice';
import TableSummary from '@components/TableSummary/TableSummary';
import { parseUTCToTime } from '@helpers';

const { Option } = Select;
export interface HistoryLogsTableProps {
  id?: string;
}

export const HistoryPALogsTable = ({ id }: HistoryLogsTableProps) => {
  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, Key[] | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<any>,
  ) => {
    console.log(pagination, filters, sorter, extra);
  };
  const [filterHistory, setFilterHistory] = useState({
    currentPage: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [
    getPaymentAdviceLog,
    { data: paymentAdviceLogs, loading, fetchMore, called },
  ] = useGetPaymentAdviceLogsLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (id) {
      if (called) {
        fetchMore({
          variables: {
            paId: id,
            offset: (filterHistory.currentPage - 1) * filterHistory.pageSize,
            limit: filterHistory.pageSize,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          },
        });
      } else {
        getPaymentAdviceLog({
          variables: {
            paId: id,
            offset: (filterHistory.currentPage - 1) * filterHistory.pageSize,
            limit: filterHistory.pageSize,
          },
        });
      }
    }
  }, [called, fetchMore, filterHistory, getPaymentAdviceLog, id]);

  const handlePageChange = (value: number) => {
    setFilterHistory({
      pageSize: value,
      currentPage: DEFAULT_PAGE,
    });
  };
  const handleChangeItemsPage = (value: number) => {
    setFilterHistory({
      ...filterHistory,
      currentPage: DEFAULT_PAGE,
      pageSize: value,
    });
  };
  const renderPill = (value: any) => {
    let color: PillType = 'primary';
    switch (value) {
      case PaymentAdviceStatus.Draft:
        color = 'warning';
        break;
      case PaymentAdviceStatus.OverDue:
        color = 'brown';
        break;
      case PaymentAdviceStatus.Paid:
        color = 'success';
        break;
      case PaymentAdviceStatus.UnPaid:
        color = 'danger';
        break;

      default:
        break;
    }
    return (
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <Pill text={value} type={color}></Pill>
      </div>
    );
  };
  const renderAction = (record: Payment_Advice_Logs) => {
    let Content: any = '';
    switch (record?.type) {
      case HistoryLogType.Update:
        Content = 'Update Content';
        break;
      case HistoryLogType.Create:
        Content = `Create New PA {${record?.pa?.name}}`;
        break;
      case HistoryLogType.ChangeStatus:
        Content = () => (
          <div style={{ display: 'flex' }}>
            Change Status from{' '}
            {renderPill(
              record?.valueBefore?.paymentAdvice?.paymentStatus ??
                record?.valueBefore?.paymentStatus,
            )}{' '}
            to{' '}
            {renderPill(
              record?.valueAfter?.paymentAdvice?.paymentStatus ??
                record?.valueAfter?.paymentStatus,
            )}
          </div>
        );
        break;
      case HistoryLogType.Duplicate:
        Content = ` Duplicate from PA {${record?.pa?.name}}`;
        break;
    }

    return (
      <Fragment>
        <div>{typeof Content === 'string' ? Content : <Content />}</div>
      </Fragment>
    );
  };
  const columns: ColumnsType<Payment_Advice_Logs> = [
    {
      title: 'Index',
      render: (text: string, record: Payment_Advice_Logs, index: number) => (
        <span>
          {index + 1 + filterHistory.pageSize * (filterHistory.currentPage - 1)}
        </span>
      ),
    },
    {
      title: 'User',
      render: (text: string, record: Payment_Advice_Logs) => (
        <Fragment>
          <div>{record?.creator?.fullName}</div>
          <div style={{ color: '#828894' }}>
            {record?.creator?.roles?.map((rl: any) => rl?.name).join(', ') ||
              '--'}
          </div>
        </Fragment>
      ),
    },
    {
      title: 'Timestamp',
      render: (text: string, record: Payment_Advice_Logs) => (
        <Fragment>
          <div>{parseUTCToTime(record?.updatedAt, 'hh:mm A')}</div>
          <div>{parseUTCToTime(record?.updatedAt, 'DD/MM/YYYY')}</div>
        </Fragment>
      ),
    },
    {
      title: 'Action',
      render: (text: string, record: Payment_Advice_Logs) =>
        renderAction(record),
    },
    {
      title: '',
      render: (text: string, record: Payment_Advice_Logs) => (
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`/finance/payment-advice/logs/${record.id}`}
        >
          <FontAwesomeIcon icon={['fal', 'eye']} size="lg" />
        </a>
      ),
    },
  ];

  return (
    <Fragment>
      <Row style={{ marginBottom: 20 }} justify="space-between" align="middle">
        <Col>
          <TableSummary
            currentNumberOfRecords={
              paymentAdviceLogs?.payment_advice_logs?.length || 0
            }
            currentPage={filterHistory.currentPage}
            pageSize={filterHistory.pageSize}
            total={
              paymentAdviceLogs?.payment_advice_logs_aggregate?.aggregate?.count
                ? paymentAdviceLogs?.payment_advice_logs_aggregate?.aggregate
                    ?.count
                : 0
            }
          />
        </Col>
        <Col>
          <Select
            value={filterHistory.pageSize}
            style={{ width: 170 }}
            onChange={handleChangeItemsPage}
          >
            <Option value={10}>10 items</Option>
            <Option value={20}>20 items</Option>
            <Option value={30}>30 items</Option>
          </Select>
        </Col>
      </Row>
      <div className="table-container table-clickable">
        <Table
          rowClassName="table-row-light"
          size="middle"
          columns={columns}
          rowKey={record => record.id || 0}
          dataSource={
            paymentAdviceLogs?.payment_advice_logs
              ? (paymentAdviceLogs?.payment_advice_logs as Payment_Advice_Logs[])
              : []
          }
          pagination={false}
          loading={loading}
          onChange={handleTableChange}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {},
            };
          }}
        />
      </div>
      <IndigoPagination
        currentPage={filterHistory.currentPage}
        total={
          paymentAdviceLogs?.payment_advice_logs_aggregate?.aggregate?.count
            ? paymentAdviceLogs?.payment_advice_logs_aggregate?.aggregate?.count
            : 0
        }
        pageSize={filterHistory.pageSize}
        onPageChange={handlePageChange}
        disabled={loading}
      />
    </Fragment>
  );
};

export default HistoryPALogsTable;
