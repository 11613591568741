import React from 'react';

import { Roles } from '../../models';

const GantryRecord = React.lazy(
  () => import(/* webpackChunkName: "gantry-record" */ './GantryRecord'),
);
const AttendanceList = React.lazy(
  () => import(/* webpackChunkName: "attendance-list" */ './list'),
);
const AttendanceMonitor = React.lazy(
  () => import(/* webpackChunkName: "attendance-monitor" */ './monitor'),
);
const AttendanceDetail = React.lazy(
  () => import(/* webpackChunkName: "attendance-detail" */ './detail'),
);

export default [
  {
    path: '/attendance/list',
    component: AttendanceList,
    exact: true,
    title: 'Attendance List',
    roles: [
      Roles.FulltimeTutor,
      Roles.PartTimeTutor,
      Roles.HOD,
      Roles.AttendanceChecker,
      Roles.OpsManager,
      Roles.FrontDesk,
    ],
  },
  {
    path: '/attendance/monitor',
    component: AttendanceMonitor,
    exact: true,
    title: 'Attendance Monitor',
    roles: [
      Roles.FulltimeTutor,
      Roles.PartTimeTutor,
      Roles.HOD,
      Roles.OpsManager,
      Roles.FrontDesk,
    ],
  },
  {
    path: '/attendance/detail/:id',
    component: AttendanceDetail,
    exact: true,
    title: 'Attendance Detail',
    roles: [
      Roles.FulltimeTutor,
      Roles.PartTimeTutor,
      Roles.HOD,
      Roles.OpsManager,
      Roles.FrontDesk,
      Roles.AttendanceChecker,
    ],
  },
  {
    path: '/attendance/gantry-record',
    component: GantryRecord,
    exact: true,
    title: 'Gantry Record',
    roles: [
      Roles.FulltimeTutor,
      Roles.PartTimeTutor,
      Roles.HOD,
      Roles.OpsManager,
      Roles.FrontDesk,
    ],
  },
];

// export default [
//   {
//     path: '/attendance/gantry-record',
//     component: GantryRecord,
//     exact: true,
//     title: 'Gantry Record',
//     roles: [
//       Roles.FulltimeTutor,
//       Roles.PartTimeTutor,
//       Roles.HOD,
//       Roles.OpsManager,
//       Roles.FrontDesk,
//     ],
//   },
// ];
