import './style.scss';

import { Col, Form, Layout, Row, Switch, Tag, Typography } from 'antd';
import React, { FunctionComponent, useEffect } from 'react';
import { CreatePaymentAdviceFormSession } from '../interface/CreatePaymentAdviceFormSession';
import CurrencyInput from '@components/CurrencyInput/component';
import StaticContainer from '@components/StaticContainer';

import { debounce } from 'lodash';
import moment from 'moment-timezone';

interface IRegistrationFeeProps extends CreatePaymentAdviceFormSession {}

const RegistrationFee: FunctionComponent<IRegistrationFeeProps> = ({
  onChange,
  disabled,
  paymentAdvice,
}) => {
  const formName = 'isRegistrationFee';
  const [form] = Form.useForm();
  const debouncedCallApi = debounce(() => {
    const isRegistrationFee = form.getFieldsValue(['content']);
    onChange({
      [formName]: isRegistrationFee.content,
    });
  }, 500);

  const isWaiveDisabled = React.useMemo(() => {
    if (!!paymentAdvice) {
      const { amount, waiverPeriod } =
        paymentAdvice?.registrationFeeConfig || {};
      const { startDate, endDate } = waiverPeriod || {};
      let issueDate: any = paymentAdvice.issueDate;

      if (!issueDate) {
        return true;
      }

      issueDate = moment(issueDate);

      return (
        (moment(startDate).isSameOrBefore(issueDate) &&
          moment(endDate).isSameOrAfter(issueDate)) ||
        disabled
      );
    }
  }, [paymentAdvice]);

  const isWaved = () => {
    return paymentAdvice.isRegistrationFee;
  };

  const onChangeRegistrationFee = () => {
    debouncedCallApi();
  };

  useEffect(() => {
    if (!paymentAdvice.issueDate) {
      form.setFieldsValue({
        content: false,
      });
    } else {
      form.setFieldsValue({
        content: paymentAdvice.isRegistrationFee || false,
      });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isWaiveDisabled) {
      form.setFieldsValue({
        content: false,
      });
    } else {
      form.setFieldsValue({
        content: paymentAdvice.isRegistrationFee || false,
      });
    }

    // eslint-disable-next-line
  }, [paymentAdvice.issueDate, isWaiveDisabled]);

  const { amount, waiverPeriod } = paymentAdvice?.registrationFeeConfig || {};

  return (
    <div className="registration-fee create-pa__group">
      <StaticContainer loading={!paymentAdvice?.registrationFeeConfig}>
        <div>
          <Form form={form} name={formName} layout="vertical">
            <Row justify="space-between" style={{ marginBottom: 20 }}>
              <p className="create-pa__title">Registration Fee</p>
              <Form.Item
                valuePropName="checked"
                label="Apply"
                className="form-item-hor"
                style={{ marginBottom: 0, minHeight: 'initial' }}
                name={'content'}
              >
                <Switch
                  disabled={isWaiveDisabled}
                  onChange={onChangeRegistrationFee}
                />
              </Form.Item>
            </Row>
          </Form>

          <Layout.Content className="create-pa__content">
            <Row justify="space-between" gutter={[20, 0]} align="bottom">
              <Col span={12}>
                <Typography.Text>Amount</Typography.Text>
                <div className="relative">
                  <CurrencyInput
                    value={
                      !paymentAdvice.isRegistrationFee ? 'S$00.00' : amount
                    }
                    disabled={true}
                  />
                  {isWaved() && isWaiveDisabled && (
                    <Tag
                      style={{
                        position: 'absolute',
                        top: '50%',
                        right: 20,
                        margin: 0,
                        transform: 'translateY(-50%)',
                      }}
                      className="idg-tag idg-tag--primary"
                    >
                      Waived
                    </Tag>
                  )}
                </div>
              </Col>
              <Col span={12} className="registration-fee__waiver-period">
                <Typography.Text>{`Waiver period: ${
                  waiverPeriod?.startDate
                    ? moment(waiverPeriod.startDate).format('DD/MM/YYYY')
                    : 'DD/MM/YYYY'
                } - ${
                  waiverPeriod?.endDate
                    ? moment(waiverPeriod.endDate).format('DD/MM/YYYY')
                    : 'DD/MM/YYYY'
                }`}</Typography.Text>
              </Col>
            </Row>
          </Layout.Content>
        </div>
      </StaticContainer>
    </div>
  );
};

export default RegistrationFee;
