export enum TypeLessonStudent {
  Enrolled = 'enrolled',
  Transferred = 'transferred',
  Replacement = 'replacement',
  Suspended = 'suspended',
  Withdrawn = 'withdrawn',
  Withdrawal = 'withdrawal',
  Pending = 'pending',
  Returned = 'returned',
  Completed = 'completed',
  HasBeenTransferred = 'has_been_transferred',
  HasBeenReplaced = 'has_been_replaced',
  Voided = 'voided',
}

export const TypeLessonStudentLabel: any = {
  enrolled: 'Enrolled',
  transferred: 'Transferred',
  replacement: 'Replacement',
  suspended: 'Suspended',
  withdrawn: 'Withdrawn',
  withdrawal: 'Withdrawn',
  pending: 'Pending',
  returned: 'Returned',
  completed: 'Completed',
  has_been_transferred: 'Has Been Transferred',
  has_been_replaced: 'Has Been Replaced',
};
