import {
  IGetClassInformationForCreditNoteReq,
  ICreateCreditNoteReq,
  IUpdateCreditNoteReq,
} from '@interfaces/network';
import { apiService } from '@services/api';
import { CreditNoteStatus } from '@models';

const getClassInformationForCreditNote = async (
  request: IGetClassInformationForCreditNoteReq,
) =>
  await apiService.get(`/credit-notes/get-class-information-for-credit-note`, {
    params: request,
  });

const createCreditNote = async (request: ICreateCreditNoteReq) =>
  apiService.post(`/credit-notes`, request);

const updateCreditNote = async (id: string, request: IUpdateCreditNoteReq) =>
  apiService.put(`/credit-notes/${id}`, request);

const updateCreditNoteStatus = async (id: string, status: CreditNoteStatus) =>
  apiService.patch(`/credit-notes/change-status-credit-note/${id}`, {
    status,
  });

const duplicateCreditNote = async (creditNoteId: string) =>
  apiService.post(`/credit-notes/duplicate-credit-note`, {
    creditNoteId,
  });

export {
  getClassInformationForCreditNote,
  createCreditNote,
  updateCreditNote,
  updateCreditNoteStatus,
  duplicateCreditNote,
};
