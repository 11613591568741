import './styles.scss';

import {
  Avatar,
  Button,
  Layout,
  Menu,
  Popconfirm,
  Row,
  Tag,
  message,
} from 'antd';
import { Roles } from '../../models';
import {
  MenuItem,
  adminMenu,
  attendanceCheckerMenu,
  financeManagerMenu,
  financeStaffMenu,
  frontDeskMenu,
  fullTimeTutorMenu,
  hodMenu,
  hrMenu,
  internMenu,
  opsManagerMenu,
  partTimeTutorMenu,
  salesMenu,
} from './Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
// import Logo from '@assets/img/Nav_Logo_White@3x.png';
import Logo from '@assets/img/Indigo_logo_ERP.png';
import React from 'react';
import SubMenu from 'antd/lib/menu/SubMenu';
import uniqBy from 'lodash/uniqBy';
import { useAuth } from '@hooks';
import { Auth } from '@services/auth';
import { useHistory } from 'react-router';
import { IndigoAvatar } from '@components/Avatar';
import moment from 'moment';

export interface INavigation {
  collapsed: boolean;
  toggleCollapse: () => void;
}

const Navigation: React.FunctionComponent<INavigation> = ({
  collapsed,
  ...props
}) => {
  const [auth, setAuth] = useAuth();
  const history = useHistory();

  const userRoles = auth?.multipleRoles || [];
  let data: MenuItem[] = [];
  let roleText = [];
  if (userRoles?.includes(Roles.Admin)) {
    data = adminMenu;
    roleText.push('Admin');
  }
  if (userRoles?.includes(Roles.FrontDesk)) {
    data.unshift(...frontDeskMenu);
    roleText.push('Front Desk');
  }
  if (userRoles?.includes(Roles.OpsManager)) {
    data.unshift(...opsManagerMenu);
    roleText.push('Ops Manager');
  }
  if (userRoles?.includes(Roles.Sales)) {
    data.unshift(...salesMenu);
    roleText.push('Sales');
  }
  if (userRoles?.includes(Roles.HR)) {
    data.unshift(...hrMenu);
    roleText.push('Hr');
  }
  if (userRoles?.includes(Roles.FinanceStaff)) {
    data.unshift(...financeStaffMenu);
    roleText.push('Finance Staff');
  }
  if (userRoles?.includes(Roles.FinanceManager)) {
    data.unshift(...financeManagerMenu);
    roleText.push('Finance Manager');
  }
  if (userRoles?.includes(Roles.AttendanceChecker)) {
    data.unshift(...attendanceCheckerMenu);
    roleText.push('Attendance Checker');
  }
  if (userRoles?.includes(Roles.Intern)) {
    data.unshift(...internMenu);
    roleText.push('Intern');
  }
  if (userRoles?.includes(Roles.FulltimeTutor)) {
    data.unshift(...fullTimeTutorMenu);
    roleText.push('Fulltime Tutor');
  }
  if (userRoles?.includes(Roles.PartTimeTutor)) {
    data.unshift(...partTimeTutorMenu);
    roleText.push('PartTime Tutor');
  }
  if (userRoles?.includes(Roles.HOD)) {
    data.unshift(...hodMenu);
    roleText.push('HOD');
  }
  data = uniqBy(data, 'key');

  const handleLogout = async () => {
    try {
      localStorage.removeItem('idg');
      setAuth({});
      history.push('/login');
    } catch (error) {
      message.error(error?.toString());
    }
  };

  return (
    <React.Fragment>
      <Layout.Sider
        collapsed={collapsed}
        width={210}
        className="navigation"
        collapsible
        trigger={null}
      >
        <div style={{ display: 'flex' }}>
          <div className="logo text-center">
            <Link to="/">
              <img
                src={Logo}
                alt="logo"
                className={collapsed ? 'img-logo' : 'img-logo expand-logo'}
              />
            </Link>
          </div>

          <div className="collapse-icon">
            <FontAwesomeIcon
              icon={['fal', collapsed ? 'angle-right' : 'angle-left']}
              onClick={props.toggleCollapse}
            />
          </div>
        </div>
        <Menu
          style={{ flex: '1 1', overflow: 'auto' }}
          theme="dark"
          mode="inline"
        >
          {data.map((e, index) =>
            e.subMenu ? (
              // @ts-ignore
              <SubMenu key={e.key} icon={e.icon} title={e.label}>
                {e.subMenu.map((s, i) =>
                  s.subMenu ? (
                    // @ts-ignore
                    <SubMenu key={s.key} icon={s.icon} title={s.label}>
                      {s.subMenu.map((ss, ii) => (
                        <Menu.Item key={ss.key}>
                          <Link to={ss.href ? ss.href : ''}>{ss.label}</Link>
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  ) : (
                    <Menu.Item key={s.key}>
                      <Link to={s.href ? s.href : ''}>
                        {s.icon}
                        <span>{s.label}</span>
                      </Link>
                    </Menu.Item>
                  ),
                )}
              </SubMenu>
            ) : (
              <Menu.Item key={e.key}>
                <Link to={e.href ? e.href : ''}>
                  {e.icon}
                  <span>{e.label}</span>
                </Link>
              </Menu.Item>
            ),
          )}
        </Menu>

        <div className="navigation__auth">
          <Link
            to={`/user/${auth?.id}/view`}
            style={collapsed ? { textAlign: 'center', transition: '0.2s' } : {}}
          >
            <Row>
              <Row
                style={{
                  position: 'absolute',
                  left: 0,
                  marginLeft: collapsed ? '5px' : 0,
                }}
              >
                <IndigoAvatar
                  size={collapsed ? 40 : 32}
                  imgUrl={auth.avatarUrl}
                  icon={<FontAwesomeIcon icon={['far', 'user']} />}
                />
              </Row>

              <Row align="middle">
                <p
                  className={
                    collapsed
                      ? 'profile-info text profile-info-hide'
                      : 'text profile-info'
                  }
                >{`${auth.firstName} ${auth.lastName}`}</p>{' '}
                <p
                  className={
                    collapsed
                      ? 'profile-info text profile-info-hide'
                      : 'text profile-info'
                  }
                >
                  <Tag
                    style={{
                      textTransform: 'capitalize',
                      borderRadius: 0,
                    }}
                    color="#24a148"
                  >
                    {[...roleText]?.pop()}
                  </Tag>
                </p>
              </Row>
            </Row>
          </Link>
        </div>

        <Popconfirm
          placement="rightTop"
          title={`Do you want to Log out?`}
          onConfirm={handleLogout}
          okText="Yes"
          cancelText="No"
        >
          {collapsed ? (
            <Button className="logout-btn icon">
              <FontAwesomeIcon icon={['far', 'sign-out']} />
            </Button>
          ) : (
            <Button className="logout-btn">
              Log out <FontAwesomeIcon icon={['far', 'sign-out']} />
            </Button>
          )}
        </Popconfirm>
        <div
          className={
            collapsed
              ? 'navigation__footer navigation__footer-hide'
              : 'navigation__footer '
          }
        >
          <p className="navigation__footer__text">
            {`© ${moment(new Date()).format(
              'YYYY',
            )} Copyright Indigo Education Group`}
          </p>
        </div>
      </Layout.Sider>
    </React.Fragment>
  );
};

export default Navigation;
