import './styles.scss';

import React from 'react';

export default function SplashScreen() {
  return (
    <div className="splash-screen">
      <svg
        className="spinner"
        width="48px"
        height="48px"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="path"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>
    </div>
  );
}
