import React, { FunctionComponent } from 'react';

export interface TableSummaryProps {
  currentPage: number;
  pageSize: number;
  currentNumberOfRecords: number;
  total: number;
}

const TableSummary: FunctionComponent<TableSummaryProps> = ({
  currentPage,
  pageSize,
  currentNumberOfRecords,
  total,
}) => {
  return (
    <div className="text-ink">
      Viewing from{' '}
      {currentNumberOfRecords > 0 ? (currentPage - 1) * pageSize + 1 : 0} -{' '}
      {(currentPage - 1) * pageSize + (currentNumberOfRecords || 0)} (out of{' '}
      <span className="text-bold">{total}</span> records)
    </div>
  );
};

export default TableSummary;
