import React from 'react';

import { Roles } from '@models';

const RewardReport = React.lazy(() => import('@pages/reward/report'));
const ReferralList = React.lazy(() => import('@pages/reward/referral/list'));
const ReferralSetup = React.lazy(
  () => import('@pages/reward/referral/view/ReferralForm'),
);

export default [
  {
    path: '/reward/report',
    component: RewardReport,
    exact: true,
    title: 'Reward Report',
    isPublic: true,
    roles: [Roles.FinanceManager, Roles.FinanceStaff, Roles.FrontDesk],
  },
  {
    path: '/reward/referral',
    component: ReferralList,
    exact: true,
    title: 'Referral Setup',
    isPublic: true,
    roles: [Roles.FinanceManager, Roles.FinanceStaff, Roles.FrontDesk],
  },
  {
    path: '/reward/referral/create',
    component: ReferralSetup,
    exact: true,
    title: 'Create Referral Setup',
    isPublic: true,
    roles: [Roles.FinanceManager, Roles.FinanceStaff, Roles.FrontDesk],
  },
  {
    path: '/reward/referral/:id/update',
    component: ReferralSetup,
    exact: true,
    title: 'Referral Setup Detail',
    isPublic: true,
    roles: [Roles.FinanceManager, Roles.FinanceStaff, Roles.FrontDesk],
  },
];
