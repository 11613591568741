import React, { Suspense, useState } from 'react';
import { Layout, Spin } from 'antd';
import LoginRoute, { ChangePassRoute } from '@pages/login/router';
import PrivateRoute, { authenticatedRouter } from './PrivateRoute';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ErrorBoundaryProvider } from '@components/Header/ErrorBoundaryProvider';
import Navigation from '@components/Navigation/Navigation';
import { Roles } from '@models';
// import StudentRegisterFrom from '@pages/student-management/student-register-form/router';

import intersection from 'lodash/intersection';
import { useErrorBoundary } from '@components/Header/ErrorBoundaryProvider';
import { useAuth, withAuth } from '@hooks';

const NotFound = React.lazy(
  () => import(/* webpackChunkName: "not-found" */ '@pages/notfound/NotFound'),
);

const unAuthenticatedRouter = [...LoginRoute];
// const unAuthenticatedRouter = [...LoginRoute, ...StudentRegisterFrom];

const changePasswordRoute = [...ChangePassRoute];

const history = createBrowserHistory();

const Core = () => {
  const [{ isLoggedIn, multipleRoles }] = useAuth();
  const [error] = useErrorBoundary();
  const [collapsed, setCollapse] = useState<boolean>(false);
  const { pathname } = history.location;

  let directPathname = [
    '/login',
    '/forgot-password',
    '/change-password',
  ].includes(pathname)
    ? '/'
    : pathname;

  if (isLoggedIn) {
    if (multipleRoles?.includes(Roles.Admin)) {
      directPathname = '/company';
    }
    if (multipleRoles?.includes(Roles.FrontDesk)) {
      directPathname = '/student-management';
    }
    if (multipleRoles?.includes(Roles.OpsManager)) {
      directPathname = '/branch';
    }
    if (multipleRoles?.includes(Roles.HR)) {
      directPathname = '/user';
    }
    if (multipleRoles?.includes(Roles.FinanceStaff)) {
      directPathname = '/student-management';
    }
    if (multipleRoles?.includes(Roles.FinanceManager)) {
      directPathname = '/student-management';
    }
    if (multipleRoles?.includes(Roles.AttendanceChecker)) {
      directPathname = '/attendance/list';
    }
    if (multipleRoles?.includes(Roles.Sales)) {
      directPathname = '/communication/broadcast';
    }
    if (multipleRoles?.includes(Roles.Intern)) {
      directPathname = '/student-management';
    }
    if (multipleRoles?.includes(Roles.FulltimeTutor)) {
      directPathname = '/attendance/list';
    }
    if (multipleRoles?.includes(Roles.PartTimeTutor)) {
      directPathname = '/attendance/list';
    }
    if (multipleRoles?.includes(Roles.HOD)) {
      directPathname = '/attendance/list';
    }
  }
  // const pathname = router.location.pathname;

  // const directPathname =
  //   pathname !== '/login' &&
  //   pathname !== '/forgot-password' &&
  //   !pathname.includes('/change-password')
  //     ? pathname
  //     : '/';

  const toggleCollapse = React.useCallback(
    () => setCollapse(!collapsed),
    [collapsed],
  );

  const privateRoutes = multipleRoles?.includes(Roles.Admin)
    ? authenticatedRouter
    : authenticatedRouter.filter(
        route => intersection(route.roles, multipleRoles).length,
      );

  return (
    <Layout style={{ height: '100vh' }}>
      <Layout style={{ background: '#FAFBFD' }}>
        <Suspense fallback={<Spin />}>
          <Router>
            {isLoggedIn && (
              <Navigation
                collapsed={collapsed}
                toggleCollapse={toggleCollapse}
              />
            )}
            <Layout.Content
              style={{
                marginTop: error.message ? 108 : 60,
                padding: 20,
                minHeight: 400,
                position: 'relative',
              }}
              id="scrollableDiv"
            >
              <Switch>
                {unAuthenticatedRouter.map(({ path, component: Component }) => (
                  <Route path={path} exact key={path}>
                    {isLoggedIn ? (
                      <Redirect to={directPathname} />
                    ) : (
                      <Component />
                    )}
                  </Route>
                ))}
                {changePasswordRoute.map(({ path, component: Component }) => (
                  <Route path={path} key={path}>
                    <Component />
                  </Route>
                ))}
                <Route exact path="/">
                  {isLoggedIn ? (
                    <Redirect to={directPathname} />
                  ) : (
                    <Redirect to="/login" />
                  )}
                </Route>
                {privateRoutes.map(
                  ({ component: Component, path, title, ...rest }) => (
                    <PrivateRoute
                      {...rest}
                      key={path}
                      path={path}
                      title={title}
                      isAuthenticated={isLoggedIn}
                      collapsed={collapsed}
                    >
                      <ErrorBoundaryProvider>
                        <Component />
                      </ErrorBoundaryProvider>
                    </PrivateRoute>
                  ),
                )}
                <Route path="*">
                  <NotFound isAuthenticated={isLoggedIn} />
                </Route>
              </Switch>
            </Layout.Content>
          </Router>
        </Suspense>
      </Layout>
    </Layout>
  );
};

export default withAuth(Core);
