import {
  IGenerateInvoiceReq,
  IUpdatePaymentVoidedReq,
  IUpdatePaymentVoidedReq2,
  IVoidUnpaidPaymentAdviceReq,
  ISendEmailUnpaidPaymentAdviceReq,
} from '@interfaces/network';
import { apiService } from '@services/api';

const getPaymentAdvicePaper = async (id: string) =>
  await apiService.get(`/finances/payment-advices/${id}/paper`);

const generateInvoice = async (request: IGenerateInvoiceReq) =>
  await apiService.post(`/finances/invoices`, request);

const updatePaymentVoided = async (request: IUpdatePaymentVoidedReq) =>
  await apiService.post(`/finances/payment-advices/void-unpaid`, request);

const updatePaymentVoided2 = async (request: IUpdatePaymentVoidedReq2) =>
  await apiService.patch(`/finances/payment-advices/void-paid-pa`, request);

const voidUnpaidPaymentAdvice = async (request: IVoidUnpaidPaymentAdviceReq) =>
  await apiService.post(`/finances/payment-advices/void-unpaid`, request);

const sendEmailUnpaidPaymentAdvice = async (
  request: ISendEmailUnpaidPaymentAdviceReq,
) => await apiService.post(`/finances/email/unpaid-pa-to-parents`, request);

export {
  getPaymentAdvicePaper,
  generateInvoice,
  updatePaymentVoided,
  updatePaymentVoided2,
  voidUnpaidPaymentAdvice,
  sendEmailUnpaidPaymentAdvice,
};
