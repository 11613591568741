import { apiService } from '@services/api';
import { ISendBroadcastReq, ISendNewsReq } from '@interfaces/network';

const sendBroadcast = async (request: ISendBroadcastReq) =>
  await apiService.post(`/broadcast`, request);

const sendNews = async (request: ISendNewsReq) =>
  await apiService.post(`/news`, request);

export { sendBroadcast, sendNews };
