import { Button, Col, Modal, Row, Typography } from 'antd';
import React, { FunctionComponent } from 'react';

import { DialogProps } from '../../../../../../../models/dialog';
import { Sessions } from '../../../../../../../services/data-access';
import moment from 'moment';

export interface SelectClassSessionPopupProps extends DialogProps {
  sessions: Sessions[];
  lessonStartDate: string;
}

const SelectClassSessionPopup: FunctionComponent<
  SelectClassSessionPopupProps
> = ({ onClose, visible, sessions, lessonStartDate }) => {
  return (
    <Modal
      title={`View Session (${lessonStartDate})`}
      visible={visible}
      onCancel={() => onClose('cancel')}
      footer={[
        <Button
          className="fixed-width-btn button-black-text"
          key="back"
          onClick={() => onClose('cancel')}
        >
          Close
        </Button>,
      ]}
    >
      {sessions.map((session, index) => (
        <Row key={session.id} gutter={[10, 10]}>
          <Col span={24}>
            <div className="text-bold text-ink">Session {index + 1}</div>
          </Col>
          <Col span={24}>
            <Row justify="space-between" gutter={[20, 0]}>
              <Col>
                <Typography.Text type="secondary">Time</Typography.Text>
              </Col>
              <Col className="text-ink">
                {moment(session.startTime.split('+')[0], 'hh:mm:ss').format(
                  'hh:mm A',
                )}{' '}
                -{' '}
                {moment(session.endTime.split('+')[0], 'hh:mm:ss').format(
                  'hh:mm A',
                )}
              </Col>
            </Row>
            <Row justify="space-between" gutter={[20, 0]}>
              <Col>
                <Typography.Text type="secondary">Tutor</Typography.Text>
              </Col>
              <Col className="text-ink">
                {session.tutors
                  ?.map(tutor => tutor.firstName + ' ' + tutor.lastName)
                  .join(', ')}
              </Col>
            </Row>
          </Col>
        </Row>
      ))}
    </Modal>
  );
};

export default SelectClassSessionPopup;
