import { Button, Col, Form, Input, Layout, Row } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  formatCurrencyFullText,
  convertToNumber,
  parseCurrencyToValue,
} from '@helpers';
import { CreatePaymentAdviceFormSession } from '../interface/CreatePaymentAdviceFormSession';
import CurrencyInput from '@components/CurrencyInput/component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '@ant-design/icons/lib/components/Icon';
import { debounce } from 'lodash';
// import { useGetSystemSettingsQuery } from '@services/data-access';

interface DepositProps extends CreatePaymentAdviceFormSession {
  defaultAmt: number;
  disabledButton?: boolean;
}

const Deposit: FunctionComponent<DepositProps> = ({
  paymentAdvice,
  disabled,
  defaultAmt,
  onChange,
  ...props
}) => {
  const formName = 'deposit';
  const [form] = Form.useForm();
  const [defaultDeposit, setDefaultDeposit] = useState('');
  const [callField, setCallField] = useState(true);

  const debouncedCallApi = debounce(async () => {
    setCallField(false);

    // const currentDeposit = form.getFieldsValue(['content']);

    // const removeFieldNotValue = currentDeposit.content.filter(
    //   (e: any) => !!e.name && !!e.amount,
    // );

    // onChange({
    //   [formName]: removeFieldNotValue.map((e: any) => {
    //     return {
    //       name: e?.name?.trim() ?? '',
    //       amount: parseCurrencyToValue(e.amount),
    //     };
    //   }),
    // });
  }, 300);

  const debounceFn = React.useCallback(debounce(onChange, 450), []);

  // useGetSystemSettingsQuery({
  //   variables: {
  //     filter: {
  //       type: {
  //         _eq: 'deposit',
  //       },
  //     },
  //   },
  //   onCompleted: result => {
  //     if (result?.system_settings) {
  //       setDefaultDeposit(result?.system_settings[0]?.value || 0);
  //     }
  //   },
  //   onError: () => {},
  // });

  const onChangeDeposit = (index: number) => {
    const formItemValue = form.getFieldValue('content');

    formItemValue[index] = {
      ...formItemValue[index],
      amount: formItemValue[index].amount || defaultAmt,
    };
    form.setFieldsValue({
      content: formItemValue,
    });

    const currentDeposit = form.getFieldsValue(['content']);
    let deposit: { error: string | null; rows: any[] } = {
      error: null,
      rows: [],
    };

    currentDeposit.content.forEach((e: any) => {
      if (!!e.name) {
        deposit.rows.push({
          name: e.name,
          amount: convertToNumber((e.amount || defaultAmt).toString()),
        });
      }
    });
    debounceFn(deposit);
    // const valid = await form.validateFields();
    // if (valid) {
    //   debouncedCallApi();
    // }
  };

  const onBlurDeduction = async (
    e: React.FocusEvent<HTMLInputElement>,
    index: number,
  ) => {
    e.preventDefault();
    const valid = await form.validateFields();
    if (valid) {
      debouncedCallApi();
    }
  };

  const handleRemove = (index: number, remove: (i: number) => void) => {
    remove(index);
    debouncedCallApi();

    let deposit: { error: string | null; rows: any[] } = {
      error: null,
      rows: [],
    };
    const currentDeposit = form.getFieldsValue(['content']);
    currentDeposit.content.forEach((e: any) => {
      if (!!e.name) {
        deposit.rows.push({
          name: e.name,
          amount: convertToNumber((e.amount || defaultAmt).toString()),
        });
      }
    });
    debounceFn(deposit);
  };

  useEffect(() => {
    if (callField && !!paymentAdvice?.data?.length) {
      form.setFieldsValue({
        content: paymentAdvice?.data || [],
      });
    }
  }, [callField, form, paymentAdvice]);

  const onFieldChanged = () => {
    form
      .validateFields()
      .then(res => console.log({ res }))
      .catch(error => {
        const { errorFields, values } = error;
        let deposit: { error: string | null; rows: any[] } = {
          error: null,
          rows: [],
        };
        deposit.error = errorFields?.length
          ? `Deposit ${errorFields[0]?.errors?.[0]}`
          : null;
        values.content.forEach((e: any) => {
          if (!!e.name && !!e.amount) {
            deposit.rows.push({
              name: e.name,
              amount: convertToNumber(e.amount.toString()),
            });
          }
        });
        debounceFn(deposit);
      });
  };

  return (
    <div>
      <Row justify="space-between">
        <p className="title" style={{ padding: 0 }}>
          Deposit
        </p>
        <p className="title" style={{ padding: 0 }}>
          (Total:{' '}
          {formatCurrencyFullText(paymentAdvice?.total?.toString() || '0')})
        </p>
      </Row>

      <div>
        <Form
          form={form}
          name={formName}
          layout="vertical"
          onValuesChange={onFieldChanged}
        >
          <Form.List name="content">
            {(fields, { add, remove }) => (
              <div>
                {fields &&
                  fields.map((field, index) => {
                    return (
                      <Row
                        justify="space-between"
                        gutter={[20, 0]}
                        align="top"
                        style={{
                          marginTop: index > 0 ? 20 : 0,
                          marginBottom: 20,
                        }}
                        key={index}
                      >
                        <Col span={11}>
                          <Form.Item
                            label="(*) Name"
                            rules={[
                              { required: true, message: 'Name is required.' },
                            ]}
                            style={{ marginBottom: 0 }}
                            name={[index, 'name']}
                          >
                            <Input
                              disabled={disabled}
                              placeholder="Fill in Deposit name"
                              onBlur={e => onBlurDeduction(e, index)}
                              // onChange={e => onChangeDeposit(index)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={11}>
                          <Form.Item
                            label="(*) Amount"
                            style={{ marginBottom: 0 }}
                            name={[index, 'amount']}
                            rules={[
                              {
                                required: true,
                                message: 'Amount is required.',
                              },
                            ]}
                          >
                            <CurrencyInput
                              placeholder="Fill in amount"
                              onBlur={e => onBlurDeduction(e, index)}
                              // onChange={e => onChangeDeposit(index)}
                              disabled={disabled}
                              // defaultValue={`S$${defaultAmt}.00`}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          {disabled ? null : (
                            <Button
                              type="link"
                              disabled={props.disabledButton}
                              onClick={() => {
                                handleRemove(index, remove);
                              }}
                              className="width-auto"
                              style={{
                                color: '#f54f64',
                                padding: 0,
                                margin: '30px auto 0',
                                display: 'block',
                              }}
                              icon={
                                <Icon viewBox={'0, 0, 1024, 1024'}>
                                  <FontAwesomeIcon
                                    icon={['far', 'trash-alt']}
                                  />
                                </Icon>
                              }
                            ></Button>
                          )}
                        </Col>
                      </Row>
                    );
                  })}
                {!disabled && (
                  <Button
                    type="primary"
                    ghost
                    style={{
                      width: '100%',
                      marginTop: 0,
                    }}
                    disabled={props.disabledButton}
                    htmlType="button"
                    onClick={() => {
                      const data = form.getFieldValue('content');
                      // form.setFieldsValue({
                      //   content: {
                      //     [fields.length]: defaultAmt,
                      //   },
                      // });
                      add({ amount: `S$${defaultAmt}.00` });
                    }}
                    icon={
                      <Icon viewBox={'0, 0, 1024, 1024'}>
                        <FontAwesomeIcon icon={['fal', 'plus']} />
                      </Icon>
                    }
                  >
                    Add Deposit Item
                  </Button>
                )}
              </div>
            )}
          </Form.List>
        </Form>
      </div>
    </div>
  );
};

export default Deposit;
