import * as auth from '@aws-amplify/auth';

import { apiService } from '@services/api';
import { IAuthService } from './types';

const amplifyAuth = new auth.AuthClass({
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
});

export function AmplifyAuthService(): IAuthService {
  return {
    currentSession: () => amplifyAuth.currentSession(),
    currentUser: async () => {
      try {
        return await amplifyAuth.currentUserPoolUser();
      } catch (error) {
        return null;
      }
    },
    getAccessToken: async () => {
      try {
        const currentUser = await amplifyAuth.currentSession();

        return currentUser.getAccessToken().getJwtToken();
      } catch (error) {
        return null;
      }
    },
    checkUserBlock: async (username: string, defaultMessage: string) => {
      let msg = defaultMessage;

      try {
        let { data: response } = await apiService.patch(
          `/users/${username}/check-block`,
          {
            username,
          },
        );

        let { data, success } = response || {};

        if (success) {
          if (data.isBlocked) {
            msg =
              'Your account has been locked due to multiple failed login attempts. Kindly contact the administrator to unlock it.';
          }
        }
      } catch (error: any) {
        msg = error?.message;
      }

      return msg;
    },
    signIn: ({ email, password }) => {
      return amplifyAuth.signIn(email, password);
    },
    logOut: () => {
      return amplifyAuth.signOut();
    },
    forgotPassword: (email: string) => amplifyAuth.forgotPassword(email),
    changePassword: async (oldPassword, newPassword) => {
      const user = await AmplifyAuthService().currentUser();
      return amplifyAuth.changePassword(user, oldPassword, newPassword);
    },
    forgotPasswordSubmit: (email: string, code: string, password: string) =>
      amplifyAuth.forgotPasswordSubmit(email, code, password),
  };
}
