import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, Form, Spin } from 'antd';
import moment from 'moment';

import {
  Credit_Note_Logs,
  Credit_Notes,
  useGetCreditNoteLogsLazyQuery,
} from '@services/data-access';
import { CreditNoteStatus, CreditNoteType, HistoryLogType } from '@models';
import CreditNoteClassFormItem from '../forms/form-components/CreditNoteClassFormItem';
import CreditNoteInfo from '../forms/form-components/CreditNoteInfo';
import CreditNoteProRatedFeeFormItem from '../forms/form-components/CreditNoteProRatedFeeFormItem';
import CreditNoteReasonFormItem from '../forms/form-components/CreditNoteReasonFormItem';
import CreditNoteRemarksFormItem from '../forms/form-components/CreditNoteRemarksFormItem';
import CreditNoteStudentFormItem from '../forms/form-components/CreditNoteStudentFormItem';
import { PALogMetadata } from '../../ModalHistoryLogsTable/PALogMetadata';

const CNLogsDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [studentId, setStudentId] = useState('');
  const [getCreditNoteDetail, { data, loading }] =
    useGetCreditNoteLogsLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        where: {
          id: {
            _eq: id,
          },
        },
      },
      onCompleted: result => {
        setStudentId(result?.credit_note_logs?.[0]?.valueAfter?.studentId);
        form.setFieldsValue({
          creditNote: {
            studentId,
          },
          reason: result?.credit_note_logs?.[0]?.valueAfter?.reason,
          remarks: result?.credit_note_logs?.[0]?.valueAfter?.remarks,
          creditNoteFee: result?.credit_note_logs?.[0]?.valueAfter
            ?.creditNoteFee
            ? {
                receipt:
                  result?.credit_note_logs?.[0]?.valueAfter?.creditNoteFee
                    .receiptId,
                overpaidAmount:
                  result?.credit_note_logs?.[0]?.valueAfter?.creditNoteFee
                    .overpaidAmount,
              }
            : null,
          classInfo: {
            class:
              result?.credit_note_logs?.[0]?.valueAfter?.creditNoteClass
                ?.classId,
            endDate: result?.credit_note_logs?.[0]?.valueAfter?.creditNoteClass
              ?.endDate
              ? moment(
                  result?.credit_note_logs?.[0]?.valueAfter?.creditNoteClass
                    ?.endDate,
                ).format('YYYY-MM-DD')
              : undefined,
            startDate: result?.credit_note_logs?.[0]?.valueAfter
              ?.creditNoteClass?.startDate
              ? moment(
                  result?.credit_note_logs?.[0]?.valueAfter?.creditNoteClass
                    ?.startDate,
                ).format('YYYY-MM-DD')
              : undefined,
            noOfLesson: result?.credit_note_logs?.[0]?.valueAfter
              .creditNoteClass?.lessonIds
              ? result?.credit_note_logs?.[0]?.valueAfter?.creditNoteClass
                  ?.lessonIds.length
              : 0,
            termId:
              result?.credit_note_logs?.[0]?.valueAfter?.creditNoteClass
                ?.termId,
            termName:
              result?.credit_note_logs?.[0]?.valueAfter?.creditNoteClass
                ?.termName,
            lessonIds:
              result?.credit_note_logs?.[0]?.valueAfter?.creditNoteClass
                ?.lessonIds,
          },
          fee: {
            noOfLesson: result?.credit_note_logs?.[0]?.valueAfter
              ?.creditNoteClass?.lessonIds
              ? result?.credit_note_logs?.[0]?.valueAfter?.creditNoteClass
                  ?.lessonIds.length
              : 0,
          },
        });
      },
    });

  const _getCreditNoteDetail = (): Credit_Notes => {
    return data?.credit_note_logs?.[0]?.valueAfter as Credit_Notes;
  };

  const _renderTag = (str: string, color: string) => {
    return (
      <span
        className="indigo-pill"
        style={{
          backgroundColor: color,
          textTransform: 'capitalize',
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        {str ? str.replace(/_/g, ' ') : ''}
      </span>
    );
  };

  const _renderPill = (value: any) => {
    let color = 'transparent';
    switch (value) {
      case CreditNoteStatus.AwaitingFrontDeskAction:
        color = '#ad99f0';
        break;
      case CreditNoteStatus.AwaitingFinanceAction:
        color = '#fa73c4';
        break;
      case CreditNoteStatus.AwaitingFinanceApproval:
        color = '#5064ff';
        break;
      case CreditNoteStatus.Issued:
        color = '#538dff';
        break;
      case CreditNoteStatus.Rejected:
        color = '#f54f64';
        break;
      case CreditNoteStatus.Voided:
        color = '#828894';
        break;
      case CreditNoteStatus.Used:
        color = '#ffa629';
        break;

      default:
        break;
    }
    return <>{_renderTag(value, color)}</>;
  };

  const _renderAction = (record: Credit_Note_Logs) => {
    let Content: any = '';
    switch (record?.type) {
      case HistoryLogType.Update:
        Content = 'Update Content';
        break;
      case HistoryLogType.Create:
        Content = `Create New CN {${record?.creditNote?.name}}`;
        break;
      case HistoryLogType.ChangeStatus:
        Content = () => (
          <div style={{ display: 'flex' }}>
            Change Status from{' '}
            {_renderPill(record?.valueBefore?.creditNoteStatus)} to{' '}
            {_renderPill(record?.valueAfter?.creditNoteStatus)}
          </div>
        );
        break;
      case HistoryLogType.Duplicate:
        Content = ` Duplicate from CN {${record?.creditNote?.name}}`;
        break;
    }
    return (
      <Fragment>
        <div>{typeof Content === 'string' ? Content : <Content />}</div>
      </Fragment>
    );
  };

  useEffect(() => {
    getCreditNoteDetail();
    // eslint-disable-next-line
  }, [id]);

  return (
    <div
      style={{
        maxWidth: '100%',
        margin: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: '100vh',
        display: 'flex',
        position: 'fixed',
        background: '#fff',
        zIndex: 9999,
        overflow: 'auto',
        paddingTop: 30,
        paddingBottom: 30,
      }}
    >
      <Form
        style={{ marginLeft: 'auto', marginRight: 'auto', width: 1110 }}
        form={form}
        layout="vertical"
      >
        <Spin spinning={loading}>
          <PALogMetadata
            detail={data?.credit_note_logs?.[0] as Credit_Note_Logs}
            renderAction={_renderAction(
              data?.credit_note_logs?.[0] as Credit_Note_Logs,
            )}
            title="Credit Note History Log"
          />
          <Divider />

          <CreditNoteInfo
            creditNoteData={_getCreditNoteDetail()}
            isLogDetail={true}
          />
          <CreditNoteStudentFormItem
            disabled={true}
            formName="creditNote"
            creditNoteData={_getCreditNoteDetail()}
          />
          {data?.credit_note_logs?.[0]?.valueAfter?.type ===
          CreditNoteType.Overpaid ? null : (
            <CreditNoteClassFormItem
              formInstance={form}
              formName="classInfo"
              studentId={studentId}
              classId={
                data?.credit_note_logs?.[0]?.valueAfter?.creditNoteClass
                  ?.classId
              }
              termId={
                data?.credit_note_logs?.[0]?.valueAfter?.creditNoteClass?.termId
              }
              disabled={true}
              creditNoteData={
                data?.credit_note_logs?.[0]?.valueAfter as Credit_Notes
              }
            />
          )}
          <CreditNoteReasonFormItem
            creditNoteType={data?.credit_note_logs?.[0].type as CreditNoteType}
            disabled={true}
            formName={'reason'}
          />
          {data?.credit_note_logs?.[0]?.valueAfter?.type ===
            CreditNoteType.Overpaid &&
          _getCreditNoteDetail()?.creditNoteFee?.overpaidAmount !== 0 ? (
            <CreditNoteProRatedFeeFormItem
              defaultNumberLesson={
                _getCreditNoteDetail()?.creditNoteFee
                  ? _getCreditNoteDetail()?.creditNoteFee?.numberOfLesson
                  : _getCreditNoteDetail()?.creditNoteClass?.lessonIds.length
              }
              studentId={studentId}
              disabled={true}
              formInstance={form}
              formName="creditNoteFee"
              creditNoteData={_getCreditNoteDetail()}
              pricing={
                _getCreditNoteDetail()
                  ? _getCreditNoteDetail().creditNoteFee?.overpaidAmount
                  : 0
              }
            />
          ) : null}
          <CreditNoteRemarksFormItem formName="remarks" />
        </Spin>
      </Form>
    </div>
  );
};

export default CNLogsDetail;
