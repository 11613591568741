export enum CreditNoteStatus {
  Delete = 'delete',
  Used = 'used',
  Voided = 'voided',
  Rejected = 'rejected',
  Issued = 'issued',
  AwaitingFrontDeskAction = 'awaiting_front_desk_action',
  AwaitingFinanceAction = 'awaiting_finance_action',
  AwaitingFinanceApproval = 'awaiting_finance_approval',
}
export const CreditNoteStatusLabel: any = {
  awaiting_front_desk_action: 'Awaiting Front Desk Action',
  awaiting_finance_action: 'Awaiting Finance Action',
  awaiting_finance_approval: 'Awaiting Finance Approval',
  voided: 'Voided',
  rejected: 'Rejected',
  used: 'Used',
  issued: 'Issued',
};
export enum CreditNoteType {
  Suspension = 'suspension',
  Overpaid = 'overpaid',
}
