import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  NormalizedCacheObject,
  gql,
  split,
} from '@apollo/client';
import { Auth } from '../../auth';
import { WebSocketLink } from '@apollo/client/link/ws';
import store from './store';
import { getMainDefinition } from '@apollo/client/utilities';
import { message } from 'antd';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
const bcrypt = require('bcryptjs');

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
  }
`;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message: description, locations, path }) => {
      message.error({
        content: `${description}.`,
        duration: 3, //3s
      });
      return console.error(
        `[GraphQL error]: Message: ${description}, Location: ${locations}, Path: ${path}`,
      );
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext(async (_, { headers }) => {
  const authorization = await Auth.getAccessToken();
  let hash;
  if (!authorization) {
    if (
      window.location.pathname.includes('register') ||
      window.location.pathname.includes('forgot-password')
    ) {
      var salt = bcrypt.genSaltSync(10);
      hash = await bcrypt.hashSync('INDIGO-CRM@2020', salt);
    }
  }

  return {
    headers: {
      ...headers,
      'x-hasura-admin-secret': process.env.REACT_APP_GRAPHQL_SECRET,
      authorization: authorization || hash,
      portal: authorization ? '' : 'student_portal',
    },
  };
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_GRAPHQL_URL?.replace('http', 'ws') as string,
  options: {
    reconnect: true,
    connectionParams: async () => {
      const authorization = await Auth.getAccessToken();
      return {
        headers: {
          authorization,
        },
      };
    },
  },
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const link = ApolloLink.from([authLink, errorLink, httpLink]);

const splitLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  link,
);

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: splitLink,
  cache: store,
  typeDefs,
  resolvers: {},
  connectToDevTools: true,
});
