import { Lessons, Lessons_Students_Aggregate } from '../services/data-access';
import { ProgramCategory } from './program';

export enum LessonStatus {
  ACTIVE = 'active',
  CANCEL = 'cancel',
}

export enum PaymentStatus {
  PAID = 'paid',
  UNPAID = 'unpaid',
  PENDING = 'pending',
}

export const enum WaitingStatus {
  TO_BE_ENROLL = 'to_be_enroll',
  TO_BE_TRANSFER = 'to_be_transfer',
}

export enum LessonType {
  Weekly = 'weekly',
  Monthly = 'monthly',
  Custom = 'custom',
}
export enum ClassStatus {
  New = 'new',
  Open = 'open',
  Closed = 'closed',
}
export const lessonTypeOptions: Array<{ label: string; key: string }> = [
  {
    key: LessonType.Weekly,
    label: 'Weekly',
  },
  {
    key: LessonType.Monthly,
    label: 'Monthly',
  },
  {
    key: LessonType.Custom,
    label: 'Custom',
  },
];
export const sessionFormDefault = {
  endTime: '',
  startTime: '',
  tutorIds: '',
};
export const lessonFormDefault = {
  sessions: [sessionFormDefault],
  classroomId: undefined,
  date: '',
};
export type ClassFormType = {
  companyId: string | undefined;
  programId: string | undefined;
  lessonType: LessonType | undefined;
  totalLoop?: number;
  remark: string;
  waitingTime: string;
  lessons: any[];
  programTypeId?: string;
  programCategory: ProgramCategory; // need to remove when request create
  classStatus: ClassStatus; // need to remove when request create
  enrollStudentIds?: string[];
  academicCalendarId?: string;
  booterClassIds: string[];
  [key: string]: any;
};
export const classFormDefault: ClassFormType = {
  companyId: undefined,
  programId: undefined,
  lessonType: undefined,
  totalLoop: 1,
  remark: '',
  classSize: 20,
  waitingTime: '',
  lessons: [lessonFormDefault],
  programTypeId: undefined,
  programCategory: ProgramCategory.Regular,
  classStatus: ClassStatus.New,
  academicCalendarId: undefined,
  year: '',
  booterClassIds: [],
};

export enum StudentLessonStatus {
  NotAttended = 'not_attended',
  Attended = 'attended',
  Withdrawn = 'withdrawn',
  Suspended = 'suspended',
}

export const StudentLessonStatusLabel: any = {
  not_attended: 'Not attended',
  attended: 'Attended',
  withdrawn: 'Withdrawn',
  suspended: 'Suspended',
};
export interface ICustomLessons extends Lessons {
  replacement_aggregate: Lessons_Students_Aggregate;
  replacementLesson: any;
}

export enum TransferClassStatus {
  Pending = 'pending',
  Completed = 'completed',
  Rejected = 'rejected',
  Cancelled = 'cancelled',
}

export enum TagType {
  BL1 = 'BL1',
  BL2 = 'BL2',
  BL3 = 'BL3',
  BL4 = 'BL4',
}

export enum ActionClassType {
  ENROLL_LAST_PARTIAL = 'enroll_last_partial',
  TRANSFER = 'transfer',
  REPLACE = 'replace',
}
