import { InMemoryCache, ReactiveVar, makeVar } from '@apollo/client';

const store: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        auth: {
          read() {
            return authVar();
          },
        },
        transactionId: {
          read() {
            return transactionIdVar();
          },
        },
      },
    },
  },
});

export const authVar: ReactiveVar<any> = makeVar<any>({ isLoggedIn: false });
export const transactionIdVar: ReactiveVar<string> = makeVar<string>('');

export default store;
