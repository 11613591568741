import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Select, Table } from 'antd';
import {
  ColumnsType,
  Key,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CreditNoteStatus, HistoryLogType } from '@models';
import { parseUTCToTime } from '@helpers';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@interfaces/constants';
import {
  Credit_Note_Logs,
  useGetCreditNoteLogsLazyQuery,
} from '@services/data-access';
import IndigoPagination from '@components/Pagination/IndigoPagination';
import TableSummary from '@components/TableSummary/TableSummary';

const { Option } = Select;
export interface HistoryLogsTableProps {
  id?: string;
}

export const HistoryCreditNoteLogsTable = ({ id }: HistoryLogsTableProps) => {
  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, Key[] | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<any>,
  ) => {
    console.log(pagination, filters, sorter, extra);
  };
  const [filterHistory, setFilterHistory] = useState({
    currentPage: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [
    getCreditNoteLog,
    { data: creditNoteLogs, loading, fetchMore, called },
  ] = useGetCreditNoteLogsLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        creditNoteId: {
          _eq: id,
        },
      },
      offset: (filterHistory.currentPage - 1) * filterHistory.pageSize,
      limit: filterHistory.pageSize,
    },
  });

  useEffect(() => {
    if (id) {
      if (called) {
        fetchMore({
          variables: {
            offset: (filterHistory.currentPage - 1) * filterHistory.pageSize,
            limit: filterHistory.pageSize,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          },
        });
      } else {
        getCreditNoteLog({
          variables: {
            where: {
              creditNoteId: {
                _eq: id,
              },
            },
            offset: (filterHistory.currentPage - 1) * filterHistory.pageSize,
            limit: filterHistory.pageSize,
          },
        });
      }
    }
  }, [called, fetchMore, filterHistory, getCreditNoteLog, id]);

  const _handlePageChange = (value: number) => {
    setFilterHistory({
      pageSize: value,
      currentPage: DEFAULT_PAGE,
    });
  };

  const _handleChangeItemsPage = (value: number) => {
    setFilterHistory({
      ...filterHistory,
      currentPage: DEFAULT_PAGE,
      pageSize: value,
    });
  };

  const _renderTag = (str: string, color: string) => {
    return (
      <span
        className="indigo-pill"
        style={{
          backgroundColor: color,
          textTransform: 'capitalize',
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        {str ? str.replace(/_/g, ' ') : ''}
      </span>
    );
  };

  const _renderPill = (value: any) => {
    let color = 'transparent';
    switch (value) {
      case CreditNoteStatus.AwaitingFrontDeskAction:
        color = '#ad99f0';
        break;
      case CreditNoteStatus.AwaitingFinanceAction:
        color = '#fa73c4';
        break;
      case CreditNoteStatus.AwaitingFinanceApproval:
        color = '#5064ff';
        break;
      case CreditNoteStatus.Issued:
        color = '#538dff';
        break;
      case CreditNoteStatus.Rejected:
        color = '#f54f64';
        break;
      case CreditNoteStatus.Voided:
        color = '#828894';
        break;
      case CreditNoteStatus.Used:
        color = '#ffa629';
        break;

      default:
        break;
    }
    return <>{_renderTag(value, color)}</>;
  };

  const columns: ColumnsType<Credit_Note_Logs> = [
    {
      title: 'Index',
      render: (text: string, record: Credit_Note_Logs, index: number) => (
        <span>
          {index + 1 + filterHistory.pageSize * (filterHistory.currentPage - 1)}
        </span>
      ),
    },
    {
      title: 'User',
      render: (_text: string, record: any) => (
        <Fragment>
          <div>{record?.creator?.fullName}</div>
          <div style={{ color: '#828894' }}>
            {record?.creator?.roles.map((rl: any) => rl?.name).join(', ') ||
              '--'}
          </div>
        </Fragment>
      ),
    },
    {
      title: 'Timestamp',
      render: (text: string, record: Credit_Note_Logs) => (
        <Fragment>
          <div>{parseUTCToTime(record?.updatedAt, 'hh:mm A')}</div>
          <div>{parseUTCToTime(record?.updatedAt, 'DD/MM/YYYY')}</div>
        </Fragment>
      ),
    },
    {
      title: 'Action',
      render: (text: string, record: Credit_Note_Logs) => {
        let Content: any = '';
        switch (record?.type) {
          case HistoryLogType.Update:
            Content = 'Update Content';
            break;
          case HistoryLogType.Create:
            Content = `Create New CN {${record?.creditNote?.name}}`;
            break;
          case HistoryLogType.ChangeStatus:
            Content = () => (
              <div style={{ display: 'flex' }}>
                Change Status from{' '}
                {_renderPill(record?.valueBefore?.creditNoteStatus)} to{' '}
                {_renderPill(record?.valueAfter?.creditNoteStatus)}
              </div>
            );
            break;
          case HistoryLogType.Duplicate:
            Content = ` Duplicate from PA `;
            break;
        }
        return (
          <Fragment>
            <div>{typeof Content === 'string' ? Content : <Content />}</div>
          </Fragment>
        );
      },
    },
    {
      title: '',
      render: (text: string, record: Credit_Note_Logs) => (
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`/finance/credit-note/logs/${record.id}`}
        >
          <FontAwesomeIcon icon={['fal', 'eye']} size="lg" />
        </a>
      ),
    },
  ];

  return (
    <Fragment>
      <Row style={{ marginBottom: 20 }} justify="space-between" align="middle">
        <Col>
          <TableSummary
            currentNumberOfRecords={
              creditNoteLogs?.credit_note_logs?.length || 0
            }
            currentPage={filterHistory.currentPage}
            pageSize={filterHistory.pageSize}
            total={
              creditNoteLogs?.credit_note_logs_aggregate?.aggregate?.count
                ? creditNoteLogs?.credit_note_logs_aggregate?.aggregate?.count
                : 0
            }
          />
        </Col>
        <Col>
          <Select
            value={filterHistory.pageSize}
            style={{ width: 170 }}
            onChange={_handleChangeItemsPage}
          >
            <Option value={10}>10 items</Option>
            <Option value={20}>20 items</Option>
            <Option value={30}>30 items</Option>
          </Select>
        </Col>
      </Row>
      <div className="table-container table-clickable">
        <Table
          rowClassName="table-row-light"
          size="middle"
          columns={columns}
          rowKey={record => record.id || 0}
          dataSource={
            creditNoteLogs?.credit_note_logs
              ? (creditNoteLogs?.credit_note_logs as Credit_Note_Logs[])
              : []
          }
          pagination={false}
          loading={loading}
          onChange={handleTableChange}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {},
            };
          }}
        />
      </div>
      <IndigoPagination
        currentPage={filterHistory.currentPage}
        total={
          creditNoteLogs?.credit_note_logs_aggregate?.aggregate?.count
            ? creditNoteLogs?.credit_note_logs_aggregate?.aggregate?.count
            : 0
        }
        pageSize={filterHistory.pageSize}
        onPageChange={_handlePageChange}
        disabled={loading}
      ></IndigoPagination>
    </Fragment>
  );
};

export default HistoryCreditNoteLogsTable;
