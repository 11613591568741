import React, { FunctionComponent } from 'react';
import Table, { TableProps } from 'antd/lib/table';

interface Props<T> extends TableProps<T> {
  pageSize?: number;
  currentPage?: number;
  indexName?: string;
  onChange?: any;
}
export const CustomTable: FunctionComponent<Props<any>> = ({
  columns,
  pageSize,
  currentPage,
  ...rest
}) => {
  const currentColumns = [
    {
      title: rest.indexName || 'Index',
      render: (__: string, _: any, index: number) => (
        <span>
          {pageSize && currentPage
            ? index + 1 + pageSize * (currentPage - 1)
            : index + 1}
        </span>
      ),
    },
    ...(columns || []),
  ];
  return (
    <Table rowClassName="table-row-light" columns={currentColumns} {...rest} />
  );
};
