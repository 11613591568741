import './session-header.scss';

import { Col, Row } from 'antd';
import React, { FunctionComponent } from 'react';

export interface SessionHeaderProps {
  title: string;
  subTitle?: string | React.ReactNode;
}

const SessionHeader: FunctionComponent<SessionHeaderProps> = ({
  title,
  subTitle,
  children,
}) => {
  return (
    <div>
      <Row justify="space-between" align="middle" gutter={[20, 20]}>
        <Col>
          <span className="session-header"> {title}</span>
        </Col>
        <Col>
          {subTitle ? (
            <span className="session-header">{subTitle}</span>
          ) : (
            children
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SessionHeader;
