import { Button, Col, Modal, Row } from 'antd';
import React, { FunctionComponent, useState } from 'react';

import { CreditNoteFormItemProps } from './CreditNoteFormItemProps';
import { CreditNoteStatusLabel } from '../../../../../models/credit-note';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HistoryCreditNoteLogsTable from '../../../ModalHistoryLogsTable/CreditNoteLogs';

const CreditNoteInfo: FunctionComponent<CreditNoteFormItemProps> = ({
  creditNoteData,
  isLogDetail,
}) => {
  const [showHistory, setShowHistory] = useState(false);

  return (
    <div className="credit-note__general-info">
      <p className="text--large">Credit Note</p>
      <Row style={{ marginTop: 20 }}>
        <Col span={4}>
          <p className="text--label">Credit Note Ref.</p>
          <p className="text--bold">{creditNoteData?.name}</p>
        </Col>
        <Col span={4}>
          <p className="text--label">Credit Type</p>
          <p className="text--bold text--capitalize">{creditNoteData?.type}</p>
        </Col>
        <Col span={6}>
          <p className="text--label">Status</p>
          <p
            className={`credit-note__status ${creditNoteData?.creditNoteStatus}`}
          >
            {creditNoteData?.creditNoteStatus
              ? CreditNoteStatusLabel[creditNoteData?.creditNoteStatus]
              : ''}
          </p>
        </Col>
        {!isLogDetail && (
          <Col span={4}>
            <span style={{ visibility: 'hidden' }}>log history</span>
            <Button
              type="link"
              style={{
                padding: 0,
                height: 24,
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => {
                setShowHistory(true);
              }}
            >
              <FontAwesomeIcon
                style={{ width: 20, height: 20, marginRight: 12 }}
                icon={['fal', 'history']}
              />
              Log History
            </Button>
          </Col>
        )}
      </Row>
      <Modal
        visible={showHistory}
        onCancel={() => {
          setShowHistory(false);
        }}
        footer={null}
        title="Log History"
        width={1100}
      >
        <HistoryCreditNoteLogsTable
          id={creditNoteData?.id ? creditNoteData?.id : ''}
        />
      </Modal>
    </div>
  );
};

export default CreditNoteInfo;
