import React from 'react';

import { Roles } from '@models';

const StudentList = React.lazy(
  () => import(/* webpackChunkName: "student-list" */ './StudentList'),
);

const CreateStudent = React.lazy(
  () => import(/* webpackChunkName: "student-create" */ './CreateStudent'),
);

const UpdateStudent = React.lazy(
  () => import(/* webpackChunkName: "student-update" */ './UpdateStudent'),
);

const StudentDetail = React.lazy(
  () => import(/* webpackChunkName: "student-detail" */ './StudentDetail'),
);

const Suspension = React.lazy(
  () => import(/* webpackChunkName: "suspension" */ './StudentSuspension'),
);

const CreateSuspension = React.lazy(
  () =>
    import(
      /* webpackChunkName: "suspension-create" */ './StudentSuspension/CreateSuspension'
    ),
);

const CreditNoteDetail = React.lazy(
  () =>
    import(
      /* webpackChunkName: "credit-note-detail" */ '../finance/paymentPaper/PaymentCreditNoteWrapper'
    ),
);

const Withdrawal = React.lazy(
  () =>
    import(/* webpackChunkName: "withdrawal" */ './StudentWithdrawal/index'),
);

const WithdrawForm = React.lazy(
  () =>
    import(
      /* webpackChunkName: "withdrawal-form" */ './StudentWithdrawal/withdrawal-form/WithdrawForm'
    ),
);

export default [
  {
    path: '/student-management',
    component: StudentList,
    exact: true,
    title: 'Student List',
    isPublic: true,
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
    ],
  },
  {
    path: '/student-management/create-account',
    component: CreateStudent,
    exact: true,
    title: 'Create Student Account',
    isPublic: true,
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
    ],
  },
  {
    path: '/student-management/update-account-information/:id',
    component: CreateStudent,
    exact: true,
    title: 'Create Student Account',
    isPublic: true,
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
    ],
  },
  {
    path: '/student-management/student/:id/view',
    component: StudentDetail,
    exact: true,
    title: 'Student Detail',
    isPublic: true,
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
    ],
  },
  {
    path: '/student-management/edit-account/:id',
    component: UpdateStudent,
    exact: true,
    title: 'Edit Student Account',
    isPublic: true,
    middleCrumbs: { title: 'Student Detail' },
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
    ],
  },
  {
    path: '/suspension',
    component: Suspension,
    exact: true,
    title: 'Suspension',
    isPublic: true,
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
    ],
  },
  {
    path: '/suspension/:id',
    component: CreateSuspension,
    exact: true,
    title: 'Create suspension',
    isPublic: true,
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
    ],
  },
  {
    path: '/suspension/:id',
    component: CreateSuspension,
    exact: true,
    title: 'Create suspension',
    isPublic: true,
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
    ],
  },
  {
    path: '/suspension/:id/:studentInfoId',
    component: CreateSuspension,
    exact: true,
    title: 'Create suspension',
    isPublic: true,
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
    ],
  },
  {
    path: '/suspension/detail/:id/view',
    component: CreditNoteDetail,
    exact: true,
    title: 'Suspension detail',
    isPublic: true,
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
    ],
  },
  {
    path: '/withdrawal',
    component: Withdrawal,
    exact: true,
    title: 'Withdrawal',
    isPublic: true,
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
    ],
  },
  {
    path: '/withdrawal/:withdrawalId',
    component: WithdrawForm,
    exact: true,
    title: 'Create Withdrawal',
    isPublic: true,
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
    ],
  },
  {
    path: '/withdrawal/:withdrawalId/:studentInfoId',
    component: WithdrawForm,
    exact: true,
    title: 'Create Withdrawal',
    isPublic: true,
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
    ],
  },
  {
    path: '/withdrawal/view-withdrawal/:withdrawalId/detail',
    component: WithdrawForm,
    exact: true,
    title: 'View Withdrawal',
    isPublic: true,
    roles: [
      Roles.FrontDesk,
      Roles.Intern,
      Roles.OpsManager,
      Roles.HR,
      Roles.FinanceStaff,
      Roles.FinanceManager,
    ],
  },
];
