export enum StudentFeedbackRating {
  Great = 'great',
  Average = 'average',
  Good = 'good',
  NotGood = 'not_good',
}

export const StudentFeedbackRatingLabel = {
  great: 'Great',
  average: 'Average',
  good: 'Good',
  not_good: 'Not Good',
};

export enum RefundPaymentMethod {
  PayNow = 'pay_now',
  Cheque = 'cheque',
  InterbankTransfer = 'interbank_transfer',
  NoRefundFee = 'no_refund_fee',
}

export const RefundPaymentMethodLabel = {
  pay_now: 'Pay Now',
  cheque: 'Cheque',
  interbank_transfer: 'Interbank Transfer',
  no_refund_fee: 'No Refund Fee',
};

export enum PaymentMethods {
  Cheque = 'cheque',
  Cash = 'cash',
  CreditCard = 'credit_card',
  NETS = 'nets',
  InterbankTransfer = 'interbank_transfer',
  Sponsorship = 'sponsorship',
  CreditNote = 'credit_note',
  QRCode = 'qr_code',
  AXS = 'axs',
  AbsorbedByCompanyAsExpense = 'absorbed_by_company_as_expense',
}

export enum CardTypes {
  Normal = 'normal',
  Visa = 'visa',
  Master = 'master',
  Amex = 'amex',
  UnionPay = 'union_pay',
}
