import React from 'react';
import { Roles } from '@models';

const ClassList = React.lazy(
  () => import(/* webpackChunkName: "class-list" */ './list/ClassList'),
);

const ClassCreate = React.lazy(
  () => import(/* webpackChunkName: "class-create" */ './view/ClassCreate'),
);
const ClassDetail = React.lazy(
  () => import(/* webpackChunkName: "class-detail" */ './detail/ClassDetail'),
);
const CreateLesson = React.lazy(
  () =>
    import(
      /* webpackChunkName: "class-create-lesson" */ './create-lesson/CreateLesson'
    ),
);

export default [
  {
    path: '/class/management',
    component: ClassList,
    exact: true,
    title: 'Class List',
    isPublic: true,
    roles: [Roles.FrontDesk, Roles.Intern, Roles.OpsManager, Roles.HR],
  },
  {
    path: '/class/management/:programId/create',
    component: ClassCreate,
    exact: true,
    title: 'Create Class',
    isPublic: true,
    roles: [Roles.FrontDesk, Roles.Intern, Roles.OpsManager, Roles.HR],
  },
  {
    path: '/class/management/create',
    component: ClassCreate,
    exact: true,
    title: 'Create Class',
    isPublic: true,
    roles: [Roles.FrontDesk, Roles.Intern, Roles.OpsManager, Roles.HR],
  },
  {
    path: '/class/management/:classId/view',
    component: ClassDetail,
    exact: true,
    title: 'Class Details',
    isPublic: true,
    roles: [Roles.FrontDesk, Roles.Intern, Roles.OpsManager, Roles.HR],
  },

  {
    path: '/class/management/:classId/view/lesson-create',
    component: CreateLesson,
    exact: true,
    title: 'Add Lesson',
    isPublic: true,
    roles: [Roles.FrontDesk, Roles.Intern, Roles.OpsManager, Roles.HR],
  },
  // {
  //   path: '/class-management/:classId/edit',
  //   component: ClassManagementCreate,
  //   exact: true,
  //   title: 'Edit Class',
  //   isPublic: true,
  //   roles: [Roles.FrontDesk, Roles.Intern, Roles.OpsManager, Roles.HR],
  // },
];
