import { Button, Col, DatePicker, Form, Row, Select, Spin } from 'antd';
import {
  Program_Types,
  useProgramTypesLazyQuery,
  Levels,
  useGetCurriculumsLevelsQuery,
  useGetCurriculumsQuery,
  useGetBranchesQuery,
  useSubjectsListQuery,
} from '@services/data-access';
import React, { Fragment, FunctionComponent, useState, useEffect } from 'react';
import { ProgramCategory } from '@models/program';
import { StatusCode } from '@models';
import { reducerDataLevels } from '@helpers/utils';
import { sortByKey } from '@helpers';
import moment from 'moment';

export interface FilterValue {
  curriculum: string;
  level: string;
  subject: string;
  programmeCategories: string;
  programmeType: string;
  branch: string;
  year?: string;
}
export interface SelectClassPopUpFilterProps {
  onFilter: (value: FilterValue) => void;
  companyId: string;
}

const SelectClassPopUpFilter: FunctionComponent<
  SelectClassPopUpFilterProps
> = ({ onFilter, companyId }) => {
  const [submitted, setSubmitted] = useState(true);
  const [allLevels, setAllLevels] = useState<{ [id: string]: Levels[] }>({});
  const [filterLevels, setFilterLevels] = useState<Levels[]>([]);
  const [filterProgramType, setFilterProgramType] = useState<string>('');
  const [form] = Form.useForm();

  const { data: getCurriculums, loading: loadingCuriculums } =
    useGetCurriculumsQuery({
      skip: !companyId,
      variables: { filters: { companyId: { _eq: companyId } } },
      onError: error => {},
      onCompleted: () => {
        form.setFieldsValue({ curriculum: 'root' });
      },
    });

  const { loading: loadingCurriculumsLevels } = useGetCurriculumsLevelsQuery({
    skip: !companyId,
    variables: { filters: { curriculum: { companyId: { _eq: companyId } } } },
    onCompleted: data => {
      const dataTemp = reducerDataLevels(data);
      setAllLevels(dataTemp);
      setFilterLevels(dataTemp['root']);
      form.setFieldsValue({ level: 'root', programmeCategories: 'root' });
    },
    onError: error => {},
  });

  const { data: subjectList, loading: loadingSubject } = useSubjectsListQuery({
    skip: !companyId,
    variables: { filter: { programs: { companyId: { _eq: companyId } } } },
    onError: error => {},
    onCompleted: () => {
      form.setFieldsValue({ subject: 'root' });
    },
  });

  const { data: branchList, loading: loadingBranch } = useGetBranchesQuery({
    variables: {
      filter: {
        status: {
          _neq: StatusCode.Deleted,
        },
      },
    },
    onError: error => {},
    onCompleted: () => {
      form.setFieldsValue({ branch: 'root' });
    },
  });

  const [
    getProgramTypes,
    {
      data: allProgramTypes,
      loading: loadingRegularProgramType,
      called: getProgramTypesCalled,
    },
  ] = useProgramTypesLazyQuery({
    onError: () => {},
  });

  useEffect(() => {
    if (!!companyId) {
      getProgramTypes({
        variables: {
          filter: {
            cate: {
              code:
                filterProgramType && filterProgramType !== 'root'
                  ? { _eq: filterProgramType }
                  : {
                      _in: [
                        ProgramCategory.Regular,
                        ProgramCategory.Special,
                        ProgramCategory.Others,
                      ],
                    },
            },
          },
        },
      });
      form.setFieldsValue({
        programmeType: 'root',
      });
    }
    // eslint-disable-next-line
  }, [filterProgramType, companyId]);

  const onApply = (value: FilterValue) => {
    value.year = moment(value.year).format('YYYY');
    onFilter(value);
    setSubmitted(true);
  };

  const onCurriculumnChanged = (value: string) => {
    if (value && value !== '-1' && allLevels[value]) {
      setFilterLevels(allLevels[value]);
    } else {
      setFilterLevels(allLevels['root']);
    }

    form.setFieldsValue({ level: undefined });
  };

  const onProgramCategoryChanged = (value: string) => {
    if (value && value !== '-1') {
      setFilterProgramType(value);
    }
    form.setFieldsValue({ programmeType: undefined });
  };

  const validateMessages = {
    required: 'This field is required.',
  };

  const currentYear = new Date().getFullYear();

  return (
    <Spin
      spinning={
        loadingCuriculums ||
        loadingCurriculumsLevels ||
        loadingSubject ||
        loadingBranch ||
        loadingRegularProgramType
      }
    >
      <Form
        form={form}
        onFinish={value => onApply(value as FilterValue)}
        layout="vertical"
        className="indigo-filter-header"
        validateMessages={validateMessages}
      >
        <Row gutter={[20, 20]} align="top">
          <Col lg={3}>
            <Form.Item name="year" label="Academic Year">
              <DatePicker
                picker="year"
                placeholder="Select a year"
                defaultValue={moment(currentYear, 'YYYY')}
                onChange={e => setSubmitted(false)}
              />
            </Form.Item>
          </Col>
          <Col lg={3}>
            <Form.Item
              label="Curriculum"
              name="curriculum"
              rules={[{ required: true }]}
            >
              <Select
                onChange={e => {
                  setSubmitted(false);
                  onCurriculumnChanged(e.toString());
                }}
                placeholder="Select"
              >
                <Select.Option value="root">All Curriculums</Select.Option>
                {getCurriculums?.curriculums.map(item => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.abbreviation}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={3}>
            <Form.Item label="Level" name="level" rules={[{ required: true }]}>
              <Select onChange={e => setSubmitted(false)} placeholder="Select">
                <Select.Option value="root">All Levels</Select.Option>
                {sortByKey(filterLevels, 'name')?.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.abbreviation}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={3}>
            <Form.Item
              label="Subject"
              name="subject"
              rules={[{ required: true }]}
            >
              <Select onChange={e => setSubmitted(false)} placeholder="Select">
                <Select.Option value="root">All Subjects</Select.Option>

                {subjectList?.subjects.map(item => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={5}>
            <Form.Item
              label="Programme Categories"
              name="programmeCategories"
              rules={[{ required: true }]}
            >
              <Select
                onChange={e => {
                  setSubmitted(false);
                  onProgramCategoryChanged(e.toString());
                }}
                placeholder="Select"
              >
                <Select.Option value="root">
                  All Programme Categories
                </Select.Option>
                <Select.Option value={ProgramCategory.Regular}>
                  Regular
                </Select.Option>
                <Select.Option value={ProgramCategory.Special}>
                  Special
                </Select.Option>
                <Select.Option value={ProgramCategory.Others}>
                  Others
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={4}>
            <Form.Item
              label="Programme Type"
              name="programmeType"
              rules={[{ required: true }]}
            >
              <Select
                onChange={e => {
                  setSubmitted(false);
                }}
                placeholder="Select"
              >
                <Select.Option value="root">All Programme Types</Select.Option>
                {allProgramTypes?.program_types.map(item => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.shortName} - {item.fullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={3}>
            <Form.Item
              label="Branch"
              name="branch"
              rules={[{ required: true }]}
            >
              <Select
                onChange={e => {
                  setSubmitted(false);
                }}
                placeholder="Select"
              >
                <Select.Option value="root">
                  All Programme Branches
                </Select.Option>

                {branchList?.branches.map(item => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={3}>
            <Button
              style={{ marginTop: 26 }}
              disabled={submitted}
              htmlType="submit"
              className="applied-btn"
            >
              {submitted ? 'Applied' : 'Apply'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default SelectClassPopUpFilter;
