import './indigo-pagination.scss';

import { Pagination, Row } from 'antd';
import React, { FunctionComponent } from 'react';

export interface IndigoPaginationProps {
  currentPage: number;
  total: number;
  pageSize: number;
  disabled?: boolean;
  onPageChange: (page: number) => void;
  position?: 'end' | 'start' | 'center';
}

const IndigoPagination: FunctionComponent<IndigoPaginationProps> = ({
  currentPage,
  total,
  pageSize,
  disabled,
  onPageChange,
  position,
}) => {
  return (
    <Row
      style={{ marginTop: 20 }}
      justify={position ? position : 'start'}
      align="middle"
    >
      <Pagination
        className="user-pagination"
        current={currentPage}
        total={total}
        pageSize={pageSize}
        onChange={onPageChange}
        disabled={disabled}
        showQuickJumper
        showSizeChanger={false}
      />
    </Row>
  );
};

export default IndigoPagination;
