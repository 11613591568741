import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '@ant-design/icons/lib/components/Icon';
import React from 'react';

export interface MenuItem {
  key: string;
  label?: string;
  href?: string;
  subMenu?: MenuItem[];
  icon?: React.ReactNode;
}

export const adminMenu: MenuItem[] = [
  {
    key: 'companies',
    label: 'Companies',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'building-columns']} />
      </Icon>
    ),
    href: '/company',
  },
  {
    key: 'users',
    label: 'Users',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'user-friends']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'user',
        label: 'User List',
        href: '/user',
      },
      {
        key: 'roles',
        label: 'User Roles',
        href: '/roles',
      },
    ],
  },
  {
    key: 'branch',
    label: 'Branches',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'building']} />
      </Icon>
    ),
    href: '/branch',
  },
  {
    key: 'students',
    label: 'Students',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['fas', 'portrait']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'student-management',
        label: 'Student List',
        href: '/student-management',
      },
      {
        key: 'suspension',
        label: 'Suspension',
        href: '/suspension',
      },
      {
        key: 'withdrawal',
        label: 'Withdrawal',
        href: '/withdrawal',
      },
    ],
  },
  {
    key: 'programmes',
    label: 'Programmes',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'tasks-alt']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'programme',
        label: 'Programmes List',
        href: '/programme/management',
      },
      {
        key: 'programmes-settings',
        label: 'Settings',
        subMenu: [
          {
            key: 'programme/curriculum-level',
            label: '- Curriculum & Level',
            href: '/programme/curriculum-level',
          },
          {
            key: 'programme/categories-type',
            label: '- Categories & Type',
            href: '/programme/categories-type',
          },
          {
            key: 'programme/academic-term',
            label: '- Academic & Term',
            href: '/programme/academic-term',
          },
          {
            key: 'programme/holiday',
            label: '- Holiday Setup',
            href: '/programme/holiday',
          },
        ],
      },
    ],
  },
  {
    key: 'class/management',
    label: 'Classes',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'users-class']} />
      </Icon>
    ),
    href: '/class/management',
  },
  {
    key: 'finance',
    label: 'Finance',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'money-check-edit-alt']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'finance/payment-advice',
        label: 'Payment',
        href: '/finance/payment-advice',
      },
      {
        key: 'finance/credit-note',
        label: 'Credit Note',
        href: '/finance/credit-note',
      },
      {
        key: 'finance/refund',
        label: 'Refund',
        href: '/finance/refund',
      },
      {
        key: 'finance-settings',
        label: 'Settings',
        subMenu: [
          {
            key: 'finance/payment-setting',
            label: '- Payment Setting',
            href: '/finance/payment-setting',
          },
          {
            key: 'finance/payment-discount',
            label: '- Payment Discount',
            href: '/finance/payment-discount',
          },
          {
            key: 'finance/pricing',
            label: '- Pricing',
            href: '/finance/pricing',
          },
        ],
      },
    ],
  },
  {
    key: 'reward',
    label: 'Rewards',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'gifts']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'reward/report',
        label: 'Reward Report',
        href: '/reward/report',
      },
      {
        key: 'reward/referral',
        label: 'Referral Setup',
        href: '/reward/referral',
      },
    ],
  },
  {
    key: 'communication',
    label: 'Communication',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'comment']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'communication/broadcast',
        label: 'Broadcast',
        href: '/communication/broadcast',
      },
      {
        key: 'communication/news',
        label: 'News',
        href: '/communication/news',
      },
      {
        key: 'communication/three-times-absent',
        label: '3x Absent',
        href: '/communication/three-times-absent',
      },
      {
        key: 'communication/unsubscribers',
        label: 'Unsubscribers',
        href: '/communication/unsubscribers',
      },
    ],
  },
  {
    key: 'report',
    label: 'Report',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'file']} />
      </Icon>
    ),
    subMenu: [
      // {
      //   key: 'number-of-classes',
      //   label: 'Classes',
      //   href: '/report/number-of-classes',
      // },
      // {
      //   key: 'new-sign-up-student',
      //   label: 'Daily Activity Log',
      //   href: '/report/new-sign-up-student',
      // },
      // {
      //   key: 'classroom-utilisation',
      //   label: 'Center Utilisation',
      //   href: '/report/classroom-utilisation',
      // },
      // {
      //   key: 'report/enrolment-summary',
      //   label: 'Enrolment Summary',
      //   href: '/report/enrolment-summary',
      // },
      // {
      //   key: 'report/revenue-breakdown',
      //   label: 'Revenue Breakdown',
      //   href: '/report/revenue-breakdown',
      // },
      {
        key: 'report/daily-receipt-report',
        label: 'Daily Receipt',
        href: '/report/daily-receipt-report',
      },
      {
        key: 'report/daily-closing-report',
        label: 'Daily Closing',
        href: '/report/daily-closing-report',
      },
      {
        key: 'report/overpaid-report',
        label: 'Overpaid',
        href: '/report/overpaid-report',
      },
      {
        key: 'report/pa-list',
        label: 'PA Listing',
        href: '/report/pa-listing',
      },
      {
        key: 'report/enrollment-list',
        label: 'Enrollment List',
        href: '/report/enrollment-list',
      },
      {
        key: 'report/student-attendance-report',
        label: 'Student Attendance',
        href: '/report/student-attendance-report',
      },
      {
        key: 'report/revenue-recognition',
        label: 'Revenue Recognition',
        href: '/report/revenue-recognition',
      },
      {
        key: 'report/deposit',
        label: 'Deposit Report',
        href: '/report/deposit',
      },
    ],
  },
  {
    key: 'attendance',
    label: 'Attendance',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'users']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'attendance/list',
        label: 'Attendance List',
        href: '/attendance/list',
      },
      {
        key: 'attendance/monitor',
        label: 'Attendance Monitor',
        href: '/attendance/monitor',
      },
      {
        key: 'attendance/gantry-record',
        label: 'Gantry Record',
        href: '/attendance/gantry-record',
      },
    ],
  },
  // {
  //   key: 'graduation',
  //   label: 'Graduation',
  //   icon: (
  //     <Icon viewBox={'0, 0, 1024, 1024'}>
  //       <FontAwesomeIcon icon={['far', 'graduation-cap']} />
  //     </Icon>
  //   ),
  //   subMenu: [
  //     {
  //       key: 'graduation/list',
  //       label: 'Graduation List',
  //       href: '/graduation/list',
  //     },
  //     {
  //       key: 'graduation/setting',
  //       label: 'Settings',
  //       href: '/graduation/setting',
  //     },
  //     {
  //       key: 'graduation/form-builder',
  //       label: 'Form Builder',
  //       href: '/graduation/form-builder',
  //     },
  //   ],
  // },
  {
    key: 'content-management',
    label: 'Content Management',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'block-quote']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'content-management/menu',
        label: 'Menu',
        href: '/content-management/menu',
      },
      {
        key: 'content-management/category',
        label: 'Category',
        href: '/content-management/category',
      },
      {
        key: 'content-management/post',
        label: 'Post',
        href: '/content-management/post',
      },
      {
        key: 'content-management/resource/category',
        label: 'Resources Category',
        href: '/content-management/resource/category',
      },
      {
        key: 'content-management/resource/detail',
        label: 'Resources Details',
        href: '/content-management/resource/detail',
      },
      {
        key: 'content-management/survey',
        label: 'Survey',
        href: '/content-management/survey',
      },
    ],
  },
  {
    key: 'enquiry',
    label: 'Enquiry',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'wreath']} />
      </Icon>
    ),
    href: '/enquiry',
  },
];

export const frontDeskMenu: MenuItem[] = [
  {
    key: 'students',
    label: 'Students',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['fas', 'portrait']} />
      </Icon>
    ),
    href: '/student-management',
    subMenu: [
      {
        key: 'student-management',
        label: 'Student List',
        href: '/student-management',
      },
      {
        key: 'suspension',
        label: 'Suspension',
        href: '/suspension',
      },
      {
        key: 'withdrawal',
        label: 'Withdrawal',
        href: '/withdrawal',
      },
    ],
  },
  {
    key: 'class/management',
    label: 'Classes',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'users-class']} />
      </Icon>
    ),
    href: '/class/management',
  },
  {
    key: 'finance',
    label: 'Finance',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'money-check-edit-alt']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'finance/payment-advice',
        label: 'Payment',
        href: '/finance/payment-advice',
      },
    ],
  },
  {
    key: 'reward',
    label: 'Rewards',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'gifts']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'reward/report',
        label: 'Reward Report',
        href: '/reward/report',
      },
      {
        key: 'reward/referral',
        label: 'Referral Setup',
        href: '/reward/referral',
      },
    ],
  },
  {
    key: 'communication',
    label: 'Communication',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'comment']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'communication/broadcast',
        label: 'Broadcast',
        href: '/communication/broadcast',
      },
      {
        key: 'communication/news',
        label: 'News',
        href: '/communication/news',
      },
      {
        key: 'communication/three-times-absent',
        label: '3x Absent',
        href: '/communication/three-times-absent',
      },
      {
        key: 'communication/unsubscribers',
        label: 'Unsubscribers',
        href: '/communication/unsubscribers',
      },
    ],
  },
  {
    key: 'report',
    label: 'Report',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'file']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'report/daily-receipt-report',
        label: 'Daily Receipt',
        href: '/report/daily-receipt-report',
      },
    ],
  },
  {
    key: 'attendance',
    label: 'Attendance',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'users']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'attendance/list',
        label: 'Attendance List',
        href: '/attendance/list',
      },
      {
        key: 'attendance/monitor',
        label: 'Attendance Monitor',
        href: '/attendance/monitor',
      },
      {
        key: 'attendance/gantry-record',
        label: 'Gantry Record',
        href: '/attendance/gantry-record',
      },
    ],
  },
];

export const opsManagerMenu: MenuItem[] = [
  {
    key: 'branch',
    label: 'Branches',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'building']} />
      </Icon>
    ),
    href: '/branch',
  },
  {
    key: 'students',
    label: 'Students',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['fas', 'portrait']} />
      </Icon>
    ),
    href: '/student-management',
    subMenu: [
      {
        key: 'student-management',
        label: 'Student List',
        href: '/student-management',
      },
      {
        key: 'suspension',
        label: 'Suspension',
        href: '/suspension',
      },
      {
        key: 'withdrawal',
        label: 'Withdrawal',
        href: '/withdrawal',
      },
    ],
  },
  {
    key: 'programmes',
    label: 'Programmes',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'tasks-alt']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'programme',
        label: 'Programmes List',
        href: '/programme/management',
      },
      {
        key: 'programmes-settings',
        label: 'Settings',
        subMenu: [
          {
            key: 'programme/curriculum-level',
            label: '- Curriculum & Level',
            href: '/programme/curriculum-level',
          },
          {
            key: 'programme/categories-type',
            label: '- Categories & Type',
            href: '/programme/categories-type',
          },
          {
            key: 'programme/academic-term',
            label: '- Academic & Term',
            href: '/programme/academic-term',
          },
          {
            key: 'programme/holiday',
            label: '- Holiday Setup',
            href: '/programme/holiday',
          },
        ],
      },
    ],
  },
  {
    key: 'class/management',
    label: 'Classes',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'users-class']} />
      </Icon>
    ),
    href: '/class/management',
  },
  {
    key: 'finance',
    label: 'Finance',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'money-check-edit-alt']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'finance/payment-advice',
        label: 'Payment',
        href: '/finance/payment-advice',
      },
      {
        key: 'finance-settings',
        label: 'Settings',
        subMenu: [
          {
            key: 'finance/pricing',
            label: '- Pricing',
            href: '/finance/pricing',
          },
        ],
      },
    ],
  },
  {
    key: 'communication',
    label: 'Communication',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'comment']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'communication/broadcast',
        label: 'Broadcast',
        href: '/communication/broadcast',
      },
      {
        key: 'communication/news',
        label: 'News',
        href: '/communication/news',
      },
      {
        key: 'communication/three-times-absent',
        label: '3x Absent',
        href: '/communication/three-times-absent',
      },
      {
        key: 'communication/unsubscribers',
        label: 'Unsubscribers',
        href: '/communication/unsubscribers',
      },
    ],
  },
  {
    key: 'report',
    label: 'Report',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'file']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'report/daily-receipt-report',
        label: 'Daily Receipt',
        href: '/report/daily-receipt-report',
      },
      // {
      //   key: 'report/enrollment-list',
      //   label: 'Enrollment List',
      //   href: '/report/enrollment-list',
      // },
      {
        key: 'report/enrollment-list',
        label: 'Enrollment List',
        href: '/report/enrollment-list',
      },
      {
        key: 'report/student-attendance-report',
        label: 'Student Attendance',
        href: '/report/student-attendance-report',
      },
    ],
  },
  {
    key: 'attendance',
    label: 'Attendance',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'users']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'attendance/list',
        label: 'Attendance List',
        href: '/attendance/list',
      },
      {
        key: 'attendance/monitor',
        label: 'Attendance Monitor',
        href: '/attendance/monitor',
      },
      {
        key: 'attendance/gantry-record',
        label: 'Gantry Record',
        href: '/attendance/gantry-record',
      },
    ],
  },
];

export const hrMenu: MenuItem[] = [
  {
    key: 'users',
    label: 'Users',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'user-friends']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'user',
        label: 'User List',
        href: '/user',
      },
      {
        key: 'roles',
        label: 'User Roles',
        href: '/roles',
      },
    ],
  },
  {
    key: 'students',
    label: 'Students',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['fas', 'portrait']} />
      </Icon>
    ),
    href: '/student-management',
    subMenu: [
      {
        key: 'student-management',
        label: 'Student List',
        href: '/student-management',
      },
      {
        key: 'suspension',
        label: 'Suspension',
        href: '/suspension',
      },
      {
        key: 'withdrawal',
        label: 'Withdrawal',
        href: '/withdrawal',
      },
    ],
  },
  {
    key: 'class/management',
    label: 'Classes',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'users-class']} />
      </Icon>
    ),
    href: '/class/management',
  },
  {
    key: 'report',
    label: 'Report',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'file']} />
      </Icon>
    ),
    subMenu: [
      // {
      //   key: 'report/revenue-breakdown',
      //   label: 'Revenue Breakdown',
      //   href: '/report/revenue-breakdown',
      // },
      // {
      //   key: 'number-of-classes',
      //   label: 'Classes',
      //   href: '/report/number-of-classes',
      // },
      // {
      //   key: 'new-sign-up-student',
      //   label: 'Daily Activity Log',
      //   href: '/report/new-sign-up-student',
      // },
      // {
      //   key: 'classroom-utilisation',
      //   label: 'Center Utilisation',
      //   href: '/report/classroom-utilisation',
      // },
      {
        key: 'report/daily-receipt-report',
        label: 'Daily Receipt',
        href: '/report/daily-receipt-report',
      },
      {
        key: 'report/daily-closing-report',
        label: 'Daily Closing',
        href: '/report/daily-closing-report',
      },
      {
        key: 'report/overpaid-report',
        label: 'Overpaid',
        href: '/report/overpaid-report',
      },
      // {
      //   key: 'report/enrollment-list',
      //   label: 'Enrollment List',
      //   href: '/report/enrollment-list',
      // },
      {
        key: 'report/student-attendance-report',
        label: 'Student Attendance',
        href: '/report/student-attendance-report',
      },
    ],
  },
];

export const financeStaffMenu: MenuItem[] = [
  {
    key: 'students',
    label: 'Students',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['fas', 'portrait']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'student-management',
        label: 'Student List',
        href: '/student-management',
      },
      {
        key: 'suspension',
        label: 'Suspension',
        href: '/suspension',
      },
      {
        key: 'withdrawal',
        label: 'Withdrawal',
        href: '/withdrawal',
      },
    ],
  },
  {
    key: 'finance',
    label: 'Finance',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'money-check-edit-alt']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'finance/payment-advice',
        label: 'Payment',
        href: '/finance/payment-advice',
      },
      {
        key: 'finance/credit-note',
        label: 'Credit Note',
        href: '/finance/credit-note',
      },
      {
        key: 'finance/refund',
        label: 'Refund',
        href: '/finance/refund',
      },
      {
        key: 'finance-settings',
        label: 'Settings',
        subMenu: [
          {
            key: 'finance/payment-setting',
            label: '- Payment Setting',
            href: '/finance/payment-setting',
          },
          {
            key: 'finance/payment-discount',
            label: '- Payment Discount',
            href: '/finance/payment-discount',
          },
          {
            key: 'finance/pricing',
            label: '- Pricing',
            href: '/finance/pricing',
          },
        ],
      },
    ],
  },
  {
    key: 'reward',
    label: 'Rewards',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'gifts']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'reward/report',
        label: 'Reward Report',
        href: '/reward/report',
      },
      {
        key: 'reward/referral',
        label: 'Referral Setup',
        href: '/reward/referral',
      },
    ],
  },
  {
    key: 'report',
    label: 'Report',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'file']} />
      </Icon>
    ),
    subMenu: [
      // {
      //   key: 'report/enrolment-summary',
      //   label: 'Enrolment Summary',
      //   href: '/report/enrolment-summary',
      // },
      // {
      //   key: 'report/revenue-breakdown',
      //   label: 'Revenue Breakdown',
      //   href: '/report/revenue-breakdown',
      // },
      // {
      //   key: 'number-of-classes',
      //   label: 'Classes',
      //   href: '/report/number-of-classes',
      // },
      // {
      //   key: 'new-sign-up-student',
      //   label: 'Daily Activity Log',
      //   href: '/report/new-sign-up-student',
      // },
      // {
      //   key: 'classroom-utilisation',
      //   label: 'Center Utilisation',
      //   href: '/report/classroom-utilisation',
      // },
      {
        key: 'report/daily-receipt-report',
        label: 'Daily Receipt',
        href: '/report/daily-receipt-report',
      },
      {
        key: 'report/daily-closing-report',
        label: 'Daily Closing',
        href: '/report/daily-closing-report',
      },
      {
        key: 'report/overpaid-report',
        label: 'Overpaid',
        href: '/report/overpaid-report',
      },
      // {
      //   key: 'report/enrollment-list',
      //   label: 'Enrollment List',
      //   href: '/report/enrollment-list',
      // },
      {
        key: 'report/enrollment-list',
        label: 'Enrollment List',
        href: '/report/enrollment-list',
      },
      {
        key: 'report/pa-list',
        label: 'PA Listing',
        href: '/report/pa-listing',
      },
      {
        key: 'report/student-attendance-report',
        label: 'Student Attendance',
        href: '/report/student-attendance-report',
      },
    ],
  },
];

export const financeManagerMenu: MenuItem[] = [
  {
    key: 'companies',
    label: 'Companies',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'building-columns']} />
      </Icon>
    ),
    href: '/company',
  },
  {
    key: 'students',
    label: 'Students',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['fas', 'portrait']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'student-management',
        label: 'Student List',
        href: '/student-management',
      },
      {
        key: 'suspension',
        label: 'Suspension',
        href: '/suspension',
      },
      {
        key: 'withdrawal',
        label: 'Withdrawal',
        href: '/withdrawal',
      },
    ],
  },
  {
    key: 'finance',
    label: 'Finance',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'money-check-edit-alt']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'finance/payment-advice',
        label: 'Payment',
        href: '/finance/payment-advice',
      },
      {
        key: 'finance/credit-note',
        label: 'Credit Note',
        href: '/finance/credit-note',
      },
      {
        key: 'finance/refund',
        label: 'Refund',
        href: '/finance/refund',
      },
      {
        key: 'finance-settings',
        label: 'Settings',
        subMenu: [
          {
            key: 'finance/payment-setting',
            label: '- Payment Setting',
            href: '/finance/payment-setting',
          },
          {
            key: 'finance/payment-discount',
            label: '- Payment Discount',
            href: '/finance/payment-discount',
          },
          {
            key: 'finance/pricing',
            label: '- Pricing',
            href: '/finance/pricing',
          },
        ],
      },
    ],
  },
  {
    key: 'reward',
    label: 'Rewards',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'gifts']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'reward/report',
        label: 'Reward Report',
        href: '/reward/report',
      },
      {
        key: 'reward/referral',
        label: 'Referral Setup',
        href: '/reward/referral',
      },
    ],
  },
  {
    key: 'report',
    label: 'Report',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'file']} />
      </Icon>
    ),
    subMenu: [
      // {
      //   key: 'report/enrolment-summary',
      //   label: 'Enrolment Summary',
      //   href: '/report/enrolment-summary',
      // },
      // {
      //   key: 'report/revenue-breakdown',
      //   label: 'Revenue Breakdown',
      //   href: '/report/revenue-breakdown',
      // },
      // {
      //   key: 'number-of-classes',
      //   label: 'Classes',
      //   href: '/report/number-of-classes',
      // },
      // {
      //   key: 'new-sign-up-student',
      //   label: 'Daily Activity Log',
      //   href: '/report/new-sign-up-student',
      // },
      // {
      //   key: 'classroom-utilisation',
      //   label: 'Center Utilisation',
      //   href: '/report/classroom-utilisation',
      // },
      {
        key: 'report/daily-receipt-report',
        label: 'Daily Receipt',
        href: '/report/daily-receipt-report',
      },
      {
        key: 'report/daily-closing-report',
        label: 'Daily Closing',
        href: '/report/daily-closing-report',
      },
      {
        key: 'report/overpaid-report',
        label: 'Overpaid',
        href: '/report/overpaid-report',
      },
      // {
      //   key: 'report/enrollment-list',
      //   label: 'Enrollment List',
      //   href: '/report/enrollment-list',
      // },
      {
        key: 'report/enrollment-list',
        label: 'Enrollment List',
        href: '/report/enrollment-list',
      },
      {
        key: 'report/pa-list',
        label: 'PA Listing',
        href: '/report/pa-listing',
      },
      {
        key: 'report/student-attendance-report',
        label: 'Student Attendance',
        href: '/report/student-attendance-report',
      },
    ],
  },
];

export const attendanceCheckerMenu: MenuItem[] = [
  {
    key: 'attendance',
    label: 'Attendance',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'users']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'attendance/list',
        label: 'Attendance List',
        href: '/attendance/list',
      },
    ],
  },
];

export const salesMenu: MenuItem[] = [
  {
    key: 'communication',
    label: 'Communication',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'comment']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'communication/broadcast',
        label: 'Broadcast',
        href: '/communication/broadcast',
      },
      {
        key: 'communication/news',
        label: 'News',
        href: '/communication/news',
      },
      {
        key: 'communication/three-times-absent',
        label: '3x Absent',
        href: '/communication/three-times-absent',
      },
      {
        key: 'communication/unsubscribers',
        label: 'Unsubscribers',
        href: '/communication/unsubscribers',
      },
    ],
  },
  {
    key: 'report',
    label: 'Report',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'file']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'report/daily-receipt-report',
        label: 'Daily Receipt',
        href: '/report/daily-receipt-report',
      },
    ],
  },
];

export const internMenu: MenuItem[] = [
  {
    key: 'students',
    label: 'Students',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['fas', 'portrait']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'student-management',
        label: 'Student List',
        href: '/student-management',
      },
      {
        key: 'suspension',
        label: 'Suspension',
        href: '/suspension',
      },
      {
        key: 'withdrawal',
        label: 'Withdrawal',
        href: '/withdrawal',
      },
    ],
  },
  {
    key: 'class/management',
    label: 'Classes',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'users-class']} />
      </Icon>
    ),
    href: '/class/management',
  },
  {
    key: 'finance',
    label: 'Finance',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'money-check-edit-alt']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'finance/payment-advice',
        label: 'Payment',
        href: '/finance/payment-advice',
      },
    ],
  },
  {
    key: 'communication',
    label: 'Communication',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'comment']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'communication/broadcast',
        label: 'Broadcast',
        href: '/communication/broadcast',
      },
      {
        key: 'communication/news',
        label: 'News',
        href: '/communication/news',
      },
      {
        key: 'communication/three-times-absent',
        label: '3x Absent',
        href: '/communication/three-times-absent',
      },
      {
        key: 'communication/unsubscribers',
        label: 'Unsubscribers',
        href: '/communication/unsubscribers',
      },
    ],
  },
  {
    key: 'report',
    label: 'Report',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'file']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'report/daily-receipt-report',
        label: 'Daily Receipt',
        href: '/report/daily-receipt-report',
      },
    ],
  },
];

export const fullTimeTutorMenu: MenuItem[] = [
  {
    key: 'attendance',
    label: 'Attendance',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'users']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'attendance/list',
        label: 'Attendance List',
        href: '/attendance/list',
      },
      {
        key: 'attendance/monitor',
        label: 'Attendance Monitor',
        href: '/attendance/monitor',
      },
      {
        key: 'attendance/gantry-record',
        label: 'Gantry Record',
        href: '/attendance/gantry-record',
      },
    ],
  },
];

export const partTimeTutorMenu: MenuItem[] = [
  {
    key: 'attendance',
    label: 'Attendance',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'users']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'attendance/list',
        label: 'Attendance List',
        href: '/attendance/list',
      },
      {
        key: 'attendance/monitor',
        label: 'Attendance Monitor',
        href: '/attendance/monitor',
      },
      {
        key: 'attendance/gantry-record',
        label: 'Gantry Record',
        href: '/attendance/gantry-record',
      },
    ],
  },
];

export const hodMenu: MenuItem[] = [
  {
    key: 'attendance',
    label: 'Attendance',
    icon: (
      <Icon viewBox={'0, 0, 1024, 1024'}>
        <FontAwesomeIcon icon={['far', 'users']} />
      </Icon>
    ),
    subMenu: [
      {
        key: 'attendance/list',
        label: 'Attendance List',
        href: '/attendance/list',
      },
      {
        key: 'attendance/monitor',
        label: 'Attendance Monitor',
        href: '/attendance/monitor',
      },
      {
        key: 'attendance/gantry-record',
        label: 'Gantry Record',
        href: '/attendance/gantry-record',
      },
    ],
  },
];
