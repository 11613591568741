import { Button, Col, Form, Input, Layout, Row } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  formatCurrencyFullText,
  convertToNumber,
  parseCurrencyToValue,
} from '@helpers';
import CurrencyInput from '@components/CurrencyInput/component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '@ant-design/icons/lib/components/Icon';
import Maybe from 'graphql/tsutils/Maybe';
import { debounce } from 'lodash';

interface OtherPaymentsType {
  otherPayments: Maybe<any>;
  onChange: (value: any) => void;
  disabled?: boolean;
  disabledButton?: boolean;
}
const OtherPayments: FunctionComponent<OtherPaymentsType> = ({
  otherPayments,
  disabled,
  onChange,
  ...props
}) => {
  const formName = 'otherPayments';
  const [form] = Form.useForm();
  const [callField, setCallFiled] = useState(true);
  const updateForm = () => {
    setCallFiled(false);
    // const currentOtherPayment = form.getFieldsValue(['content']);

    // const removeFieldNotValue = currentOtherPayment.content.filter(
    //   (e: any) => !!e.name && !!e.amount,
    // );

    // onChange({
    //   [formName]: removeFieldNotValue.map((e: any) => {
    //     return {
    //       name: e?.name?.trim() ?? '',
    //       amount: parseCurrencyToValue(e.amount),
    //     };
    //   }),
    // });
  };
  const debouncedCallApi = debounce(updateForm, 300);
  const debounceFn = React.useCallback(debounce(onChange, 450), []);

  const onBlurOtherPayment = async (
    e: React.FocusEvent<HTMLInputElement>,
    index: number,
  ) => {
    e.preventDefault();
    const valid = await form.validateFields();
    if (valid) {
      debouncedCallApi();
    }
  };
  const handleRemove = async (index: number, remove: (i: number) => void) => {
    remove(index);
    debouncedCallApi();

    let otherPayments: { error: string | null; rows: any[] } = {
      error: null,
      rows: [],
    };
    const currentOtherPayments = form.getFieldsValue(['content']);
    currentOtherPayments.content.forEach((e: any) => {
      if (!!e.name && !!e.amount) {
        otherPayments.rows.push({
          name: e.name,
          amount: convertToNumber(e.amount.toString()),
        });
      }
    });
    debounceFn(otherPayments);
  };

  useEffect(() => {
    if (callField && !!otherPayments?.data?.length) {
      form.setFieldsValue({
        content: otherPayments?.data || [],
      });
    }
  }, [callField, form, otherPayments]);

  const onFieldChanged = () => {
    form
      .validateFields()
      .then(res => console.log({ res }))
      .catch(error => {
        const { errorFields, values } = error;
        let otherPayments: { error: string | null; rows: any[] } = {
          error: null,
          rows: [],
        };
        otherPayments.error = errorFields?.length
          ? `otherPayments ${errorFields[0]?.errors?.[0]}`
          : null;
        values.content.forEach((e: any) => {
          if (!!e.name && !!e.amount) {
            otherPayments.rows.push({
              name: e.name,
              amount: convertToNumber(e.amount.toString()),
            });
          }
        });
        debounceFn(otherPayments);
      });
  };

  return (
    <div>
      <Row justify="space-between">
        <p className="title" style={{ padding: 0 }}>
          Other Payments
        </p>
        <p className="title" style={{ padding: 0 }}>
          (Total:{' '}
          {formatCurrencyFullText(otherPayments?.total?.toString() ?? '0')})
        </p>
      </Row>

      <div>
        <Form
          form={form}
          name={formName}
          layout="vertical"
          onValuesChange={onFieldChanged}
        >
          <Form.List name={'content'}>
            {(fields, { add, remove }) => (
              <div>
                {fields &&
                  fields.map((field, index) => (
                    <Row
                      justify="space-between"
                      gutter={[20, 0]}
                      align="middle"
                      style={{ marginTop: 0, marginBottom: 20 }}
                      key={index}
                    >
                      <Col span={11}>
                        <Form.Item
                          label="(*) Name"
                          rules={[
                            { required: true, message: 'Name is required.' },
                          ]}
                          style={{ marginBottom: 0 }}
                          name={[index, 'name']}
                        >
                          <Input
                            disabled={disabled}
                            placeholder="Fill in payment name"
                            onBlur={e => onBlurOtherPayment(e, index)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          label="(*) Amount"
                          rules={[
                            { required: true, message: 'Amount is required.' },
                          ]}
                          style={{ marginBottom: 0 }}
                          name={[index, 'amount']}
                        >
                          <CurrencyInput
                            disabled={disabled}
                            placeholder="Fill in amount"
                            onBlur={e => onBlurOtherPayment(e, index)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        {disabled ? null : (
                          <Button
                            type="link"
                            disabled={props.disabledButton}
                            onClick={() => {
                              handleRemove(index, remove);
                            }}
                            className="width-auto"
                            style={{
                              color: '#f54f64',
                              padding: 0,
                              margin: '30px auto 0',
                              display: 'block',
                            }}
                            icon={
                              <Icon viewBox={'0, 0, 1024, 1024'}>
                                <FontAwesomeIcon icon={['far', 'trash-alt']} />
                              </Icon>
                            }
                          ></Button>
                        )}
                      </Col>
                    </Row>
                  ))}
                {!disabled && (
                  <Button
                    type="primary"
                    ghost
                    style={{ width: '100%' }}
                    disabled={props.disabledButton}
                    htmlType="button"
                    onClick={add}
                    icon={
                      <Icon viewBox={'0, 0, 1024, 1024'}>
                        <FontAwesomeIcon icon={['fal', 'plus']} />
                      </Icon>
                    }
                  >
                    Add Payment
                  </Button>
                )}
              </div>
            )}
          </Form.List>
        </Form>
      </div>
    </div>
  );
};

export default OtherPayments;
