import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

// import './styles.scss';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import { formatCurrency } from '../../helpers';

export type CurrencyInputProps = InputProps & {
  disabled?: boolean;
  negative?: boolean;
  maxValue?: number;
  value?: string;
  unit?: string;
  change?: (s: string) => void;
};

const CurrencyInput: FunctionComponent<CurrencyInputProps> = ({
  disabled,
  maxValue,
  negative,
  value,
  unit = 'S$',
  change,
  onBlur,
  ...rest
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef<Input>(null);
  const blur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocus(false);
    if (onBlur) {
      onBlur(e);
    }
  };
  const format = useCallback(() => {
    // appends $ to value, validates decimal side
    // and puts cursor back in right position.

    var input_val = value ? `${value}` : '';

    // don't validate empty input
    if (input_val === '') {
      return;
    }

    // original length
    var original_len = input_val.length;

    // initial caret position
    var caret_pos = inputRef.current?.input.selectionStart || 0;

    // check for decimal
    if (input_val.indexOf('.') >= 0) {
      // get position of first decimal
      // this prevents multiple decimals from
      // being entered
      var decimal_pos = input_val.indexOf('.');

      // split number by decimal point
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);

      // add commas to left side of number
      left_side = formatCurrency(left_side);

      // validate right side
      right_side = formatCurrency(right_side);

      // On blur make sure 2 numbers after decimal
      if (!isFocus) {
        right_side += '00';
      }

      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);

      // join number by .
      input_val = `${negative ? '-' : ''}${unit}${left_side}.${right_side}`;
    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = formatCurrency(input_val);
      input_val = `${negative ? '-' : ''}${unit}${input_val}`;

      // final formatting
      if (!isFocus) {
        input_val += '.00';
      }
    }

    // eslint-disable-next-line no-unused-expressions
    inputRef.current?.setValue(input_val);

    // send updated string to input
    // inputRef.current.in.value = input_val;

    // put caret back in the right position
    var updated_len = input_val.length;
    caret_pos = updated_len - original_len + caret_pos;
    // eslint-disable-next-line no-unused-expressions
    inputRef.current?.input.setSelectionRange(caret_pos, caret_pos);
  }, [value, isFocus, negative, unit]);

  useEffect(() => {
    format();
  }, [value, format]);

  return (
    <Input
      className={`currency-input ${disabled ? 'currency-input-disable' : ''}`}
      type="text"
      onBlur={blur}
      onFocus={e => setIsFocus(true)}
      ref={inputRef}
      disabled={disabled}
      {...rest}
    />
  );
};

export default CurrencyInput;
