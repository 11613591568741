import React from 'react';

import { Roles } from '@models';

const EnquiryList = React.lazy(() => import('@pages/enquiry/list'));
const EnquiryForm = React.lazy(() => import('@pages/enquiry/view/EnquiryForm'));
const ReferralSetup = React.lazy(
  () => import('@pages/reward/referral/view/ReferralForm'),
);

export default [
  {
    path: '/enquiry',
    component: EnquiryList,
    exact: true,
    title: 'Enquiry',
    isPublic: true,
    roles: [],
  },
  {
    path: '/enquiry/create',
    component: EnquiryForm,
    exact: true,
    title: 'Create Enquiry',
    isPublic: true,
    roles: [],
  },
  {
    path: '/enquiry/:id/update',
    component: EnquiryForm,
    exact: true,
    title: 'Enquiry Detail',
    isPublic: true,
    roles: [],
  },
];
