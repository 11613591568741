import { formatCurrencyFullText, parseUTCToTime } from '@helpers';
import { Col, Row } from 'antd';
import React from 'react';
import './style.scss';

type Props = {
  transferData?: any;
  transferBill?: any;
  hideTitle?: boolean;
};

export const PATransferInformation = ({
  transferBill,
  hideTitle,
  transferData,
}: Props) => {
  return (
    <div className="transfer-info_reason">
      <div className="transfer-info">
        {!hideTitle && (
          <Row justify="space-between">
            <Col>
              <p className="create-pa__title">Transfer Information</p>
            </Col>
            <Col>
              <span className="text--label">Transfer date:</span>
              <span className="text margin-left--xs">
                {parseUTCToTime(transferBill?.createdAt, 'DD/MM/YYY')}
              </span>
            </Col>
          </Row>
        )}
        <div className="relative student-card">
          <div className="flex justify-space">
            <p className="text--bold text-ink">
              {transferBill?.fromClass?.name ?? ''}
            </p>
          </div>
          <div className="flex justify-space">
            <p className="label">Class Fee</p>
            <p className="text">
              {formatCurrencyFullText(
                transferData?.fromClass?.classFee?.toString() ?? '__',
              )}
            </p>
          </div>
          <div className="flex justify-space">
            <p className="label">Paid Term</p>
            <p className="text">
              {(transferBill?.fromClass?.termInformations ?? [])
                .sort((a: any, b: any) => a.name - b.name)
                ?.map((t: any) => `T${t.name}`)
                .join(', ')}
            </p>
          </div>
          <div className="flex justify-space">
            <p className="label">Paid Lesson</p>
            <p className="text">
              {(transferBill?.fromClass?.termInformations ?? [])
                .map((term: any) => term.lessonIds.length)
                .reduce((a: any, b: any) => a + b) ?? '__'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
