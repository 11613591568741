import { apiService } from '@services/api';
import { ICreateUpdateCompanyReq } from '@interfaces/network';

const createCompany = async (request: ICreateUpdateCompanyReq) =>
  await apiService.post(`/companies`, request);

const updateCompany = async (id: string, request: ICreateUpdateCompanyReq) =>
  await apiService.put(`/companies/${id}`, request);

export { createCompany, updateCompany };
