import 'react-quill/dist/quill.snow.css';

import { Col, Divider, Form, Input, Row, Select } from 'antd';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { CreatePaymentAdviceFormSession } from '../interface/CreatePaymentAdviceFormSession';
import { debounce } from 'lodash';
import { formatCurrencyFullText } from '@helpers';

const { TextArea } = Input;

const defaultNotes = `
<div>
  <div style="text-align: left; max-width: 100%; font-size: 13px; margin-bottom: 10px; font-weight: bold;">
    <table style="width: 100%; border-collapse: collapse; border: 1px solid black;">
      <tr style="border: 1px solid black;">
        <th style="border: 1px solid black; padding: 5px">
          <span class="text-ink text-bold">Payment Mode</span>
        </th>
        <th style="border: 1px solid black; padding: 5px">
          <span class="text-ink text-bold">Bank Details</span>
        </th>
      </tr>
      <tr style="border: 1px solid black;">
        <td style="border: 1px solid black; padding: 5px">
          <span class="text-ink text-bold">Paynow (Preferred)</span>
        </td>
        <td style="border: 1px solid black; padding: 5px">
          <span class="text-ink text-bold">
            Indigo Education Centre Pte. Ltd.
          </span>
          <br />
          <span style="display: flex;" class="text-ink text-bold">
            <span style="width: 50%;">UEN No.</span>
            <span>201024401N</span>
          </span>
        </td>
      </tr>
    </table>
  </div>
  1. After making payment through PayNow, please provide us
  <span class="text-ink text-bold">screenshot of payment</span> to
  <a href="mailto:contact@indigogroup.com.sg">contact@indigogroup.com.sg</a>
  with the <span class="text-ink text-bold">following details</span>:<br />
  <div style="margin-left: 20px;">
    <span>a. Name of Payor per Bank records (i.e. Tan Heng Sot)</span>
    <br />
    <span>b. Date of Payment (i.e. 18th June 2021)</span>
    <br />
    <span>c. Amount (i.e. $1,540.00)</span>
    <br />
    <span>
      d. Name of student, level & Subject (Alex Tan, Sec IP 3 English)
    </span>
  </div>
  2. NETS & Cash payments are also accepted at the physical outlet.
  <br />
  3. Late payment fee of $10 per week will be levied on overdue payments.
</div>
`;

export interface SummaryProps extends CreatePaymentAdviceFormSession {}

const Summary: FunctionComponent<SummaryProps> = ({
  onChange,
  paymentAdvice,
  disabled,
}) => {
  const [form] = Form.useForm();
  const [editorValue, setEditorValue] = useState(
    paymentAdvice?.notes ? paymentAdvice?.notes : '',
  );

  const [getInitialRemarks, setInitialRemarks] = useState(false);

  const textFieldRef: any = useRef<HTMLDivElement>(null);

  const notes = React.useMemo(() => {
    return paymentAdvice?.notes;
  }, [paymentAdvice]);

  useEffect(() => {
    if (notes) {
      setEditorValue(notes);
    } else {
      setEditorValue(defaultNotes);
    }
  }, [notes]);

  useEffect(() => {
    if (editorValue) {
      textFieldRef.current.innerHTML = editorValue;
    }
  }, [editorValue]);

  useEffect(() => {
    if (!getInitialRemarks && paymentAdvice?.remarks) {
      form.setFieldsValue({ remarks: paymentAdvice.remarks });
      setInitialRemarks(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentAdvice?.remarks]);

  const debounceRemarks = React.useCallback(
    debounce(value => onChange(value), 200),
    [paymentAdvice],
  );

  const onValuesChange = (value: any) => {
    debounceRemarks({
      remarks: value.target.value,
      notes: editorValue,
    });
  };

  return (
    <Form form={form} className="create-pa__group">
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div
            className="text-ink text-bold create-pa__title"
            style={{ marginBottom: 20 }}
          >
            Remarks
          </div>
          <Form.Item name="remarks">
            <TextArea
              // disabled={disabled}
              onChange={onValuesChange}
              className="bg-white"
              rows={4}
            />
          </Form.Item>

          <Col span={24}>
            <div className="text-ink text-bold create-pa__title">Notes</div>
            <div className="editable-block">
              <div ref={textFieldRef} />
            </div>
          </Col>
        </Col>
        <Col span={12}>
          <div
            className="text-ink text-bold create-pa__title"
            style={{ marginBottom: 20 }}
          >
            Payment Summary
          </div>
          <div className="text-ink">
            <Row justify="space-between" gutter={[10, 10]}>
              <Col>Class Fee</Col>
              <Col className="text-bold">
                {(paymentAdvice.paymentSummaryStatic?.classFee ?? 0) < 0
                  ? formatCurrencyFullText(
                      paymentAdvice.paymentSummaryStatic?.classFee?.toString() ||
                        '0',
                      true,
                    )
                  : formatCurrencyFullText(
                      paymentAdvice.paymentSummaryStatic?.classFee?.toString() ||
                        '0',
                    )}
              </Col>
            </Row>

            <Row justify="space-between" gutter={[10, 10]}>
              <Col>Registration Fee</Col>
              <Col>
                {`${formatCurrencyFullText(
                  paymentAdvice.paymentSummaryStatic?.registrationFee?.toString() ||
                    '0',
                )}`}
              </Col>
            </Row>
            <Row justify="space-between" gutter={[10, 10]}>
              <Col>Other Payments</Col>
              <Col>
                {`${formatCurrencyFullText(
                  paymentAdvice.paymentSummaryStatic?.otherPayment?.toString() ||
                    '0',
                )}`}
              </Col>
            </Row>
            <Row justify="space-between" gutter={[10, 10]}>
              <Col>Deduction</Col>
              <Col>
                {`${formatCurrencyFullText(
                  paymentAdvice.paymentSummaryStatic?.deduction?.toString() ||
                    '0',
                  true,
                )}`}
              </Col>
            </Row>
            {!isNaN(paymentAdvice.paymentSummaryStatic?.deductedDeposit) && (
              <Row justify="space-between">
                <Col>Deduct Deposit</Col>
                <Col className="text-bold">
                  {`${formatCurrencyFullText(
                    paymentAdvice.paymentSummaryStatic?.deductedDeposit?.toString() ||
                      '0',
                    true,
                  )}`}
                </Col>
              </Row>
            )}
            <Row>
              <Col span={24}>
                <Divider className="indigo-divider-header"></Divider>
              </Col>
            </Row>
            <Row justify="space-between" gutter={[10, 10]}>
              <Col>Total Before GST</Col>
              <Col>
                {`${formatCurrencyFullText(
                  paymentAdvice.paymentSummaryStatic?.totalBeforeGst?.toString() ||
                    '0',
                  !!paymentAdvice.paymentSummaryStatic?.totalBeforeGst &&
                    paymentAdvice.paymentSummaryStatic?.totalBeforeGst < 0,
                )}`}
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>
                Total GST
                <Select
                  size="small"
                  style={{ paddingLeft: 20, width: 160 }}
                  value={paymentAdvice.gst || 0}
                  onChange={value => onChange({ gst: Number(value) })}
                >
                  {[
                    { value: 0, label: 'No GST' },
                    { value: 7, label: '7% GST' },
                    { value: 8, label: '8% GST' },
                    { value: 9, label: '9% GST' },
                  ].map((gst: any) => (
                    <Select.Option key={gst.value} value={gst.value}>
                      {gst.label}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col>
                {`${formatCurrencyFullText(
                  paymentAdvice.paymentSummaryStatic?.gst?.toString() || '0',
                  !!paymentAdvice.paymentSummaryStatic?.gst &&
                    paymentAdvice.paymentSummaryStatic?.gst < 0,
                )}`}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Divider className="indigo-divider-header"></Divider>
              </Col>
            </Row>
            <Row justify="space-between" gutter={[10, 10]}>
              <Col className="text-bold">Total After GST</Col>
              <Col className="text-bold">
                {`${formatCurrencyFullText(
                  paymentAdvice.paymentSummaryStatic?.totalAfterGst?.toString() ||
                    '0',
                  !!paymentAdvice.paymentSummaryStatic?.totalAfterGst &&
                    paymentAdvice.paymentSummaryStatic?.totalAfterGst < 0,
                )}`}
              </Col>
            </Row>
            <Row justify="space-between" gutter={[10, 10]}>
              <Col className="text-bold">Deposit</Col>
              <Col className="text-bold">
                {`${formatCurrencyFullText(
                  paymentAdvice.paymentSummaryStatic?.deposit?.toString() ||
                    '0',
                  (paymentAdvice.paymentSummaryStatic?.deposit || 0) < 0,
                )}`}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Divider className="indigo-divider-header"></Divider>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col className="text-bold" style={{ fontSize: 24 }}>
                Total Payable
              </Col>
              <Col className="text-bold" style={{ fontSize: 24 }}>
                {`${formatCurrencyFullText(
                  paymentAdvice.paymentSummaryStatic?.totalPayable?.toString() ||
                    '0',
                  (paymentAdvice.paymentSummaryStatic?.totalPayable || 0) < 0,
                )}`}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default Summary;
