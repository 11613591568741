import React, {
  FunctionComponent,
  createContext,
  useContext,
  useState,
} from 'react';

export interface ErrorBoundaryOptions {
  hasError: boolean;
  message: string;
}

const initialState: ErrorBoundaryOptions = {
  hasError: false,
  message: '',
};

const ErrorBoundaryContext = createContext<
  [ErrorBoundaryOptions, (ErrorBoundaryOptions: ErrorBoundaryOptions) => void]
>([initialState, () => {}]);

export const useErrorBoundary = () => {
  return useContext(ErrorBoundaryContext);
};

export const ErrorBoundaryProvider: FunctionComponent = ({ children }) => {
  const [errorBoundaryOptions, setErrorBoundaryOptions] = useState({
    ...initialState,
  } as ErrorBoundaryOptions);
  const defaultErrorBoundaryContext: [
    ErrorBoundaryOptions,
    typeof setErrorBoundaryOptions,
  ] = [errorBoundaryOptions, setErrorBoundaryOptions];
  return (
    <ErrorBoundaryContext.Provider value={defaultErrorBoundaryContext}>
      {children}
    </ErrorBoundaryContext.Provider>
  );
};
