export interface IRole {
  module: string;
  name: string;
  quantity: number;
}
export enum Roles {
  Admin = 'admin',
  FrontDesk = 'front_desk',
  OpsManager = 'ops_manager',
  HR = 'hr',
  FinanceStaff = 'finance_staff',
  FinanceManager = 'finance_manager',
  AttendanceChecker = 'attendance_checker',
  Sales = 'sales',
  Intern = 'intern',
  FulltimeTutor = 'fulltime_tutor',
  PartTimeTutor = 'parttime_tutor',
  HOD = 'hod',
  Student = 'student',
  Parent = 'parent',
  Tutor = 'tutor',
}
