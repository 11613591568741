import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'antd/dist/antd.less';
import '@assets/scss/_default.scss';
// import '@assets/scss/_spacing.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import Amplify, { AWS } from '@aws-amplify/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ApolloProvider } from '@apollo/client';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { message } from 'antd';
import { client } from '@services/graphql/Core';
import Core from '@core';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: true,
    },
  },
});

library.add(fab, fas, fad, fal, far);

function App(props: any) {
  // Amplify.configure({
  //   Auth: {
  //     // identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  //     region: process.env.REACT_APP_AWS_REGION,
  //     userPoolId: process.env.REACT_APP_USER_POOL_ID,
  //     userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
  //   },
  // });
  // AWS.config.update({
  //   credentials: new AWS.Credentials({
  //     accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID || '',
  //     secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || '',
  //   }),
  // });

  message.config({
    duration: 3,
    maxCount: 1,
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={client}>
        <section className="page-container">
          <Core />
        </section>
      </ApolloProvider>
    </QueryClientProvider>
  );
}

window.addEventListener('error', event => {
  if (event.message && event.message.includes('Loading chunk')) {
    window.location.reload();
  }
});

ReactDOM.render(<App />, document.getElementById('root'));
