import { Academic_Calendars } from '../services/data-access';
import { FileType } from '../helpers';

export interface ICurriculumFile {
  uid?: string;
  fileUrl: string;
  thumbUrl: string;
  name: string;
  type?: FileType;
}
export enum ProgramCategory {
  Special = 'special',
  Regular = 'regular',
  DoubleLesson = 'double_lesson',
  Others = 'others',
}

export enum ListClassStatus {
  Open = 'open',
  New = 'new',
  Close = 'closed',
}

export enum ProgramTypes {
  RegularWeekday = 'WLWD',
  RegularWeekend = 'WLWE',
  DoubleLessonWeekday = 'DBWD',
  DoubleLessonWeekend = 'DBWE',
}

export const ProgramCategoryOptions = [
  {
    value: ProgramCategory.Regular,
    label: 'Regular Programme',
    abbreviation: 'R',
  },
  {
    value: ProgramCategory.DoubleLesson,
    label: 'Double Lesson Programme',
    abbreviation: 'DB',
  },
  {
    value: ProgramCategory.Special,
    label: 'Special Programme',
    abbreviation: 'S',
  },
  {
    value: ProgramCategory.Others,
    label: 'Others Programme',
    abbreviation: 'O',
  },
];

export type CurriculumLevelPare = {
  category: string;
  curriculumId: string;
  levelId: string;
};
export function convertToCurriculumLevelPare(
  curriculums: Academic_Calendars[],
) {
  let results: CurriculumLevelPare[] = [];
  curriculums.forEach(e => {
    e.levels?.forEach(level => {
      results.push({
        category: e.category,
        curriculumId: e.curriculum.id,
        levelId: level.id,
      });
    });
  });

  return results;
}
