import React from 'react';

const CompanyList = React.lazy(
  () => import(/* webpackChunkName: "company-list" */ './List'),
);
const CompanyForm = React.lazy(
  () => import(/* webpackChunkName: "company-form" */ './Form'),
);

import { Roles } from '@models';

export default [
  {
    path: '/company',
    component: CompanyList,
    exact: true,
    title: 'Companies',
    isPublic: true,
    roles: [Roles.FinanceManager],
  },
  {
    path: '/company/create',
    component: CompanyForm,
    exact: true,
    title: 'Create Company',
    roles: [Roles.FinanceManager],
  },
  {
    path: '/company/:companyId/update',
    component: CompanyForm,
    exact: true,
    title: 'Edit Company',
    roles: [Roles.FinanceManager],
  },
];
