import { IAuthState } from './auth';
import { IGlobalState } from '../stores/global/reducer';
import { IUserState } from '../stores/user/reducer';

export enum ErrorCode {
  NotAuthorizedException = 'NotAuthorizedException',
  Unknown = 'unknown',
  NotFound = 'not_found',
  BadRequest = 'bad_request',
  PermissionDenied = 'permission_denined',
  NoConnection = 'no_connection',
}
export interface IState {
  router: any;
  auth: IAuthState;
  global: IGlobalState;
  user: IUserState;
}
export interface IErrorException {
  code?: ErrorCode;
  message?: string;
  nodes?: any;
}
export interface IBreadcrumb {
  title: string;
  href?: string;
}

export interface IApply {
  isApplied: boolean;
  isReset: boolean;
}
