import React, { useReducer } from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import { cloneDeep } from 'lodash';

import { Levels } from '../services/data-access';

export function joinArray<T>(
  joinBy: string,
  data: { [key: string]: T }[],
  selectedField: string,
): string {
  if (isNotNull(data)) {
    return data
      .map((value: any) => {
        return isNotNull(value[selectedField]) && value[selectedField];
      })
      .join(joinBy);
  }
  return '';
}

/**
 * Method is used to check not undefine and null
 * @param data data which need check input valid
 * @author hoangvo
 */
export function isNotNull<T>(data: T): boolean {
  if (data !== undefined && data !== null) {
    return true;
  }
  return false;
}

/**
 * Method is used to check undefine and null
 * @param data data which need check input valid
 * @author hoangvo
 */
export function isUndifineOrNull<T>(data: T): boolean {
  return !isNotNull(data);
}

/**
 * The method is used to transformation upper First letter
 * @author hoangvo
 */

export function capitalizeFirstLetter(str: string): string {
  if (isNotNull(str)) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return '';
}

/**
 * The method is used to get input file name and then validate or formart
 * @author hoangvo
 * @returns fileList type
 */

export function validationFileName(
  fileList: { [key: string]: any; name: string }[],
) {
  if (fileList.length > 0) {
    fileList.map((file: any) => {
      file.name = validateString(file.name);
      return file;
    });
  } else {
    return fileList;
  }
}

/**
 * The method is used to formart file's name
 * @author hoangvo
 */

function onFormatFileName(fileName: string): string {
  const limitedLenghtOfEleName = 20; // ' ' or '-', default is '-'
  const endString: number = limitedLenghtOfEleName - 3;
  return (
    fileName.slice(0, endString) +
    '-' +
    onRandomString(3) +
    '-' +
    fileName.substr(-endString)
  );
}

/**
 * The method is used to validate fileName
 * @author hoangvo
 */

export function validateString(fileName: string): string {
  // Validation somthing here
  return onFormatFileName(fileName);
}

/**
 * The method is used to random string a-zA-Z0-9
 * @author hoangvo
 */

function onRandomString(length: number): string {
  let result = '';
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

/**
 * This method is used to render Tag
 * @param checkedText text status need to check the style
 * @param isText as text parent that included checkedText
 * @param bg background color
 * @author hoangvo
 */
export function onRenderTag(
  checkedText: string,
  isText: string,
  bg: string,
  prefix = '',
): JSX.Element | undefined {
  if (checkedText && isText.split(' ').includes(checkedText)) {
    return (
      <Tag
        style={{
          borderRadius: 30,
          height: 24,
          textTransform: 'capitalize',
        }}
        color={bg}
      >
        {checkedText} {prefix}
      </Tag>
    );
  }
  return;
}
/**
 * this method is used to reducer data levels
 */
export function reducerDataLevels(data: any) {
  const dataTemp: { [id: string]: Levels[] } = {};
  const allLevels: { [id: string]: Levels } = {};
  // eslint-disable-next-line no-unused-expressions
  data?.curriculums_levels.forEach(
    (item: {
      curriculumId: string | number;
      level: {
        id: any;
        abbreviation?: string;
        createdAt?: any;
        createdBy?: any;
        name?: string;
        status?: string;
        updatedAt?: any;
        updatedBy?: any;
      };
    }) => {
      if (!dataTemp.hasOwnProperty(item.curriculumId)) {
        dataTemp[item.curriculumId] = [];
      }

      dataTemp[item.curriculumId].push(item.level as Levels);

      if (!allLevels.hasOwnProperty(item.level.id)) {
        allLevels[item.level.id] = item.level as Levels;
      }
    },
  );

  dataTemp['root'] = Object.values(allLevels).sort((a: any, b: any) =>
    a.name > b.name ? 1 : -1,
  );

  return dataTemp;
}
/**
 * this method is used to check if theres unasaved data in the form
 */

export function validateDataUnsaved(data: any) {
  var getall = Object.keys(data).map(key => {
    if (typeof data[key] == 'object') {
      if (!('number' in data[key])) {
        return undefined;
      }
    }
    return data[key];
  });

  let returnValue = false;

  for (let i = 0; getall.length > i; i++) {
    if (getall[i] !== undefined) {
      returnValue = true;
      break;
    } else {
      returnValue = false;
      continue;
    }
  }

  return returnValue;
}
//force Render update antd
export default function useForceUpdate() {
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  return forceUpdate;
}

export function convertToNumber(str: string) {
  if (!str) {
    return 0;
  }
  const newStr = str?.match(/[+-]?\d+(\.\d+)?/g)?.join('');
  return !isNaN(Number(newStr)) ? Number(newStr) : 0;
}

const A4inDesign = {
  h: [1240, 595.28],
  v: [1754, 841.89],
};

export function toPrintedPt(pxInDesign: number, direction: 'h' | 'v' = 'v') {
  const [px, mm] = A4inDesign[direction];
  return (((pxInDesign * 1.0) / px) * mm).toFixed(0);
}

export function renderUser(user: any) {
  if (!user) return '';
  return `${user.firstName} ${user.lastName}`;
}

export function sortClassInformation(arr: any = []) {
  return arr.sort((a: any, b: any) =>
    (a?.classType ?? '') < (b?.classType ?? '')
      ? 1
      : (b?.classType ?? '') < (a?.classType ?? '')
      ? -1
      : 0,
  );
}

export const convertSnakeCase = (textConvert: string = '') => {
  const convert = textConvert
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word: string) => word.toLowerCase())
    .join('_');

  let convertSnackCase = convert;
  if (convertSnackCase.lastIndexOf('_') === convertSnackCase.length - 1) {
    convertSnackCase = convertSnackCase.slice(0, -1);
  }

  if (convertSnackCase.indexOf('_') === 0) {
    convertSnackCase = convertSnackCase.slice(1);
  }
  return convertSnackCase;
};

export const flattenObject = (obj: any = {}, out: any = {}) => {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] == 'object') {
      out = flattenObject(obj[key], out);
    } else {
      out[key] = obj[key];
    }
  });
  return out;
};

export const convertExpiredDate = (date: string) => {
  if (!date) return '';
  const dateStr = date.replaceAll('/', '');
  const asciiNum: number[] = [];
  dateStr.split('').forEach((_, idx) => {
    if (dateStr.charCodeAt(idx) === 48) {
      asciiNum.push(74); // for case char = 0
    } else {
      asciiNum.push(dateStr.charCodeAt(idx) + 16);
    }
  });
  return String.fromCharCode(...asciiNum);
};

export const generateMonthYearFilter = (
  month: string | number,
  year: string | number,
  filterField = 'createdAt',
) => {
  return {
    ...(!!year &&
      !month && {
        [filterField]: {
          _gte: moment().format(`${year}-01-01 00:00:00`),
          _lte: moment().format(`${year}-12-31 23:59:59`),
        },
      }),
    ...(!!year &&
      !!month && {
        [filterField]: {
          _gte: moment(`${year}, ${month}`).format(`YYYY-MM-01 00:00:00`),
          _lte: moment(`${year}, ${month}`).format(
            `YYYY-MM-${moment(
              `${year}, ${month}`,
              'YYYY-MM',
            ).daysInMonth()} 23:59:59`,
          ),
        },
      }),
  };
};

export const deepCompareArrays = (arr1: any, arr2: any) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      if (obj1 !== obj2) {
        return false;
      }
    } else {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (let j = 0; j < keys1.length; j++) {
        const key = keys1[j];
        const val1 = obj1[key];
        const val2 = obj2[key];

        if (typeof val1 !== 'object' || typeof val2 !== 'object') {
          if (val1 !== val2) {
            return false;
          }
        } else if (!deepCompareArrays(val1, val2)) {
          return false;
        }
      }
    }
  }

  return true;
};

export const formatNumber = (numberString: any, decimalAmount = 2) => {
  const number = parseFloat(numberString);

  if (isNaN(number)) {
    return numberString;
  }

  return number.toFixed(decimalAmount);
};

export const sortCategories = (array: any) => {
  if (!array?.length) {
    return [];
  }

  const order: any = { regular: 0, double_lesson: 1, special: 2, others: 3 };

  const sortedPrograms = array.slice().sort((a: any, b: any) => {
    if (order[a?.code] < order[b?.code]) return -1;
    if (order[a?.code] > order[b?.code]) return 1;

    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;

    return 0;
  });

  return sortedPrograms;
};

export const sortPrograms = (array: any) => {
  if (!array?.length) {
    return [];
  }

  const order: any = { regular: 0, double_lesson: 1, special: 2, others: 3 };

  const sortedPrograms = array.slice().sort((a: any, b: any) => {
    if (order[a?.category] < order[b?.category]) return -1;
    if (order[a?.category] > order[b?.category]) return 1;

    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;

    return 0;
  });

  return sortedPrograms;
};

export const sortProgramTypes = (array: any) => {
  if (!array?.length) {
    return [];
  }

  const order: any = { regular: 0, double_lesson: 1, special: 2, others: 3 };

  const sortedProgramTypes = array.slice().sort((a: any, b: any) => {
    const aCategory = a?.cate?.code;
    const bCategory = b?.cate?.code;

    if (order[aCategory] < order[bCategory]) return -1;
    if (order[aCategory] > order[bCategory]) return 1;

    if (a.shortName < b.shortName) return -1;
    if (a.shortName > b.shortName) return 1;

    return 0;
  });

  return sortedProgramTypes;
};

export const sortByKey = (array: any, key: any, direction = 'asc') => {
  const newArr = cloneDeep(array) ?? [];

  newArr.sort((a: any, b: any) => {
    if (direction === 'desc') {
      if (typeof a[key] === 'string' && typeof b[key] === 'string') {
        return b[key].localeCompare(a[key]);
      } else {
        return b[key] - a[key];
      }
    } else {
      if (typeof a[key] === 'string' && typeof b[key] === 'string') {
        return a[key].localeCompare(b[key]);
      } else {
        return a[key] - b[key];
      }
    }
  });

  return newArr;
};

export const generatePDFTableStyles = () => ({
  table: {
    borderLeft: '1pt solid #000',
    borderRight: '1pt solid #000',
    borderBottom: '1pt solid #000',
  },
  tr: {
    borderTop: '1pt solid #000',
  },
  ['table:has(thead) thead tr']: {
    backgroundColor: '#f0f8ff',
  },
  ['table:not(:has(thead)) tr:first-child']: {
    backgroundColor: '#f0f8ff',
  },
  th: {
    borderLeft: '1pt solid #000',
  },
  td: {
    borderLeft: '1pt solid #000',
    display: 'flex',
    justifyContent: 'center',
  },
  a: {
    color: 'blue',
  },
  ['.text-ink']: {
    color: '#000',
  },
  ['.text-bold']: {
    fontWeight: 'bold',
  },
});
