import React from 'react';
import { useAuth } from './useAuth';
import { apiService } from '@services/api';
import SplashScreen from '@components/SplashScreen/SplashScreen';

export const withAuth = (Component: React.ComponentType<any>) => {
  return (props: any) => {
    const [{ id }, setAuth] = useAuth();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
      document.title = `Indigo ${
        process.env.NODE_ENV !== 'production' ? `(${process.env.NODE_ENV})` : ''
      }`;
      (async () => {
        try {
          if (!!localStorage.getItem('idg')) {
            setIsLoading(true);
            const { data: response, status } = await apiService.get(
              '/users/me',
            );
            if (status === 200) {
              setAuth({ ...(response.data || {}), isLoggedIn: true });
            }
            setIsLoading(false);
          }
        } catch (err) {
          setIsLoading(false);
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
      return <SplashScreen />;
    }

    return <Component {...props} />;
  };
};
