import React, { FunctionComponent } from 'react';

import { Users } from '../../services/data-access';

export interface StudentCardCreatePaymentAdviceProps {
  student: Users;
}
const StudentCardCreatePaymentAdvice: FunctionComponent<
  StudentCardCreatePaymentAdviceProps
> = ({ children, student }) => {
  return (
    <div>
      <div className="relative student-card">
        <div className="flex justify-space">
          <p className="text--bold text-ink">{`${student?.firstName} ${student?.lastName}`}</p>
        </div>
        <div className="flex justify-space">
          <p className="label">Username</p>
          <p className="text">{student?.username || '__'}</p>
        </div>
        <div className="flex justify-space">
          <p className="label">Email</p>
          <p className="text">{student?.email || '__'}</p>
        </div>
        <div className="flex justify-space">
          <p className="label">Mobile Phone</p>
          <p className="text">
            {student?.phone
              ? student?.phone?.code + ' ' + student?.phone?.number
              : '__'}
          </p>
        </div>
        <div className="flex justify-space">
          <p className="label">School</p>
          <p className="text" style={{ textAlign: 'right' }}>
            {student?.studentProfile?.school?.name || '__'}
          </p>
        </div>
        <div className="flex justify-space">{children} </div>
      </div>
    </div>
  );
};

export default StudentCardCreatePaymentAdvice;
