import React, { Fragment } from 'react';
import { Col, Row } from 'antd';

import { parseUTCToTime } from '../../../helpers';

import './style.scss';

type Props = {
  detail?: any;
  renderAction?: JSX.Element;
  title?: string;
};

export const PALogMetadata = ({ detail, renderAction, title }: Props) => {
  return (
    <Fragment>
      <div className="pa-log-meta">
        <h2>{title ? title : 'Payment Advice History Log'}</h2>
        <Row gutter={[20, 10]}>
          <Col span={6}>
            <p className="text-bold">User</p>
            <p className="text">{detail?.creator?.fullName}</p>
            <p className="label">{detail?.creator?.role}</p>
          </Col>
          <Col span={6}>
            <p className="text-bold">Timestamp</p>
            <p className="text">
              {parseUTCToTime(detail?.updatedAt, 'hh:mm A')}
            </p>
            <p className="text">
              {parseUTCToTime(detail?.updatedAt, 'DD/MM/YYYY')}
            </p>
          </Col>
          <Col span={12}>
            <p className="text-bold">Action</p>
            <div className="text">{renderAction}</div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};
