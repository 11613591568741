import React from 'react';

import { Roles } from '@models';

const CurriculumLevel = React.lazy(
  () =>
    import(
      /* webpackChunkName: "curriculum-level" */ './curriculum-level/CurriculumLevel'
    ),
);
const CurriculumLevelCreate = React.lazy(
  () =>
    import(
      /* webpackChunkName: "curriculum-level-create" */ './curriculum-level/CreateCurriculumLevel'
    ),
);
const AcademicTermCreateEdit = React.lazy(
  () =>
    import(
      /* webpackChunkName: "academic-term-create-edit" */ './academic-term/view/AcademicTermCreateEdit'
    ),
);
const AcademicTerm = React.lazy(
  () =>
    import(
      /* webpackChunkName: "academic-term" */ './academic-term/AcademicTerm'
    ),
);
const CategoriesType = React.lazy(
  () =>
    import(
      /* webpackChunkName: "categories-type" */ './categories-type/CategoriesType'
    ),
);
const ProgramList = React.lazy(
  () => import(/* webpackChunkName: "program-list" */ './list/ProgramList'),
);
const ProgramCreate = React.lazy(
  () => import(/* webpackChunkName: "program-create" */ './view/ProgramCreate'),
);
const ProgrammeDetails = React.lazy(
  () =>
    import(
      /* webpackChunkName: "program-details" */ './programme-details/ProgrammeDetails'
    ),
);

const Holidays = React.lazy(
  () => import('@pages/programme/holiday-setup/list'),
);
const HolidayForm = React.lazy(
  () => import('@pages/programme/holiday-setup/view/HolidayForm'),
);

export default [
  // START curriculum-and-level
  {
    path: '/programme/curriculum-level',
    component: CurriculumLevel,
    exact: true,
    title: 'Curriculum & Level',
    isPublic: true,
    roles: [Roles.OpsManager],
  },
  {
    path: '/programme/curriculum-level/create',
    component: CurriculumLevelCreate,
    exact: true,
    title: 'Create Curriculum & Level',
    roles: [Roles.OpsManager],
  },
  // END curriculum-and-level
  {
    path: '/programme/categories-type',
    component: CategoriesType,
    exact: true,
    title: 'Categories & Type',
    isPublic: true,
    roles: [Roles.OpsManager],
  },
  {
    path: '/programme/academic-term',
    component: AcademicTerm,
    exact: true,
    title: 'Academic & Term',
    isPublic: true,
    roles: [Roles.OpsManager],
  },
  {
    path: '/programme/academic-term/create',
    component: AcademicTermCreateEdit,
    exact: true,
    title: 'Create Academic & Term',
    isPublic: true,
    roles: [Roles.OpsManager],
  },
  {
    path: '/programme/academic-term/:id/edit',
    component: AcademicTermCreateEdit,
    exact: true,
    title: 'Edit Academic & Term',
    isPublic: true,
    roles: [Roles.OpsManager],
  },
  {
    path: '/programme/management',
    component: ProgramList,
    exact: true,
    title: 'Programme List',
    isPublic: true,
    roles: [Roles.OpsManager],
  },
  {
    path: '/programme/management/create',
    component: ProgramCreate,
    exact: true,
    title: 'Create Programme',
    isPublic: true,
    roles: [Roles.OpsManager],
  },
  {
    path: '/programme/management/:id/view',
    component: ProgrammeDetails,
    exact: true,
    title: 'Programme Details',
    isPublic: true,
    roles: [Roles.OpsManager],
  },
  {
    path: '/programme/holiday',
    component: Holidays,
    exact: true,
    title: 'Holiday Setup',
    isPublic: true,
    roles: [],
  },
  {
    path: '/programme/holiday/create',
    component: HolidayForm,
    exact: true,
    title: 'Create Holiday',
    isPublic: true,
    roles: [],
  },
  {
    path: '/programme/holiday/:id/update',
    component: HolidayForm,
    exact: true,
    title: 'Holiday Setup Detail',
    isPublic: true,
    roles: [],
  },
  // {
  //   path: '/programme/management/edit/:id',
  //   component: ProgramCreate,
  //   exact: true,
  //   title: 'Programme Edit',
  //   isPublic: true,
  //   roles: [Roles.OpsManager],
  // },
];
