import './create-payment-advice-session.scss';

import React, { FunctionComponent } from 'react';
import SessionHeader, { SessionHeaderProps } from './SessionHeader';

export interface CreatePaymentAdviceSessionProps extends SessionHeaderProps {
  displaySelection?: boolean;
}

const CreatePaymentAdviceSession: FunctionComponent<
  CreatePaymentAdviceSessionProps
> = ({ title, children, subTitle, displaySelection = false }) => {
  return (
    <div className="create-payment-advice-session">
      <SessionHeader
        displaySelection={displaySelection}
        title={title}
        subTitle={subTitle}
      />
      <div className="body">{children}</div>
    </div>
  );
};

export default CreatePaymentAdviceSession;
