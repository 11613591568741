import React from 'react';

import { Roles } from '@models';

const BranchList = React.lazy(
  () => import(/* webpackChunkName: "branch-list" */ './list/BranchList'),
);
const BranchCreateEdit = React.lazy(
  () =>
    import(
      /* webpackChunkName: "branch-create-edit" */ './view/BranchCreateEdit'
    ),
);
const ClassRoomList = React.lazy(
  () =>
    import(
      /* webpackChunkName: "classroom-list" */ './class-room/list/ClassRoomList'
    ),
);
const ClassRoomCreateEdit = React.lazy(
  () =>
    import(
      /* webpackChunkName: "classroom-create-edit" */ './class-room/view/ClassRoomCreateEdit'
    ),
);

export default [
  {
    path: '/branch',
    component: BranchList,
    exact: true,
    title: 'Branch Management',
    isPublic: true,
    roles: [Roles.OpsManager],
  },
  {
    path: '/branch/create',
    component: BranchCreateEdit,
    exact: true,
    title: 'Create Branch',
    isPublic: true,
    roles: [Roles.OpsManager],
  },
  {
    path: '/branch/:branchId/edit',
    component: BranchCreateEdit,
    exact: true,
    title: 'Edit Branch',
    isPublic: true,
    roles: [Roles.OpsManager],
  },
  {
    path: '/branch/:branchId/detail',
    component: ClassRoomList,
    exact: true,
    title: 'Branch Details',
    isPublic: true,
    roles: [Roles.OpsManager],
  },
  {
    path: '/branch/:branchId/detail/class-room/add-new',
    component: ClassRoomCreateEdit,
    exact: true,
    title: 'Create Classroom',
    isPublic: true,
    roles: [Roles.OpsManager],
  },
  {
    path: '/branch/:branchId/detail/class-room/:id/edit',
    component: ClassRoomCreateEdit,
    exact: true,
    title: 'Edit Classroom',
    isPublic: true,
    roles: [Roles.OpsManager],
  },
];
