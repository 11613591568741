import { AutoComplete, Avatar, Col, Input, Row } from 'antd';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { StatusCode, UserRole } from '../../../../../models';
import {
  Users,
  useFilterUserWithPaginationQuery,
  useGetStudentDetailLazyQuery,
} from '../../../../../services/data-access';

import { CreditNoteFormItemProps } from './CreditNoteFormItemProps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StudentCardCreatePaymentAdvice from '../../../../../components/StudentCardCreatePaymentAdvice/StudentCardCreatePaymentAdvice';

export interface CreditNoteStudentFormItemProps
  extends CreditNoteFormItemProps {
  onChanged?: (studentId: string) => void;
  studentId?: any;
}

const CreditNoteStudentFormItem: FunctionComponent<
  CreditNoteStudentFormItemProps
> = ({
  creditNoteData,
  creditNoteStatus,
  creditNoteType,
  disabled,
  formName,
  formInstance,
  onChanged,
  studentId,
}) => {
  const [searchReferralString, setSearchReferralString] = useState('');
  const [selectedReferralDetail, setSelectedReferralDetail] = useState<Users[]>(
    [],
  );

  const [getStudent, { fetchMore: getStudentDetail, called }] =
    useGetStudentDetailLazyQuery({
      onCompleted: result => {
        if (result) {
          setSelectedReferralDetail([result.users_by_pk as Users]);
        }
      },
    });

  useEffect(() => {
    if (creditNoteData && creditNoteData.studentId) {
      if (called) {
        getStudentDetail({
          variables: {
            id: creditNoteData.studentId || '',
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          },
        });
      } else {
        getStudent({
          variables: {
            id: creditNoteData.studentId,
          },
        });
      }
    }
  }, [called, getStudent, getStudentDetail, creditNoteData]);

  const { data: studentsReferral, fetchMore: fetchMoreStudentsReferral } =
    useFilterUserWithPaginationQuery({
      variables: {
        offset: 0,
        limit: 20,
        filter: {
          status: {
            _neq: StatusCode.Deleted,
          },
          role: {
            _eq: UserRole.Student,
          },
        },
      },
      onError: () => {},
    });

  useEffect(() => {
    fetchMoreStudentsReferral({
      variables: {
        offset: 0,
        limit: 20,
        filter: {
          _and: [
            {
              status: {
                _neq: StatusCode.Deleted,
              },
            },
            {
              status: {
                _neq: 'draft',
              },
            },
          ],
          role: {
            _eq: UserRole.Student,
          },
          _or: [
            {
              username: {
                _ilike: `%${searchReferralString}%`,
              },
            },
            {
              fullName: {
                _ilike: `%${searchReferralString}%`,
              },
            },
          ],
          id: {
            _nin: selectedReferralDetail.map(e => e.id),
          },
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });
  }, [searchReferralString, selectedReferralDetail, fetchMoreStudentsReferral]);

  const renderStudentItem = (scope: Users) => {
    return {
      value: scope.id,
      label: (
        <div className="relative student-option-item">
          <Avatar
            size={28}
            style={{
              position: 'absolute',
              top: 'calc(50% - 14px)',
              left: 0,
            }}
            src={scope.avatar || undefined}
            icon={<FontAwesomeIcon icon={['far', 'user']} />}
          />
          <p>{`${scope.firstName} ${scope.lastName} - ${scope.username} - ${
            scope.studentProfile?.school?.name || '__'
          } - ${scope.studentProfile?.curriculum?.abbreviation || '__'} - ${
            scope.studentProfile?.level?.abbreviation || '__'
          }`}</p>
        </div>
      ),
    };
  };

  const selectReferral = useCallback(
    (value: string) => {
      const _student = studentsReferral?.users.find(e => e.id === value);
      if (_student) {
        setSearchReferralString('');
        setSelectedReferralDetail([_student as Users]);
        if (onChanged) {
          onChanged(_student.id);
        }
      }
    },
    [studentsReferral, onChanged],
  );

  const getStudentData = useCallback(async () => {
    await getStudent({
      variables: {
        id: studentId,
      },
    });
  }, [getStudent, studentId]);

  useEffect(() => {
    if (studentId) {
      getStudentData();
      selectReferral(studentId);
    }
  }, [studentId, getStudentData, selectReferral]);

  return (
    <div>
      <p className="text--large">Student Information</p>
      {disabled ? null : (
        <AutoComplete
          dropdownClassName="certain-category-search-dropdown"
          dropdownMatchSelectWidth={true}
          style={{ width: '100%', marginTop: 20, marginBottom: 20 }}
          value={searchReferralString}
          onSearch={inputValue => setSearchReferralString(inputValue)}
          onSelect={value => selectReferral(value)}
          options={studentsReferral?.users.map(student =>
            renderStudentItem(student as any),
          )}
          disabled={disabled}
        >
          <Input
            prefix={<FontAwesomeIcon icon={['fal', 'search']} />}
            size="large"
            placeholder="Search by student name or username"
          />
        </AutoComplete>
      )}

      {selectedReferralDetail.length <= 0 ? (
        <div className="text-small text-danger">Please select student!</div>
      ) : null}

      <Row gutter={[20, 20]}>
        <Col>
          {selectedReferralDetail.length > 0 ? (
            <StudentCardCreatePaymentAdvice
              student={selectedReferralDetail[0]}
            ></StudentCardCreatePaymentAdvice>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default CreditNoteStudentFormItem;
