import moment from 'moment-timezone';

export function capitalizeFLetter(s: string): string {
  if (!s) {
    return '';
  }
  // let _s: any = s;

  return `${s.charAt(0).toUpperCase()}${s.slice(1)}`;
}
export const FORMAT_TIME_GLOBAL = 'YYYY-MM-DD';
export const FORMAT_SHORT_TIME_GLOBAL = 'HH:mm:ss';

export function formatCurrency(n: any, isUnit?: boolean): string {
  if (n === 0) {
    return `0`;
  }
  if (!n) {
    return '';
  }
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function convertToUTCWithFormat(
  time: string,
  format = 'YYYY-MM-DD HH:mm:ss',
) {
  if (!time) {
    return '';
  }

  return moment.tz(time, 'Asia/Singapore').format(format);
}
export function convertToUTCSuspension(
  time: string,
  format = 'YYYY-MM-DD HH:mm:ss',
) {
  if (!time) {
    return '';
  }

  return moment(time).utcOffset(0).add(1, 'seconds').format(format);
}
export function parseUTCToTime(time: string, format = 'HH:mm, DD/MM/YYYY') {
  // const timeZone = moment.tz.guess();
  return moment(time).utc().tz('Asia/Singapore').format(format);
}
export function parseCurrencyToValue(
  text: string = '',
  isNumber: boolean = false,
): string | number {
  text = text?.toString();
  if (!text || !text.trim()) {
    return '';
  }
  const result = text.replace(/[-,$S]/gi, '');

  if (isNumber) {
    return Number(result);
  }

  return result;
}

export function formatDate(value: any, format = 'DD MMM YYYY HH:mm') {
  if (!value) {
    return null;
  }
  let dateValue: any;
  if (value instanceof Date) {
    dateValue = value;
  } else {
    dateValue = new Date(value);
    if (isNaN(dateValue)) {
      return null;
    }
  }
  return moment(dateValue).format(format);
}

export function formatCurrencyFullText(
  text: string,
  negative?: boolean,
  unit = 'S$',
) {
  if (!text || typeof text !== 'string') {
    return '';
  }

  text = text.toString();
  if (text.indexOf('.') >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    var decimal_pos = text.indexOf('.');

    // split number by decimal point
    var left_side = text.substring(0, decimal_pos);
    var right_side = text.substring(decimal_pos);

    // add commas to left side of number
    left_side = formatCurrency(left_side);

    // validate right side
    right_side = formatCurrency(right_side);

    // On blur make sure 2 numbers after decimal
    right_side += '00';

    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, 2);

    // join number by .
    text = `${negative ? '-' : ''}${unit}${left_side}.${right_side}`;
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    text = formatCurrency(text);
    text = `${negative ? '-' : ''}${unit}${text}`;

    // final formatting
    text += '.00';
  }

  return text;
}

export function msToTime(s: number) {
  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;

  return `${mins < 10 ? `0${mins}` : `${mins}`}:${
    secs < 10 ? `0${secs}` : secs
  }`;
}

export function removeStyleAttributes(html: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const elements = doc.querySelectorAll('[style]');
  for (let i = 0; i < elements.length; i++) {
    elements[i].removeAttribute('style');
  }
  return doc.documentElement.innerHTML;
}

export function getContentBetweenTags(htmlString: string, tagName: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const elements = doc.getElementsByTagName(tagName);
  const content = [];

  for (let element of elements) {
    content.push(element.outerHTML);
  }

  return content;
}

export function getContentAndRemoveTag(
  htmlString: string,
  tagName: string,
): [string, string] {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const elements: any = doc.getElementsByTagName(tagName);

  if (elements.length === 0) {
    return [htmlString, ''];
  }

  const content = elements[0].outerHTML;
  elements[0].parentNode.removeChild(elements[0]);
  const remainingHtml = doc.documentElement.innerHTML;

  return [content, remainingHtml];
}

export function addCustomAttributes(
  htmlString: string,
  tagName: string,
  attributes: Record<string, string>,
) {
  const parser = new DOMParser();
  const dom = parser.parseFromString(htmlString, 'text/html');
  const oldTag: any = dom.getElementsByTagName(tagName)[0];

  const newTag: any = dom.createElement(tagName);
  newTag.href = oldTag.href;
  newTag.textContent = oldTag.textContent;

  for (const [name, value] of Object.entries(attributes)) {
    newTag.setAttribute(name, value);
  }

  oldTag.parentNode.replaceChild(newTag, oldTag);

  return dom.documentElement.getElementsByTagName('body')[0].innerHTML;
}

export function limitToOneNewline(str: string) {
  const regex = /http\S+/g;
  let text = str.replace(/\n{2,}/g, '\n');
  const matches = text.match(regex);

  if (matches) {
    matches.forEach(match => {
      const startIndex = text.indexOf(match);
      const endIndex = startIndex + 60;
      const transformedLink = match.slice(0, 60) + ' ' + match.slice(60);
      text =
        text.slice(0, startIndex) +
        transformedLink +
        text.slice(startIndex + match.length);
    });
  }

  return text;
}
