import { Button, Col, Form, Input, Layout, Row, Typography } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  convertToUTCWithFormat,
  convertToNumber,
  formatCurrencyFullText,
  parseCurrencyToValue,
} from '@helpers';
import { CreatePaymentAdviceFormSession } from '../interface/CreatePaymentAdviceFormSession';
import CurrencyInput from '@components/CurrencyInput/component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '@ant-design/icons/lib/components/Icon';
import { debounce } from 'lodash';
import moment from 'moment-timezone';
import { useGetSystemSettingsQuery } from '@services/data-access';

interface DeductionProps extends CreatePaymentAdviceFormSession {
  disabledButton?: boolean;
}

const Deduction: FunctionComponent<DeductionProps> = ({
  onChange,
  disabled,
  paymentAdvice,
  ...props
}) => {
  const formName = 'deductions';
  const [waivePeriod, setWaivePeriod] = useState({
    startDate: '',
    endDate: '',
  });
  const [callField, setCallFiled] = useState(true);
  const [form] = Form.useForm();
  const updateForm = async () => {
    setCallFiled(false);
    // const currentDeduction = form.getFieldsValue(['content']);
    // const removeFieldNotValue = currentDeduction.content.filter(
    //   (e: any) => !!e.name && !!e.amount,
    // );

    // onChange({
    //   [formName]: removeFieldNotValue.map((e: any) => {
    //     return {
    //       name: e?.name?.trim() ?? '',
    //       amount: parseCurrencyToValue(e.amount),
    //     };
    //   }),
    // });
  };
  const debouncedCallApi = debounce(updateForm, 500);
  const debounceFn = React.useCallback(debounce(onChange, 450), []);

  useGetSystemSettingsQuery({
    variables: {
      filter: {
        type: {
          _eq: 'fee',
        },
      },
    },
    onCompleted: result => {
      const waiverPeriod = result.system_settings.find(
        e => e.key === 'waivePeriod',
      );
      const timeRange = waiverPeriod?.value
        ? JSON.parse(waiverPeriod?.value)
        : {};
      const startDate = timeRange.startDate
        ? convertToUTCWithFormat(timeRange.startDate, 'DD/MM/YYYY')
        : '';
      const endDate = timeRange.endDate
        ? convertToUTCWithFormat(timeRange.endDate, 'DD/MM/YYYY')
        : '';
      setWaivePeriod({
        startDate,
        endDate,
      });
    },
    onError: () => {},
  });

  const onBlurDeduction = async (index: number) => {
    const valid = await form.validateFields();
    if (valid) {
      debouncedCallApi();
    }
  };

  const handleRemove = (index: number, remove: (i: number) => void) => {
    remove(index);
    debouncedCallApi();

    let deduction: { error: string | null; rows: any[] } = {
      error: null,
      rows: [],
    };
    const currentDeduction = form.getFieldsValue(['content']);
    currentDeduction.content.forEach((e: any) => {
      if (!!e.name && !!e.amount) {
        deduction.rows.push({
          name: e.name,
          amount: convertToNumber(e.amount.toString()),
        });
      }
    });
    debounceFn(deduction);
  };

  const isWaiveDisabled = () => {
    const { startDate, endDate } = waivePeriod;
    let issueDate: any = paymentAdvice.issueDate;
    if (!issueDate) {
      return true;
    }
    issueDate = moment(moment(issueDate).format('DD/MM/YYYY'), 'DD/MM/YYYY');

    return (
      moment(startDate, 'DD/MM/YYYY').isSameOrBefore(issueDate) &&
      moment(endDate, 'DD/MM/YYYY').isSameOrAfter(issueDate)
    );
  };

  useEffect(() => {
    if (callField && !!paymentAdvice?.data?.length) {
      form.setFieldsValue({
        content: paymentAdvice?.data || [],
      });
    }
  }, [callField, form, paymentAdvice]);

  const onFieldChanged = () => {
    form
      .validateFields()
      .then(res => console.log({ res }))
      .catch(error => {
        const { errorFields, values } = error;
        let deduction: { error: string | null; rows: any[] } = {
          error: null,
          rows: [],
        };
        deduction.error = errorFields?.length
          ? `Deduction ${errorFields[0]?.errors?.[0]}`
          : null;
        values.content.forEach((e: any) => {
          if (!!e.name && !!e.amount) {
            deduction.rows.push({
              name: e.name,
              amount: convertToNumber(e.amount.toString()),
            });
          }
        });
        debounceFn(deduction);
      });
  };

  return (
    <div>
      <Row justify="space-between">
        <p className="title" style={{ padding: 0 }}>
          Deduction
        </p>
        {/* <p className="create-pa__title">(Total: -S${paymentAdvice.summary?.deduction || '00.00'})</p> */}
        <p className="title" style={{ padding: 0 }}>
          (Total:{' '}
          {formatCurrencyFullText(
            paymentAdvice?.total?.toString() || '0',
            true,
          )}
          )
        </p>
      </Row>

      <div>
        {paymentAdvice?.isRegistrationFee && isWaiveDisabled() && (
          <Row
            justify="space-between"
            gutter={[20, 0]}
            align="top"
            style={{ marginTop: 0, marginBottom: 20 }}
          >
            <Col span={11}>
              <Typography.Text>(*) Deduction</Typography.Text>
              <Input disabled={true} value={'Waived Registration'} />
            </Col>
            <Col span={13}>
              <Typography.Text>(*) Amount</Typography.Text>
              <CurrencyInput
                negative={true}
                disabled={true}
                value={paymentAdvice.summary?.registrationFee?.toString()}
              />
            </Col>
          </Row>
        )}
        <Form
          form={form}
          name={formName}
          layout="vertical"
          onValuesChange={onFieldChanged}
        >
          <Form.List name="content">
            {(fields, { add, remove }) => (
              <div>
                {fields &&
                  fields.map((filed, index) => (
                    <Row
                      justify="space-between"
                      gutter={[20, 0]}
                      align="top"
                      style={{
                        marginTop: index > 0 ? 20 : 0,
                        marginBottom: 20,
                      }}
                      key={index}
                    >
                      <Col span={11}>
                        <Form.Item
                          label="(*) Name"
                          rules={[
                            { required: true, message: 'Name is required.' },
                          ]}
                          style={{ marginBottom: 0 }}
                          name={[index, 'name']}
                        >
                          <Input
                            disabled={disabled}
                            placeholder="Fill in deduction name"
                            onBlur={e => onBlurDeduction(index)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          label="(*) Amount"
                          rules={[
                            { required: true, message: 'Amount is required.' },
                          ]}
                          style={{ marginBottom: 0 }}
                          name={[index, 'amount']}
                        >
                          <CurrencyInput
                            disabled={disabled}
                            negative={true}
                            placeholder="Fill in amount"
                            onBlur={e => onBlurDeduction(index)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        {disabled ? null : (
                          <Button
                            type="link"
                            disabled={props.disabledButton}
                            onClick={() => {
                              handleRemove(index, remove);
                            }}
                            className="width-auto"
                            style={{
                              color: '#f54f64',
                              padding: 0,
                              margin: '30px auto 0',
                              display: 'block',
                            }}
                            icon={
                              <Icon viewBox={'0, 0, 1024, 1024'}>
                                <FontAwesomeIcon icon={['far', 'trash-alt']} />
                              </Icon>
                            }
                          ></Button>
                        )}
                      </Col>
                    </Row>
                  ))}
                {!disabled && (
                  <Button
                    type="primary"
                    ghost
                    style={{
                      width: '100%',
                      marginTop: 0,
                    }}
                    disabled={props.disabledButton}
                    htmlType="button"
                    onClick={add}
                    icon={
                      <Icon viewBox={'0, 0, 1024, 1024'}>
                        <FontAwesomeIcon icon={['fal', 'plus']} />
                      </Icon>
                    }
                  >
                    Add Deduction Item
                  </Button>
                )}
              </div>
            )}
          </Form.List>
        </Form>
      </div>
    </div>
  );
};

export default Deduction;
