import {
  Credit_Notes,
  useGetCreditNotesLazyQuery,
} from '../../../../../services/data-access';
import React, { FunctionComponent, useEffect } from 'react';

import { ColumnsType } from 'antd/lib/table';
import { CreatePaymentAdviceFormSession } from '../interface/CreatePaymentAdviceFormSession';
import { CreditNoteStatus } from '../../../../../models/credit-note';
import { CustomTable } from '../../../../../components/CustomTable';
import { Row } from 'antd';
import { formatCurrencyFullText } from '../../../../../helpers';

interface CreditNoteProps extends CreatePaymentAdviceFormSession {}

const CreditNote: FunctionComponent<CreditNoteProps> = ({
  onChange,
  paymentAdvice,
}) => {
  const [getCreditNote, { data, loading, fetchMore, called }] =
    useGetCreditNotesLazyQuery({
      onError: () => {},
    });

  useEffect(() => {
    if (paymentAdvice.student?.id) {
      if (called) {
        fetchMore({
          variables: {
            where: {
              creditNoteStatus: {
                _eq: CreditNoteStatus.Issued,
              },
              student: {
                id: {
                  _eq: paymentAdvice.student?.id,
                },
              },
            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          },
        });
      } else {
        getCreditNote({
          variables: {
            where: {
              creditNoteStatus: {
                _eq: CreditNoteStatus.Issued,
              },
              student: {
                id: {
                  _eq: paymentAdvice.student?.id,
                },
              },
            },
          },
        });
      }
    }
  }, [called, fetchMore, getCreditNote, paymentAdvice.student]);

  const columns: ColumnsType<Credit_Notes> = [
    {
      title: 'Credit Note',
      dataIndex: 'name',
      render: (text: string, record: Credit_Notes, index: number) => (
        <span className="credit-note-text">{text}</span>
      ),
    },
    {
      title: 'Amount',
      render: (text: string, record: Credit_Notes, index: number) => (
        <span className="credit-note-text">
          {formatCurrencyFullText(record.summary.total.toString() || '0', true)}
        </span>
      ),
    },
  ];
  return (
    <div className="create-pa__group">
      <Row justify="space-between" style={{ marginBottom: 20 }}>
        <p className="create-pa__title">Credit Note</p>
      </Row>
      <div
        className="create-pa__content table-container"
        style={{ padding: 0 }}
      >
        <CustomTable
          bordered={false}
          loading={loading}
          rowClassName="table-row-light"
          rowKey={record => record.id}
          columns={columns}
          dataSource={(data?.credit_notes as Credit_Notes[]) || []}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default CreditNote;
