import {
  IGetClassInformationForRefundReq,
  IChangeRefundStatusReq,
  ICreateRefundReq,
  IUpdateRefundReq,
} from '@interfaces/network';
import { apiService } from '@services/api';

const getClassInformationForRefund = async (
  request: IGetClassInformationForRefundReq,
) => await apiService.post(`/classes/get-class-infor-for-refund`, request);

const getReceiptOfClass = async (studentId: string, classId: string) =>
  apiService.get(
    `/refund/get-receipt-of-class?studentId=${studentId}&classId=${classId}`,
  );

const getRefund = async (id: string) => apiService.get(`/refund/${id}`);

const createRefund = async (request: ICreateRefundReq) =>
  apiService.post(`/refund`, request);

const updateRefund = async (id: string, request: IUpdateRefundReq) =>
  apiService.patch(`/refund/${id}`, request);

const changeRefundStatus = async (
  id: string,
  request: IChangeRefundStatusReq,
) => await apiService.patch(`/refund/${id}/change-refund-status`, request);

export {
  getRefund,
  getClassInformationForRefund,
  getReceiptOfClass,
  createRefund,
  updateRefund,
  changeRefundStatus,
};
