import React, { ElementType } from 'react';
import { Text, pdf } from '@react-pdf/renderer';
import FileSaver from 'file-saver';
import ReactHtmlParser from 'react-html-parser';
import styles from '../components/ReportLayout/styles';

export const downloadReport = async (
  Component: ElementType,
  filename: string,
  props: any,
) => {
  return FileSaver.saveAs(
    await pdf(<Component {...props} />).toBlob(),
    filename,
  );
};

export const htmlParser = (taskDescription: string | null) => {
  let returnContentConst: any = [];
  let totalString = 0;
  if (taskDescription) {
    const parsedHtml = ReactHtmlParser(taskDescription);
    parsedHtml.forEach(element => {
      element.props.children.forEach((content: string | any) => {
        const type = content.type ?? 'string';

        if ((taskDescription ?? '')?.match('<br>')) {
          if (taskDescription.includes('<p><br></p>')) {
            switch (type) {
              case 'string':
                returnContentConst = [
                  ...returnContentConst,
                  <Text>{content + '\n'}</Text>,
                ];
                break;
              case 'p':
                returnContentConst = [
                  ...returnContentConst,
                  <Text>{content}</Text>,
                ];
                break;
              case 'span':
              case 'strong':
                (content?.props?.children ?? []).forEach((item: any) => {
                  returnContentConst = [
                    ...returnContentConst,
                    <Text style={styles.titleMedium}>{item}</Text>,
                  ];
                });
                break;
              case 'br':
                returnContentConst = [...returnContentConst, '\n'];
                break;
              case 'a':
                returnContentConst = [
                  ...returnContentConst,
                  <Text style={styles.link}>{content.props.children[0]}</Text>,
                ];
                break;
              default:
                (content.props.children ?? []).forEach((item: any) => {
                  returnContentConst = [
                    ...returnContentConst,
                    <Text>{item}</Text>,
                  ];
                });
                break;
            }
          } else {
            switch (type) {
              case 'string':
                returnContentConst = [
                  ...returnContentConst,
                  <Text>{content.replace('\n', '')}</Text>,
                ];
                break;
              case 'p':
                returnContentConst = [
                  ...returnContentConst,
                  <Text>{content}</Text>,
                ];
                break;
              case 'span':
              case 'strong':
                (content?.props?.children ?? []).forEach((item: any) => {
                  returnContentConst = [
                    ...returnContentConst,
                    <Text style={styles.titleMedium}>{item}</Text>,
                  ];
                });
                break;
              case 'br':
                returnContentConst = [...returnContentConst, '\n'];
                break;
              case 'a':
                returnContentConst = [
                  ...returnContentConst,
                  <Text style={styles.link}>{content.props.children[0]}</Text>,
                ];
                break;
              default:
                (content.props.children ?? []).forEach((item: any) => {
                  returnContentConst = [
                    ...returnContentConst,
                    <Text>{item}</Text>,
                  ];
                });
                break;
            }
          }
        }

        if (
          !taskDescription?.match('<br>') &&
          !taskDescription?.match('<strong>')
        ) {
          switch (type) {
            case 'string':
              returnContentConst = [
                ...returnContentConst,
                <Text>{content + '\n'}</Text>,
              ];
              break;
            case 'p':
              returnContentConst = [
                ...returnContentConst,
                <Text>{content}</Text>,
              ];
              break;
            case 'span':
              (content?.props?.children ?? []).forEach((item: any) => {
                returnContentConst = [
                  ...returnContentConst,
                  <Text style={styles.titleMedium}>{item}</Text>,
                ];
              });
              break;
            case 'strong':
              (content?.props?.children ?? []).forEach((item: any) => {
                returnContentConst = [
                  ...returnContentConst,
                  <Text style={styles.titleMedium}>
                    {item.replace('\n', '')}
                  </Text>,
                ];
              });
              break;
            case 'br':
              returnContentConst = [...returnContentConst, '\n'];
              break;
            case 'a':
              returnContentConst = [
                ...returnContentConst,
                <Text style={styles.link}>{content.props.children[0]}</Text>,
              ];
              break;
            default:
              (content.props.children ?? []).forEach((item: any) => {
                returnContentConst = [
                  ...returnContentConst,
                  <Text>{item}</Text>,
                ];
              });
              break;
          }
        }

        if (
          !taskDescription?.match('<br>') &&
          taskDescription?.match('<strong>')
        ) {
          if (type === 'string') {
            totalString += 1;
          }
          switch (type) {
            case 'string':
              if (totalString > 1) {
                returnContentConst = [
                  ...returnContentConst,
                  <Text>{content + '\n'}</Text>,
                ];
              } else {
                returnContentConst = [
                  ...returnContentConst,
                  <Text>{content}</Text>,
                ];
              }
              break;
            case 'p':
              returnContentConst = [
                ...returnContentConst,
                <Text>{content}</Text>,
              ];
              break;
            case 'span':
              (content?.props?.children ?? []).forEach((item: any) => {
                returnContentConst = [
                  ...returnContentConst,
                  <Text style={styles.titleMedium}>{item}</Text>,
                ];
              });
              break;
            case 'strong':
              (content?.props?.children ?? []).forEach((item: any) => {
                returnContentConst = [
                  ...returnContentConst,
                  <Text style={styles.titleMedium}>
                    {item.replace('\n', '')}
                  </Text>,
                ];
              });
              break;
            case 'br':
              returnContentConst = [...returnContentConst, '\n'];
              break;
            case 'a':
              returnContentConst = [
                ...returnContentConst,
                <Text style={styles.link}>{content.props.children[0]}</Text>,
              ];
              break;
            default:
              (content.props.children ?? []).forEach((item: any) => {
                returnContentConst = [
                  ...returnContentConst,
                  <Text>{item}</Text>,
                ];
              });
              break;
          }
        }
      });
    });
    return returnContentConst.map((note: any, index: number) => (
      <Text key={index}>{note}</Text>
    ));
  } else {
    return returnContentConst;
  }
};
